import cn from "classnames"

import { HiddenValueCounter } from "@/atoms"

import styles from "./InsightsIcons.module.scss"

import type { InsightsIconsProps } from "."

export const InsightsIcons = ({
  icons,
  values,
  isKeyTranslation,
  hide,
  limit,
  triggerable, // TODO: temporary class for demo
  textPopoverByIndex = []
}: InsightsIconsProps) => {
  const useLimit = !!limit
  let counter = 0

  return (
    <div className={cn(styles.container, triggerable && styles.triggerable)}>
      {icons.map((Icon, index: number) => {
        if (hide && !values[index]) return null
        if (useLimit) {
          if (values[index]) counter += 1
          if (counter > limit) return null
        }

        return (
          <Icon
            key={index}
            data-tooltip-content={textPopoverByIndex[index]}
            data-tooltip-translation={isKeyTranslation}
            className={cn(styles.icon, !!values[index] && styles.active)}
          />
        )
      })}
      {useLimit && counter > limit && (
        <HiddenValueCounter value={counter - limit} />
      )}
    </div>
  )
}
