import { getCodeRepoPackagesData } from "@/api/useInventory.selectors"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useHttp } from "@/hooks/useHttp"

import type { Config, Params } from "@/hooks/useHttp"
import type {
  ApplicationParams,
  ApplicationResponse,
  ArchivedCountsResponse,
  BaseImageDetailsParams,
  BranchResponse,
  CodeRepoPackagesParams,
  CodeRepoPackagesResponse,
  ContainerBaseImageDetailsResponse,
  ContainerBaseImageResponse,
  CountsResponse,
  EntityByTypeAndId,
  EnvironmentResponse,
  ImageResponse,
  InventoryCodeRepoPackagesParams,
  SnapshotResponse,
  TransitiveFixesResponse
} from "./useInventory.types"

export type { Snapshot } from "./useInventory.types"

export const useGetInventorySnapshot = <T = SnapshotResponse, S = T>(
  params: Params = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.SNAPSHOT,
    params,
    config
  )

  return context
}

export const useGetInventoryEnvironment = <T = EnvironmentResponse, S = T>(
  params: Params = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.ENVIRONMENT,
    params,
    config
  )

  return context
}

export const useGetInventoryApplication = <T = ApplicationResponse, S = T>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.APPLICATION}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetInventoryImage = <T = ImageResponse, S = T>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params

  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.IMAGES}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetInventoryBranch = <T = BranchResponse, S = T>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params

  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.BRANCH}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetInventoryContainerBaseImage = <
  T = ContainerBaseImageResponse,
  S = T
>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.CONTAINER_BASE_IMAGE,
    params,
    config
  )

  return context
}

export const useGetInventoryContainerBaseImageDetails = <
  T = ContainerBaseImageDetailsResponse,
  S = T
>(
  baseImageDigest: string,
  params?: Params<BaseImageDetailsParams>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.CONTAINER_BASE_IMAGE}/${baseImageDigest}`,
    params,
    config
  )

  return context
}

export const useGetInventoryCounts = <T = CountsResponse, S = T>(
  params: Params = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.COUNTS,
    params,
    config
  )

  return context
}

export const useGetInventoryCodeRepoPackages = <
  T extends CodeRepoPackagesResponse = CodeRepoPackagesResponse,
  S = ReturnType<typeof getCodeRepoPackagesData>
>(
  repoName: string,
  params: InventoryCodeRepoPackagesParams = {},
  config: Config<T, S> = {}
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.BRANCH}/${repoName}`,
    params,
    config
  )

  return context
}

export const useGetTransitiveFixesCodeRepository = <
  T extends TransitiveFixesResponse = TransitiveFixesResponse,
  S = T
>(
  repoName: string,
  params: Params<CodeRepoPackagesParams> = {},
  config: Config<T, S> = {}
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.BRANCH}/${repoName}`,
    params,
    config
  )

  return context
}

export const useGetItemByIdInventory = <T, S = T>(
  { id, type }: EntityByTypeAndId,
  params?: Params,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORY}/${type}/${id}`,
    params,
    config
  )

  return context
}

export const useGetArchivedRepositoriesCount = <
  T = ArchivedCountsResponse,
  S = T
>() => {
  const context = useHttp<T, S>(RESOURCES_NAMES.INVENTORIES.ARCHIVED)

  return context
}
