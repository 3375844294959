import { Tooltip } from "@/atoms"
import { TEASERS } from "@/const/teaser.constants"
import { useLogger } from "@/hooks/useLogger"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { IntegrationInstallationStatus } from "@/icons"
import { ExternalLinkIcon } from "@/molecules/ExternalLinkIcon"
import { StatisticCard } from "../../molecules/StatisticCard"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"
import { EMPTY_VALUE_STATISTIC_CARD, getStatisticCardProps } from "./helpers"

import styles from "./InventorySnapshotWidget.module.scss"

import type { InventorySnapshotWidgetProps } from "."

export const InventorySnapshotWidget = (
  props: InventorySnapshotWidgetProps
) => {
  const prefixKeyTranslationWidget = "dashboard.inventorySnapshotWidget."
  const { t } = useTranslation()
  const { abbreviateNumber } = useNumberConvertor()
  const { logger, EVENTS } = useLogger()
  const { response, isLoading } = props
  const {
    cluster,
    application,
    containerImage,
    packages,
    branch,
    containerBaseImage
  } = response || {}
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const showPlaceholder = !!dataReadinessFF
  const loading = showPlaceholder ? false : isLoading
  const noData = showPlaceholder
    ? false
    : !cluster && !application && !containerImage && !packages
  const classesStatisticCard = { container: styles.statisticCard }
  const branchIntegrationEnabled =
    branch?.total !== -1 && dataReadinessFF !== "setup_required"
  const statisticCardAttr = { t, showPlaceholder, abbreviateNumber }

  return (
    <CardDashboardTemplate
      wrap
      // customPlaceholder
      loading={loading}
      noData={noData}
      title={t(`${prefixKeyTranslationWidget}title`)}
      contentPopover={t(`${prefixKeyTranslationWidget}description`)}
      gutter={[8, 8]}
      teasers={{
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t),
        scmInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t)
      }}
    >
      <StatisticCard
        span={8}
        classes={classesStatisticCard}
        title={t("general.cluster_other")}
        {...getStatisticCardProps(cluster, statisticCardAttr)}
      />
      <StatisticCard
        span={8}
        classes={classesStatisticCard}
        to="/inventory/applications"
        title={t("general.application_other")}
        onClick={() => {
          logger.info(
            EVENTS.ANALYTIC_EVENTS.DASHBOARD.CLICK_INVENTORY_SNAPSHOT
              .APPLICATIONS
          )
        }}
        {...getStatisticCardProps(application, statisticCardAttr)}
      />
      <Tooltip
        title={
          !branchIntegrationEnabled && (
            <div className={styles.externalLink}>
              <span>
                {t("dashboard.inventorySnapshotWidget.branchExternalLink")}
              </span>
              <ExternalLinkIcon href="/integrations" />
            </div>
          )
        }
      >
        <StatisticCard
          span={8}
          classes={classesStatisticCard}
          to={branchIntegrationEnabled ? "/inventory/branch" : undefined}
          title={t("general.code_repositories")}
          onClick={() => {
            logger.info(
              EVENTS.ANALYTIC_EVENTS.DASHBOARD.CLICK_INVENTORY_SNAPSHOT
                .CODE_REPOSITORIES
            )
          }}
          {...getStatisticCardProps(branch, statisticCardAttr)}
          formatter={(value) =>
            branchIntegrationEnabled ? value : <IntegrationInstallationStatus />
          }
        />
      </Tooltip>
      <StatisticCard
        span={8}
        classes={classesStatisticCard}
        to="/inventory/images"
        title={t("general.image_other")}
        onClick={() => {
          logger.info(
            EVENTS.ANALYTIC_EVENTS.DASHBOARD.CLICK_INVENTORY_SNAPSHOT.IMAGES
          )
        }}
        {...getStatisticCardProps(containerImage, statisticCardAttr)}
      />
      <StatisticCard
        span={8}
        classes={classesStatisticCard}
        to="/inventory/baseImages"
        title={t("general.base_image_other")}
        onClick={() => {
          logger.info(
            EVENTS.ANALYTIC_EVENTS.DASHBOARD.CLICK_INVENTORY_SNAPSHOT.IMAGES
          )
        }}
        {...getStatisticCardProps(containerBaseImage, statisticCardAttr)}
      />
      <StatisticCard
        span={8}
        classes={classesStatisticCard}
        to="/sbom"
        title={t("general.package_other")}
        value={
          !showPlaceholder && packages?.total
            ? abbreviateNumber(packages.total)
            : EMPTY_VALUE_STATISTIC_CARD
        }
        onClick={() => {
          logger.info(
            EVENTS.ANALYTIC_EVENTS.DASHBOARD.CLICK_INVENTORY_SNAPSHOT.PACKAGES
          )
        }}
      />
    </CardDashboardTemplate>
  )
}
