import { TFunction } from "@/hooks/useTranslation"
import { getCVEFilter } from "@/pages/InventoryPage/utils/filters.utils"

export { EXPLOITABILITY_FILTER_OPTIONS } from "@/const/options.constants"

export const getItemsKeys = () => {
  const checkboxType = "checkbox" as const
  const radioType = "radio" as const
  const inputType = "input" as const

  return [
    { label: "Environment", key: "environment", type: checkboxType },
    { label: "Namespace", key: "namespace", type: checkboxType },
    { label: "Service", key: "service", type: checkboxType },
    { label: "Vulnerable", key: "isVulnerable", type: radioType },
    { label: "Language", key: "language", type: checkboxType },
    { label: "License", key: "license", type: checkboxType },
    { label: "CVE Severity", key: "cveSeverity", type: checkboxType },
    { label: "Has Fix", key: "hasFix", type: radioType },
    { label: "Exploitability", key: "exploitMaturity", type: checkboxType },
    { label: "Base Image", key: "fromBaseImage", type: radioType },
    { label: "Packages", key: "name", type: checkboxType },
    { label: "CVEs", key: "riskId", type: checkboxType },
    {
      label: "Image ID",
      key: "imageId",
      type: checkboxType,
      displayValue: (value: string) => `Image ID (${value.split("@")[0]})`
    },
    {
      label: "Base Image Digest",
      key: "baseImageDigest",
      type: inputType,
      displayValue: (value: string) =>
        `Base Image Digest (${value.substring(7, 19)})`
    }
  ]
}

export const getItemsAutocomplete = () => [
  { label: "Packages", name: "name" },
  { label: "CVEs", name: "cve" }
]

export const getFilters = (t: TFunction) => [
  {
    key: "name",
    label: t("filters.package"),
    type: "input",
    helper: [
      {
        label: "package:value1,value2",
        description: "Matches multiple package names as a substring"
      }
    ]
  },
  {
    key: "imageId",
    label: t("filters.imageId"),
    type: "input",
    readOnly: true,
    validation: () => true
  },
  {
    key: "baseImageDigest",
    label: t("filters.baseImageDigest"),
    type: "input",
    readOnly: true
  },
  getCVEFilter(),
  {
    key: "cveSeverity",
    label: t("filters.severity"),
    type: "multiple",
    options: []
  },
  {
    key: "environment",
    label: t("filters.environment"),
    type: "multiple",
    options: []
  },
  {
    key: "fromBaseImage",
    label: t("filters.fromBaseImage"),
    type: "single",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      { label: "N/A", value: "Undetermined", disabled: true }
    ]
  },
  {
    key: "hasFix",
    label: t("filters.hasFix"),
    type: "single",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      { label: "N/A", value: "Undetermined", disabled: true }
    ]
  },
  { key: "isVulnerable", label: t("filters.vulnerable"), type: "single" },
  {
    key: "language",
    label: t("filters.language"),
    type: "multiple",
    options: []
  },
  {
    key: "license",
    label: t("filters.license"),
    type: "multiple",
    options: []
  },
  {
    key: "exploitMaturity",
    label: t("filters.exploitability"),
    type: "multiple",
    options: []
  },
  { key: "namespace", label: "namespace", type: "multiple", options: [] },
  {
    key: "service",
    label: t("filters.application"),
    type: "multiple",
    options: []
  }
]
