import { CellTable, SortOrder } from "@/atoms"
import { ISSUES_LIMIT } from "@/const/table-constants"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { NumberConvertor } from "@/hooks/useNumberConvertor"
import { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import { TFunction } from "@/hooks/useTranslation"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { MoreTableCell } from "@/molecules/MoreTableCell"
import { InsightsApplicationCell } from "../../molecules/InsightsApplicationCell"
import { ViewSbomCell } from "../../molecules/ViewSbomCell"

import type { ColumnType } from "@/atoms"
import type { Application } from "@/pages/InventoryPage/application/application-inventory/useApplicationInventory.types"

export const getColumnsTablePreviewMode = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
) => [
  {
    title: "Application Name",
    render: CellTable,
    ...getDefaultColumnParams("displayName", sorter),
    sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
  },
  {
    title: "Environment",
    width: 120,
    render: CellTable,
    ...getDefaultColumnParams("environment")
  },
  {
    title: "Namespace",
    width: 200,
    render: CellTable,
    ...getDefaultColumnParams("namespace")
  }
]

export const getColumnsTable = ({
  internetFacingFF,
  previewMode,
  convertors,
  sorter,
  t
}: {
  internetFacingFF?: boolean
  previewMode?: boolean
  convertors: NumberConvertor
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
  t: TFunction
}): ColumnType<Application>[] => {
  const columnsPreviewMode = getColumnsTablePreviewMode(sorter)

  if (previewMode) return columnsPreviewMode

  return [
    ...columnsPreviewMode,
    {
      title: "OS",
      width: 150,
      render: MoreTableCell,
      ...getDefaultColumnParams("operatingSystems")
    },
    {
      title: "Insights",
      width: internetFacingFF ? 110 : 75,
      render: InsightsApplicationCell({ internetFacingFF }),
      ...getDefaultColumnParams("insights")
    },
    {
      title: "Issues Summary",
      width: 220,
      render: IssuesSummaryCell({ t, convertors }, ISSUES_LIMIT),
      ...getDefaultColumnParams("severity", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "SBOM",
      width: 70,
      className: "center-align-tab-template-component",
      render: ViewSbomCell,
      ...getDefaultColumnParams("sbomQuery")
    },
    {
      title: "Last Seen",
      width: 130,
      ...getDefaultColumnParams("lastSeen")
    }
  ]
}
