import { message } from "@/atoms"
import { ExportContext } from "@/hooks/useExport"
import { useServices } from "@/hooks/useServices"
import { MessageExport } from "@/molecules/MessageExport"
import { useEffect, useMemo, useRef, useState } from "react"
import { createTask } from "./tasks"

import styles from "./ExportProvider.module.scss"

export const ExportProvider = ({ children }: any) => {
  const timeoutIdByURL = useRef<Record<string, NodeJS.Timeout>>({})
  const [messageApi, contextHolder] = message.useMessage()
  const [tasksByUrl, setTasksByUrl] = useState<
    Record<
      string,
      { id?: string; url: string; params?: Record<string, string> }
    >
  >({})
  const { http, download, logger } = useServices()

  const value = useMemo(() => {
    const removeTask = (url: string, destroyMessage?: boolean) => {
      if (timeoutIdByURL.current[url]) {
        clearTimeout(timeoutIdByURL.current[url])
        delete timeoutIdByURL.current[url]
      }

      if (destroyMessage) {
        messageApi.destroy(url)
      }

      setTasksByUrl((prev) => {
        const { [url]: _, ...rest } = prev

        return rest
      })
    }

    return {
      tasksByUrl,
      removeTask,
      addTask: (url: string, params?: Record<string, string>) => {
        const formatText = params?.format === "json" ? "JSON" : "CSV"

        messageApi.open({
          key: url,
          className: styles.messageContainer,
          duration: 0,
          type: "loading",
          content: (
            <MessageExport
              text={`Exporting ${formatText}...`}
              onCancel={() => removeTask(url, true)}
            />
          )
        })
        createTask(
          { url, params },
          { timeoutIdByURL, http, onSuccess, onError }
        )
        setTasksByUrl((prev) => {
          return {
            ...prev,
            [url]: {
              url,
              params
            }
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksByUrl, messageApi])

  const onError = (url: string) => {
    messageApi.open({
      key: url,
      duration: 3,
      type: "error",
      content: "Export failed"
    })
    value.removeTask(url)
  }

  const onSuccess = (url: string, key: string) => {
    download.downloadFileFromUrl({ url, filename: "name" }, { logger })

    messageApi.open({
      key,
      duration: 3,
      type: "success",
      content: "Export completed successfully"
    })

    value.removeTask(key)
  }

  useEffect(() => {
    const timeoutIds = timeoutIdByURL.current

    window.onbeforeunload = function () {
      if (Object.keys(timeoutIds || {}).length) {
        return true
      }
    }

    return () => {
      Object.keys(timeoutIds).forEach((url) => {
        clearTimeout(timeoutIds[url])

        delete timeoutIds[url]
      })

      window.onbeforeunload = null
    }
  }, [])

  return (
    <ExportContext.Provider value={value}>
      {contextHolder}
      {children}
    </ExportContext.Provider>
  )
}
