export const PARAMS = {
  ID: "id",
  DUPLICATE_ID: "duplicateId",
  SUB_TYPE: "subType"
}
export const VALUES_PARAMS = {
  NEW_ENTITY: "new"
}

export const NAMES_ROUTES = {
  SETTINGS: {
    ROOT: "settings",
    SCM: "scm",
    SCOPES: "scopes",
    CI: "ci"
  },
  INTEGRATIONS: {
    ROOT: "integrations",
    CALLBACK: "callback"
  }
}
