import { useState } from "react"

import {
  BetaLabel,
  Button,
  Dropdown,
  Link,
  message,
  Modal,
  Tabs,
  Text
} from "@/atoms"
import { NAMES_ROUTES, VALUES_PARAMS } from "@/const/routes.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { FileProtectOutlined } from "@/icons"
import { Heading } from "@/molecules/Heading"
import { LabelTabItem } from "@/molecules/LabelTabItem"
import { usePolicies } from "@/pages/SettingsPage/application/policies/usePolicies"
import { VerticalTabTemplate } from "@/pages/SettingsPage/components/templates/VerticalTabTemplate"
import { useNavigate, useParams } from "react-router-dom"
import {
  isCIPolicy,
  PROTECT_SUB_TYPE_POLICY,
  SUPPRESS_SUB_TYPE_POLICY
} from "../../../helpers"
import { SubTab } from "./SubTab"

import styles from "./ScmTab.module.scss"

import { TabsTemplate } from "@/templates/TabsTemplate"
import type { PoliciesPageProps } from "./ScmTab.types"

export const ScmTab = (props: PoliciesPageProps) => {
  const { t: tPolicy } = useTranslation("translation", {
    keyPrefix: `settings.${props.policyType.toLowerCase()}`
  })
  const { t: tPolicies } = useTranslation("translation", {
    keyPrefix: "settings.policies"
  })
  const [modalApi, modalContext] = Modal.useModal()
  const [messageApi, messageContext] = message.useMessage()
  const protectSubType = PROTECT_SUB_TYPE_POLICY
  const suppressSubType = SUPPRESS_SUB_TYPE_POLICY
  const [isInitialDataInState, setIsInitialDataInState] = useState(false)
  const navigation = useNavigate()
  const tabsKeys = [protectSubType, suppressSubType]
  const { needle } = useParams()
  const activeKey = needle || tabsKeys[0]
  const { ROOT } = NAMES_ROUTES.SETTINGS
  const subTabProps = {
    modalApi,
    messageApi,
    setIsInitialDataInState,
    ...props
  }
  const isCI = isCIPolicy(props.policyType)
  const { abbreviateNumber } = useNumberConvertor()
  const { policyType } = props
  const protectRes = usePolicies({ type: policyType, subType: protectSubType })
  const suppressRes = usePolicies(
    { type: policyType, subType: suppressSubType },
    undefined,
    { enabled: !isCI }
  )

  return (
    <VerticalTabTemplate
      title={
        <Heading
          level={4}
          title={tPolicy("title")}
          suffix={isCI && <BetaLabel />}
        />
      }
      button={
        <Dropdown
          className={styles.dropdownButton}
          disabled={!isInitialDataInState}
          menu={{
            items: [
              {
                key: protectSubType,
                label: (
                  <Link
                    to={`/${ROOT}/${props.settingsRoute}/${protectSubType}/${VALUES_PARAMS.NEW_ENTITY}`}
                  >
                    Protection Policy
                  </Link>
                )
              },
              {
                key: suppressSubType,
                label: (
                  <Link
                    to={`/${ROOT}/${props.settingsRoute}/${suppressSubType}/${VALUES_PARAMS.NEW_ENTITY}`}
                  >
                    Suppression Policy
                  </Link>
                ),
                disabled: isCI
              }
            ]
          }}
          trigger={["hover"]}
        >
          <Button icon={<FileProtectOutlined />} type="primary">
            {tPolicies("createPolicy")}
          </Button>
        </Dropdown>
      }
    >
      <Text type="secondary">{tPolicy("first_line_note")}</Text>
      <br />
      <Text type="secondary">{tPolicy("second_line_note")}</Text>
      <br />
      <Text type="secondary">{tPolicy("third_line_note")}</Text>
      <TabsTemplate className={styles.tabs}>
        <Tabs
          activeKey={activeKey}
          items={[
            {
              key: tabsKeys[0],
              label: (
                <LabelTabItem
                  className="label-tab-item"
                  count={abbreviateNumber(
                    protectRes.response?.metadata?.total || 0
                  )}
                  overflowCount={999}
                  title="Protection"
                />
              ),
              children: (
                <SubTab draggable subType={protectSubType} {...subTabProps} />
              )
            },
            {
              key: tabsKeys[1],
              label: (
                <LabelTabItem
                  count={abbreviateNumber(
                    suppressRes.response?.metadata?.total || 0
                  )}
                  overflowCount={999}
                  title="Suppression"
                />
              ),
              children: <SubTab subType={suppressSubType} {...subTabProps} />,
              disabled: isCI
            }
          ]}
          onChange={(key) => {
            navigation(`/${ROOT}/${props.settingsRoute}/${key}`)
          }}
        />
      </TabsTemplate>

      {modalContext}
      {messageContext}
    </VerticalTabTemplate>
  )
}
