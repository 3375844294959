import { ExploitIconsGroup, Ingress, InternetFacing, Runtime } from "@/icons"
import { IconTag } from "@/molecules/IconTag"
import { InsightsIcons } from "@/molecules/InsightsIcons"
import { ShowMore } from "@/molecules/ShowMore"

import styles from "./InsightsCell.module.scss"

import type { TFunction } from "@/hooks/useTranslation"
import type { InsightsValues } from "."

export const InsightsCell =
  (t: TFunction, internetFacingFF: boolean) =>
  ({
    runtime,
    internetFacing,
    ingress,
    pocExploitMaturity,
    inTheWildExploitMaturity,
    tag,
    id
  }: InsightsValues) => {
    const icons = [
      Runtime,
      ExploitIconsGroup.IN_THE_WILD,
      Ingress,
      ExploitIconsGroup.POC
    ]
    const values = [
      runtime,
      inTheWildExploitMaturity,
      ingress,
      pocExploitMaturity
    ]
    const texts = [
      "general.runtime",
      "general.exploitability.IN_THE_WILD",
      "general.ingress",
      "general.exploitability.POC"
    ]

    if (internetFacingFF) {
      icons.splice(1, 0, InternetFacing)
      values.splice(1, 0, internetFacing === "YES")
      texts.splice(1, 0, "general.internetFacing")
    }

    if (tag) {
      return icons.map((Icon, index) => {
        if (!values[index]) return null

        return <IconTag key={index} icon={<Icon />} text={t(texts[index])} />
      })
    }

    return (
      <ShowMore
        id={id}
        label={
          <ShowMore.Label.Component>
            <InsightsIcons hide limit={4} icons={icons} values={values} />
          </ShowMore.Label.Component>
        }
      >
        <ShowMore.Component
          title="Insights"
          classes={{ content: styles.contentPopover }}
        >
          {icons.map((Icon, index) => {
            if (!values[index]) return null

            return (
              <IconTag key={index} icon={<Icon />} text={t(texts[index])} />
            )
          })}
        </ShowMore.Component>
      </ShowMore>
    )
  }
