import { BaseImage, DirectDependency, IndirectDependency } from "@/icons"
import { IconTag } from "@/molecules/IconTag"
import { InsightsIcons } from "@/molecules/InsightsIcons"

import type { TFunction } from "@/hooks/useTranslation"
import type { InsightsValues } from "."

export const IntroduceThroughInsightsICell =
  (t: TFunction) =>
  ({ isDirect, isIndirect, fromBaseImage, tag }: InsightsValues) => {
    const icons = [DirectDependency, IndirectDependency, BaseImage]
    const texts = [
      "general.directDependency",
      "general.indirectDependency",
      "general.baseImage"
    ]
    const values = [isDirect, isIndirect, fromBaseImage]

    if (tag) {
      return icons.map((Icon, index) => {
        if (!values[index]) return null

        return <IconTag key={index} icon={<Icon />} text={t(texts[index])} />
      })
    }

    return (
      <InsightsIcons
        isKeyTranslation
        textPopoverByIndex={texts}
        icons={icons}
        values={values}
      />
    )
  }
