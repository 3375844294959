import { RESOURCES_NAMES } from "@/const/resource.constants"

export const getQueriesSBOM = (params: object = {}) => {
  const paramsRuntimeSBOM = { scope: "runtime", ...params }
  const paramsAllSBOM = { scope: "all", ...params }

  return [
    { url: RESOURCES_NAMES.SBOM.LIST, params: paramsAllSBOM },
    {
      url: RESOURCES_NAMES.SBOM.LIST,
      params: paramsRuntimeSBOM
    }
  ]
}
