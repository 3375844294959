import { useRef } from "react"

import { Link } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { AuditOutlined, UnorderedListOutlined } from "@/icons"
import { MenuNavigationTemplate } from "@/templates/MenuNavigationTemplate"
import { TitleSideNavigation as Title } from "./TitleSideNavigation"

import type { NavigationComponent } from ".."

export const ExploreSideNavigation: NavigationComponent = (props) => {
  const { collapsed, t } = props
  const menuItems = useRef([
    {
      label: (
        <Link to={`/inventory/${TAB_KEYS_BY_PAGE.INVENTORY[0]}`}>
          {t("navigation.inventory")}
        </Link>
      ),
      key: "inventory",
      icon: <AuditOutlined />,
      "data-testid": "inventory"
    },
    {
      label: (
        <Link to={`/sbom/${TAB_KEYS_BY_PAGE.SBOM[0]}?isVulnerable=Yes`}>
          {t("navigation.sbom")}
        </Link>
      ),
      key: "sbom",
      icon: <UnorderedListOutlined />,
      "data-testid": "sbom"
    }
  ]).current

  return (
    <>
      <Title collapsed={collapsed}>{t("navigation.explore")}</Title>
      <MenuNavigationTemplate items={menuItems} />
    </>
  )
}
