import { ExploitMaturity } from "@/domain/package"
import { ExploitabilityTag } from "../ExploitabilityTag"

import type { TFunction } from "@/hooks/useTranslation"

export const ExploitabilityCell =
  (t: TFunction, { disableTooltip }: { disableTooltip?: boolean } = {}) =>
  (maturity: ExploitMaturity | undefined, _: unknown, rowIndex: number) => (
    <div data-tooltip-exploit-maturity={disableTooltip ? undefined : rowIndex}>
      <ExploitabilityTag maturity={maturity} t={t} />
    </div>
  )
