import { Table } from "@/atoms"
import { useTooltip } from "@/hooks/useTooltip"

import styles from "./ExpandedRowTable.module.scss"

import type { TableProps } from "./ExpandedRowTable.types"

export const ExpandedRowTable = <T extends Object>(props: TableProps<T>) => {
  useTooltip(props.dataSource)

  return (
    <Table
      className={styles.table}
      pagination={false}
      showHeader={false}
      {...props}
    />
  )
}
