import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { BaseImage, InternalApp, InternetFacing, Runtime } from "@/icons"
import { ExploitabilityTag } from "@/molecules/ExploitabilityTag"
import { IconTag } from "@/molecules/IconTag"

import styles from "./TitlePackageDrawer.module.scss"

import type { TitlePackageDrawerProps } from "."

export const TitlePackageDrawer = ({
  insights,
  exploitMaturity,
  internetFacing
}: TitlePackageDrawerProps) => {
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  //@ts-ignore
  const triggerable = insights?.triggerable // TODO: temporary code for demo

  if (!insights) return null

  return (
    <div className={styles.section}>
      {insights.runtime && (
        <IconTag
          classes={{ tag: triggerable && styles.triggerable }}
          icon={<Runtime />}
          text={`${t(
            triggerable ? "general.triggerableInRuntime" : "general.runtime"
          )}`}
        />
      )}
      <ExploitabilityTag maturity={exploitMaturity} t={t} />
      {insights.fromBaseImage && (
        <IconTag icon={<BaseImage />} text={t("general.baseImage")} />
      )}
      {internetFacingFF && internetFacing === "Yes" && (
        <IconTag icon={<InternetFacing />} text={t("general.internetFacing")} />
      )}
      {internetFacingFF && internetFacing === "No" && (
        <IconTag icon={<InternalApp />} text={t("general.internalApp")} />
      )}
    </div>
  )
}
