import { Button } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./MessageExport.module.scss"

import type { MessageExportProps } from "."

export const MessageExport = ({ text, onCancel }: MessageExportProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <span>{text}</span>
      <Button className={styles.button} type="primary" onClick={onCancel}>
        {t("general.cancel")}
      </Button>
    </div>
  )
}
