import { Button, Text } from "@/atoms"
import { useToggle } from "@/hooks/useToggle"
import { useTranslation } from "@/hooks/useTranslation"
import { BulletedList } from "../BulletedList"
import { Description } from "../Description"
import type { PackagePathsProps } from "./PackagePaths.types"

import styles from "./PackagePaths.module.scss"

export const PackagePaths = ({ data = [] }: PackagePathsProps) => {
  const { value: showMoreToggled, toggle } = useToggle(false)
  const { t } = useTranslation("translation", { keyPrefix: "sbom.drawer" })
  if (!data || data.length === 0) {
    return null
  }

  const dataSlice = showMoreToggled ? data : data.slice(0, 3)
  const shouldShowMore = data.length > 3
  const moreThanOne = data.length > 1

  return (
    <div className={styles.container}>
      <Description
        width={500}
        ellipsis={{
          tooltip: <div className={styles.tooltip}>{dataSlice[0]}</div>
        }}
        copyable
        label={`${t("packagePath", { count: dataSlice.length })}:`}
      >
        {moreThanOne ? null : dataSlice[0]}
      </Description>
      {moreThanOne && (
        <BulletedList classes={{ wrap: styles.list }}>
          {dataSlice.map((path) => (
            <>
              <Text
                ellipsis={{
                  tooltip: <div className={styles.tooltip}>{path}</div>
                }}
                copyable
              >
                {path}
              </Text>
            </>
          ))}
        </BulletedList>
      )}
      {shouldShowMore && (
        <Button className={styles.button} type="link" onClick={toggle}>
          {showMoreToggled ? t("showLess") : t("showMore")}
        </Button>
      )}
    </div>
  )
}
