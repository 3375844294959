import { Badge, Tag } from "@/atoms"
import type { LabelPrimaryNavigationProps } from "."
import styles from "./LabelPrimaryNavigation.module.scss"

export const LabelPrimaryNavigation = ({
  label,
  asideText,
  count
}: LabelPrimaryNavigationProps) => (
  <div className={styles.container}>
    {label}
    {!!asideText && <Tag className={styles.tag}>{asideText}</Tag>}
    {!!count && <Badge className={styles.count} count={count} />}
  </div>
)
