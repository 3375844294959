import cn from "classnames"

import { CodeOutlined } from "@/icons"
import { ItemListTemplate } from "../../templates/ItemListTemplate"
import { DescriptionItem } from "../DescriptionItem"

export const HelperList = (props: any) => {
  const { services, onClick } = props
  const { FilterService, StringService } = services
  const currentToken = StringService.getTokenByCaretPos()
  const currentLabel = currentToken?.id
  const filter = FilterService.getFilterByLabel(currentLabel)

  if (!filter) return null

  const title = "Helper"
  const className = "item-smart-search-ks helper-item-smart-search-ks"
  const { helper, linkedKey } = filter
  const helpers = linkedKey
    ? FilterService.getHelpersByLinkedKey(linkedKey).reduce(
        (acc: [], filter: { helper: unknown[] }) => {
          return [
            ...acc,
            ...(filter.helper || []).map((helper: any) => ({
              filter,
              ...helper
            }))
          ]
        },
        []
      )
    : helper

  if (helpers)
    return (
      <ItemListTemplate title={title} Icon={CodeOutlined}>
        {helpers.map(
          (
            {
              filter,
              description,
              label = currentLabel
            }: { label?: string; description: string; filter: any },
            index: number
          ) => {
            const handler = (event: MouseEvent | KeyboardEvent) => {
              const { code } = event as KeyboardEvent

              if (code === "ArrowDown" || code === "ArrowUp") return

              if (filter.label === currentLabel)
                return onClick({
                  replaceByIndex: StringService.findTokenIndexByCaretPos()
                })

              StringService.setTokens(
                StringService.getTokens().map((token: any) => {
                  if (token.id === currentLabel) {
                    return {
                      ...token,
                      str: token.str
                        .toLowerCase()
                        .replace(token.id, filter.label),
                      id: filter.id,
                      label: filter.id
                    }
                  }
                  return token
                })
              )
              StringService.setText(
                StringService.buildTextFromTokens()
              ).tokenizer()
              StringService.setCaretPos(
                StringService.getTokens().filter(
                  (token: any) => token.id === filter.label
                )?.[0]?.endCaretPos
              )
              StringService.updateDataByCaretPos()

              onClick({
                replaceByIndex: StringService.findTokenIndexByCaretPos()
              })
            }

            return (
              <DescriptionItem
                tabIndex={linkedKey ? 1 : 0}
                key={index}
                className={cn(
                  className,
                  linkedKey && "inequality-item-smart-search"
                )}
                description={description}
                handler={linkedKey && handler}
              >
                {linkedKey && (
                  <>
                    {index === 0 && (
                      <span className="inequality-label-smart-search">!</span>
                    )}
                    <span>{label.slice(index === 0 ? 1 : 0)}</span>
                  </>
                )}
                {!linkedKey && label}
              </DescriptionItem>
            )
          }
        )}
      </ItemListTemplate>
    )

  const { type } = filter

  if (type === "multiple")
    return (
      <ItemListTemplate title={title} Icon={CodeOutlined}>
        <DescriptionItem
          tabIndex={0}
          className={className}
          description="Equals value1 OR value2"
        >
          {`${currentLabel}:value1,value2`}
        </DescriptionItem>
      </ItemListTemplate>
    )

  return null
}
