import { useEffect, useState } from "react"
import showdown from "showdown"

import { useCalculateFixes } from "@/api/useTriage"
import { Fix } from "@/api/useTriage.types"
import { Button, message, Tooltip } from "@/atoms"
import { useQueryClient } from "@/hooks/useHttp"
import { useServices } from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckSquareOutlined, IssueTypeIcon, Language } from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { IconDismissedTag } from "@/molecules/DismissedTag"
import { DescriptionVulnerableCodeIssue } from "@/molecules/IssueDetails/DescriptionVulnerableCodeIssue"
import { LinkRisk } from "@/molecules/LinkRisk"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { CreateJiraTicketButton } from "@/organisms/Drawers/components/TriageDrawer/components/CreateJiraTicketButton"
import { JiraIssueModal } from "@/organisms/JiraIssueModal"
import { LabelModal } from "@/organisms/LabelModal"
import { getIssueRequestFilter } from "@/organisms/LabelModal/applications/useLabelsModal"
import {
  useDismissIssues,
  useIssues
} from "@/pages/TriagePage/application/useTriage"
import { DismissModal } from "@/pages/TriagePage/components/molecules/DismissModal"
import { useDismissModal } from "@/pages/TriagePage/hooks/useDismissModal"
import { getIssueHTML } from "./getTicketContent"

import styles from "./TitleTriageDrawer.module.scss"

import type { Issue } from "@/domain/issue"

export const TitleTriageDrawer: React.FC<{
  record: Issue | undefined
  hasIntegration: boolean
  params: Record<string, any>
  loading: boolean
  linkToDrawer?: string
  refetch: () => void
}> = (props) => {
  const { record, params, refetch, linkToDrawer } = props
  const { t } = useTranslation()
  const [messageApi, messageContextHolder] = message.useMessage()
  const { auth } = useServices()

  const onOkCallback = (attr?: { reason?: string }) => {
    if (!record) return

    const payload = { [record.projectId]: [record] }

    if (isDismissed) return reopenIssues(payload)

    dismissIssues(payload, attr)
  }

  const queryClient = useQueryClient()
  const [{ dismissIssues, reopenIssues }, context] = useDismissIssues(params, {
    messageApi,
    showLoadingMessage: true,
    onSuccess: () => {
      return Promise.all([
        refetch(),
        queryClient.invalidateQueries(getIssueRequestFilter())
      ])
    },
    componentName: "TitleTriageDrawer"
  })
  const { open, openModal, onOk, onCancel } = useDismissModal({
    onOk: onOkCallback
  })

  const createTicket = useIssues()

  const contextCalculateFixes = useCalculateFixes()

  const [jira, setJira] = useState<null | any>(null)

  const selector = `[data-id-checkbox-issue-expanded-row-triage="${record?.id}"]`
  const [isSelected, setIsSelected] = useState<boolean | null>(null)

  useEffect(() => {
    const el = document.querySelector(selector)

    if (el === null) return setIsSelected(null)

    setIsSelected(!!el.closest(".ant-checkbox-wrapper-checked"))
  }, [record, selector])

  if (props.loading || !record) return <SkeletonTitle />

  const { language, cwe, riskId, packageName, issueType, severity, filePath } =
    record
  const { description } = cwe || {}
  const isDismissed = record.status === "dismissed"

  const LanguageComponent = (
    <Tooltip title={language || "unknown"}>
      <Language
        noTooltip
        secondary
        withBg={false}
        language={language}
        className={styles.languageIcon}
      />
    </Tooltip>
  )

  const onCloseModal = () => setJira(null)

  const setJiraTicket = (
    record: Issue,
    description: string,
    summary: string
  ) => {
    if (!record) return setJira(null)

    const converter = new showdown.Converter()

    setJira({
      description: converter.makeHtml(description),
      summary: summary,
      id: record.id
    })
  }

  const title = description || riskId
  const hasIntegration = props.hasIntegration

  return (
    <>
      <TitleDrawer
        classes={{ title: styles.title }}
        icon={<IssueTypeIcon type={issueType} severity={severity} />}
        title={title}
        subTitle={
          issueType === "Vulnerable_Code" ? (
            <DescriptionVulnerableCodeIssue
              language={language}
              LanguageComponent={LanguageComponent}
              filePath={filePath || ""}
            />
          ) : (
            <div className={styles.subTitle}>
              <OverviewDescriptions.Label>
                <div className={styles.wrapper}>
                  {riskId && (
                    <LinkRisk cve={riskId} classes={{ text: styles.cveLink }} />
                  )}
                  <span>{t("general.onPackage", { str: "" })}</span>
                  {language && LanguageComponent}
                  <span>{packageName}</span>
                </div>
              </OverviewDescriptions.Label>
            </div>
          )
        }
        aside={<CopyLinkButton link={linkToDrawer} />}
      />
      <div className={styles.actionsWrapper}>
        <CreateJiraTicketButton
          id={`${record.id}-create-jira-ticket`}
          sizeBtn="small"
          typeBtn={auth.isDemo() ? "primary" : "secondary"}
          disabled={record.status === "dismissed"}
          t={t}
          hasIntegration={hasIntegration}
          isLoading={contextCalculateFixes.isPending}
          outlined={true}
          onClick={async () => {
            const res = await contextCalculateFixes.mutateAsync({
              issuesIds: [record.id]
            })
            const fixes = res.data as Fix[]
            const attr = { t, linkToDrawer }
            const { description, summary } = getIssueHTML(fixes, attr)

            setJiraTicket(record, description, summary)
          }}
        />
        {hasIntegration && (
          <JiraIssueModal
            type={!!jira ? "jira" : undefined}
            jira={jira}
            showLoaderCreateJiraIssueButton={false}
            closeModal={onCloseModal}
            onCreateJiraIssue={createTicket}
          />
        )}
        <Button
          icon={<IconDismissedTag />}
          size="small"
          type="secondary"
          className={styles.button}
          disabled={context.isPending}
          onClick={() => (isDismissed ? onOkCallback() : openModal())}
        >
          {isDismissed ? t("general.reopen") : t("general.dismiss")}
        </Button>
        <LabelModal
          btnSize="small"
          btnType="secondary"
          record={record}
          placement="drawer"
          component="triage_drawer"
        />
        <Button
          disabled={isSelected === null}
          ghost={!!isSelected}
          type={isSelected ? "primary" : "secondary"}
          size="small"
          icon={<CheckSquareOutlined />}
          className={styles.button}
          onClick={() => {
            document.querySelector<HTMLInputElement>(selector)?.click()
            setIsSelected(!isSelected)
          }}
        >
          {t(`triage.drawer.${isSelected ? "unselect" : "select"}Issue`)}
        </Button>
      </div>
      <DismissModal count={1} open={open} onOk={onOk} onCancel={onCancel} />
      {messageContextHolder}
    </>
  )
}
