import { useEffect } from "react"
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom"

import { REDIRECT_URL_PARAM_AUTH } from "@/const/provider-auth.constants"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { isAuthorizedUser } from "@/domain/user"
import { setPluginsDateHelpers } from "@/helpers/date.helpers"
import { useCheckApplicationUpdates } from "@/hooks/useCheckUpdates"
import { useLoggerOnNavigation } from "@/hooks/useLogger"
import { useServicesWithUser } from "@/hooks/useServicesWithUser"
import { useUser } from "@/hooks/useUser"
import { Drawers } from "@/organisms/Drawers"
import { SplashScreen } from "@/organisms/SplashScreen"
import { DashboardPage } from "@/pages/DashboardPage"
import { DeploymentRouts } from "@/pages/DeploymentsPage"
import { IntegrationsPage } from "@/pages/IntegrationsPage"
import { InventoryPage } from "@/pages/InventoryPage"
import { SbomPage } from "@/pages/SbomPage"
import { SettingsRouter } from "@/pages/SettingsPage"
import { AcsAuth } from "@/pages/SignInPage/components/organisms/AcsAuth"
import { ForgotPasswordForm } from "@/pages/SignInPage/components/organisms/ForgotPasswordForm"
import { OrganizationSlugForm } from "@/pages/SignInPage/components/organisms/OrganizationSlugForm"
import { ResetPasswordForm } from "@/pages/SignInPage/components/organisms/ResetPasswordForm"
import { SignInForm } from "@/pages/SignInPage/components/organisms/SignInForm"
import { WelcomeCard } from "@/pages/SignInPage/components/organisms/WelcomeCard"
import { SignInPageTemplate } from "@/pages/SignInPage/components/templates/SignInPageTemplate"
import { TenantSignupPage } from "@/pages/TenantSignupPage/TenantSignupPage"
import { ThreatPage } from "@/pages/ThreatPage"
import { TriagePage } from "@/pages/TriagePage"
import { LayoutTemplate } from "@/templates/LayoutTemplate"
import { useServices } from "./hooks/useServices"

const App = () => {
  const { user } = useUser()
  const { auth } = useServices()
  const [searchParams] = useSearchParams()
  const redirectToUrl = searchParams.get(REDIRECT_URL_PARAM_AUTH) || "/"

  useEffect(() => {
    setPluginsDateHelpers()
  }, [])

  useServicesWithUser()
  useLoggerOnNavigation()
  useCheckApplicationUpdates()

  const authorizedUser = isAuthorizedUser(user)

  const {
    userNeedVerifyPhoneNumberMultiFactor,
    userNeedVerificationCodeMultiFactor
  } = user
  const userNeedUseMultiFactor =
    userNeedVerifyPhoneNumberMultiFactor || userNeedVerificationCodeMultiFactor
  const showApplication = authorizedUser && !userNeedUseMultiFactor

  const { INTEGRATIONS } = NAMES_ROUTES

  return (
    <>
      <Routes>
        {!showApplication && (
          <Route element={<SignInPageTemplate />}>
            <Route
              path=":slug/forgot-password"
              element={<ForgotPasswordForm />}
            />
            <Route path=":slug/signin" element={<SignInForm user={user} />} />
            <Route path=":slug/acs" element={<AcsAuth />} />
            <Route path=":slug/invite" element={<WelcomeCard />} />
            <Route
              path=":slug/reset-password"
              element={<ResetPasswordForm />}
            />
            <Route path="/signup" element={<TenantSignupPage />} />
            <Route
              path={`/${INTEGRATIONS.ROOT}/${INTEGRATIONS.CALLBACK}`}
              element={<IntegrationsPage authorizedUser={authorizedUser} />}
            />
            <Route path="/*" element={<OrganizationSlugForm />} />
          </Route>
        )}
        {showApplication && (
          <Route element={<LayoutTemplate />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="inventory/*" element={<InventoryPage />} />
            <Route path="sbom/*" element={<SbomPage />} />
            <Route
              path={`${INTEGRATIONS.ROOT}/*`}
              element={<IntegrationsPage authorizedUser={authorizedUser} />}
            />
            <Route path="settings/*" element={<SettingsRouter />} />
            <Route path="deployments/*" element={<DeploymentRouts />} />
            <Route path="triage/*" element={<TriagePage />} />
            {auth.isDemo() && (
              <Route path="incidents/:incident?" element={<ThreatPage />} />
            )}
            <Route
              path="/*"
              element={<Navigate to={redirectToUrl} replace />}
            />
          </Route>
        )}
      </Routes>
      {showApplication && <Drawers />}
      {showApplication && <SplashScreen />}
    </>
  )
}

export default App
