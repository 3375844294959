import { Button } from "@/atoms"
import { CheckOutlined, GithubFilled } from "@/icons"
import { useState } from "react"

import styles from "./RemediateButton.module.scss"

export const RemediateButton = () => {
  const [remediated, setRemediated] = useState<"yes" | "no" | "loading">("no")

  return (
    <Button
      loading={remediated === "loading"}
      className={styles.button}
      icon={remediated === "yes" ? <CheckOutlined /> : <GithubFilled />}
      onClick={() => {
        if (remediated === "yes") return

        setRemediated("loading")
        setTimeout(() => setRemediated("yes"), 1000)
      }}
      data-testid="remediateButton"
    >
      {remediated === "yes" ? "PR opened" : "Remediate"}
    </Button>
  )
}
