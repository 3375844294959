import { Button, Link } from "@/atoms"
import { UnorderedListOutlined } from "@/icons"

export const ViewSbomCell = (query: string) => (
  <Link
    target="_blank"
    href={`/sbom/runtime?pageNumber=0&orderBy=kodemScore&ascending=false&${query}`}
  >
    <Button
      data-tooltip-content="View SBOM"
      disabled={query == null}
      size="small"
      icon={<UnorderedListOutlined />}
      onClick={(e) => {
        e.stopPropagation()
      }}
    />
  </Link>
)

export const ViewSbomCellP = ({ sbomQuery }: { sbomQuery: string }) =>
  ViewSbomCell(sbomQuery)
