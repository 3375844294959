import { PublicImage } from "@/icons"
import { InsightsIcons } from "@/molecules/InsightsIcons"

import type { InsightsImageCellProps } from "./InsightsImageCell.types"

export const InsightsImageCell = ({
  imageAccessPermission
}: InsightsImageCellProps) => (
  <InsightsIcons
    isKeyTranslation
    icons={[PublicImage]}
    textPopoverByIndex={["general.publicImage"]}
    values={[imageAccessPermission === "public"]}
  />
)
