import { lazy, Suspense } from "react"

import { Divider } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { UserMenu } from "@/organisms/UserMenu/UserMenu"
import { ExploreSideNavigation } from "./components/ExploreSideNavigation"
import { FeedSideNavigation } from "./components/FeedSideNavigation"
import { ManageSideNavigation } from "./components/ManageSideNavigation"
import { ScopeSideNavigation } from "./components/ScopeSideNavigation"

import styles from "./SideNavigation.module.scss"

import type { SideNavigationComponent } from "."

const AdminCheckListNavigation = lazy(
  () => import("./components/AdminCheckListNavigation")
)

export const SideNavigation: SideNavigationComponent = ({ collapsed }) => {
  const { t } = useTranslation()
  const props = { collapsed, t }

  return (
    <div className={styles.container}>
      <div>
        <ScopeSideNavigation {...props} />
        <FeedSideNavigation {...props} />
        <ExploreSideNavigation {...props} />
      </div>
      <div>
        <Suspense>
          <AdminCheckListNavigation {...props} />
        </Suspense>
        <ManageSideNavigation {...props} />
        <Divider className={styles.divider} />
        <UserMenu />
      </div>
    </div>
  )
}
