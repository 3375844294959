import { useGetInventoryCounts } from "@/api/useInventory"
import { Tabs } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useQueryStringStorage } from "@/hooks/useQueryStringStorage"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { LabelTabItem } from "@/molecules/LabelTabItem"
import { HeaderPage } from "@/organisms/HeaderPage"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ApplicationsTab } from "../ApplicationsTab"
import { BranchTab } from "../BranchTab"
import { ContainerBaseImagesTab } from "../ContainerBaseImagesTab"
import { ImagesTab } from "../ImagesTab"

import type { HeaderInventoryPageProps } from "."

export const HeaderInventoryPage: React.FC<HeaderInventoryPageProps> = ({
  useActiveKeyTabsInventory
}) => {
  const pageBaseUrl = "/inventory"
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const { activeKey, setActiveKey, config = {} } = useActiveKeyTabsInventory()
  const { response } = useGetInventoryCounts({}, { ...config })
  const { data } = response || {}
  const {
    application = 0,
    containerImage = 0,
    branch = 0,
    containerBaseImage = 0
  } = data || {}
  const navigation = useNavigate()
  const { t } = useTranslation("translation", { keyPrefix: "general" })
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const { abbreviateNumber } = useNumberConvertor({
    showPlaceholder: !!dataReadinessFF
  })
  const previewMode = !!setActiveKey
  const onClickCb = previewMode
    ? (key: string) => () => setActiveKey(key)
    : undefined

  const items = [
    {
      key: TAB_KEYS[0],
      label: (
        <LabelTabItem
          title={t("code_repositories")}
          to={TAB_KEYS[0]}
          keyStorageRestoreQueryString={TAB_KEYS[0]}
          count={abbreviateNumber(branch)}
          overflowCount={999}
          onClick={onClickCb?.(TAB_KEYS[0])}
        />
      ),
      children: (
        <BranchTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
      )
    },
    {
      key: TAB_KEYS[1],
      label: (
        <LabelTabItem
          title={t("application_other")}
          to={TAB_KEYS[1]}
          keyStorageRestoreQueryString={TAB_KEYS[1]}
          count={abbreviateNumber(application)}
          overflowCount={999}
          onClick={onClickCb?.(TAB_KEYS[1])}
        />
      ),
      children: (
        <ApplicationsTab
          useActiveKeyTabsInventory={useActiveKeyTabsInventory}
        />
      )
    },
    {
      key: TAB_KEYS[2],
      label: (
        <LabelTabItem
          title={t("image_other")}
          to={TAB_KEYS[2]}
          keyStorageRestoreQueryString={TAB_KEYS[2]}
          count={abbreviateNumber(containerImage)}
          overflowCount={999}
          onClick={onClickCb?.(TAB_KEYS[2])}
        />
      ),
      children: (
        <ImagesTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
      )
    },
    {
      key: TAB_KEYS[3],
      label: (
        <LabelTabItem
          title={t("base_image_other")}
          to={TAB_KEYS[3]}
          keyStorageRestoreQueryString={TAB_KEYS[3]}
          count={abbreviateNumber(containerBaseImage)}
          overflowCount={999}
          onClick={onClickCb?.(TAB_KEYS[3])}
        />
      ),
      children: (
        <ContainerBaseImagesTab
          useActiveKeyTabsInventory={useActiveKeyTabsInventory}
        />
      )
    }
  ]

  useEffect(() => {
    if (activeKey === undefined) {
      navigation(`${pageBaseUrl}/${items[0].key}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  useQueryStringStorage(activeKey, { keys: TAB_KEYS })

  return (
    <HeaderPage
      titleTranslationKey={previewMode ? "" : "navigation.inventory"}
      tabs={<Tabs activeKey={activeKey} items={items} />}
    />
  )
}
