import {
  useBranchEntity,
  usePackagesCodeRepository
} from "@/organisms/Drawers/applications/inventory/useInventory"
import { TabDrawerTemplate } from "@/organisms/Drawers/components/TabDrawerTemplate"
import {
  getDefaultFilterSeverities,
  useParamsFilter
} from "@/organisms/Drawers/hooks/useParamsFilter"
import { SbomFilterCodeRepositoryDrawer } from "../SbomFilterCodeRepositoryDrawer"
import { getColumnsExpandedRow } from "./getColumnsExpandedRow"
import { getColumnsTable } from "./getColumnsTable"

import { useTranslation } from "@/hooks/useTranslation"
import { InformationAlert } from "@/molecules/InformationAlert/InformationAlert"
import { useCounts } from "@/organisms/Drawers/hooks/useCounts"
import type { SbomTabCodeRepositoryDrawerComponent } from "."

export const SbomTabCodeRepositoryDrawer: SbomTabCodeRepositoryDrawerComponent =
  (props) => {
    const { t } = useTranslation()
    const tabKey = "sbom"
    const { record } = props

    const [, { isPackageManagerEstimated }] = useBranchEntity(props.record?.id)

    const headerComponent = isPackageManagerEstimated && (
      <InformationAlert
        message={t(
          "inventory.codeRepositoriesTab.drawer.estimatedPackageManagerMessage"
        )}
      />
    )

    const filtersContext = useParamsFilter({
      filterKey: tabKey,
      initialParams: getDefaultFilterSeverities("severity"),
      ...props
    })

    const [dataContext, countsContext] = usePackagesCodeRepository(
      record?.id,
      filtersContext.utils.getParamsWithFilters(filtersContext.params)
    )

    useCounts(tabKey, countsContext, props)

    return (
      <TabDrawerTemplate<typeof dataContext>
        dataContext={dataContext}
        filtersContext={filtersContext}
        getColumnsTable={getColumnsTable}
        getColumnsExpandedRow={getColumnsExpandedRow}
        FilterComponent={<SbomFilterCodeRepositoryDrawer {...filtersContext} />}
        headerComponent={headerComponent}
      />
    )
  }
