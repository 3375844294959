import cn from "classnames"
import { useLocation } from "react-router-dom"

import { useGetDeploymentVersion } from "@/api/useDeployments"
import { Breadcrumb, Button, Link, Skeleton, Steps, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { DownloadOutlined } from "@/icons"
import { InformationAlert } from "@/molecules/InformationAlert"
import { HeaderPage } from "@/organisms/HeaderPage"
import { downloadFileFromUrl } from "@/services/download"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { DeployCommand } from "../../organisms/DeployCommand"
import { DeployTabs } from "../../organisms/DeployTabs"

import styles from "./NewDeploymentPage.module.scss"

export const NewDeploymentPage = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { deployEnvFieldValue } = state || {}
  const { response, isLoading } = useGetDeploymentVersion()
  const { data } = response || {}
  const { version } = data || {}
  const noError = !isLoading && !!version
  const fileName = `kortex-${version}.tgz`
  const regcredFileName = "kortex-regcred.yaml"

  return (
    <PageWithBreadcrumbTemplate
      full
      classes={{ main: styles.main }}
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/deployments">{t("navigation.deployments")}</Link>
                  )
                },
                {
                  title: t("deployments.Deploy") || (
                    <Skeleton.Input active size="small" />
                  )
                }
              ]}
            />
          }
        />
      }
    >
      <Title
        className={cn(styles.title, isLoading && styles.loading)}
        level={5}
      >
        {`${t("deployments.type")}:`}
      </Title>

      <DeployTabs key={version} loading={isLoading}>
        <div>
          <Title className={cn(styles.title, styles.downloadSection)} level={5}>
            {`${t("deployments.instructions")}:`}
          </Title>
          <Steps
            size="small"
            className={styles.steps}
            direction="vertical"
            items={[
              {
                title: (
                  <div className={styles.download}>
                    <span className={styles.titleStep}>
                      <span>{t("general.download")}</span>{" "}
                      {t("deployments.deploymentStep")}
                    </span>
                    {noError && (
                      <Button
                        className={styles.button}
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          downloadFileFromUrl({
                            url: "/api/v1/deployments/download",
                            filename: fileName
                          })
                        }}
                      >
                        {fileName}
                      </Button>
                    )}
                    {noError && (
                      <Button
                        className={styles.button}
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          downloadFileFromUrl({
                            url: "/api/v1/deployments/registry-credentials",
                            filename: regcredFileName
                          })
                        }}
                      >
                        {regcredFileName}
                      </Button>
                    )}
                  </div>
                ),
                status: "process"
              },
              {
                title: (
                  <span className={styles.titleStep}>
                    <span>{t("general.deploy")}</span>
                    {` - ${t("deployments.commandStep")}`}
                  </span>
                ),
                status: "process"
              }
            ]}
          />
          <DeployCommand
            classes={{ container: styles.commandContainer }}
            data={{
              version: version,
              environment: deployEnvFieldValue
            }}
            noError={noError}
          />
        </div>
      </DeployTabs>
      {!isLoading && (
        <InformationAlert
          className={styles.infoAlert}
          message={
            <span>
              {t("deployments.alert.text")}
              <br />
              <b>{t("deployments.alert.note")}</b>
            </span>
          }
        />
      )}
    </PageWithBreadcrumbTemplate>
  )
}
