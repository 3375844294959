import { Runtime } from "@/icons"
import { InsightsIcons } from "@/molecules/InsightsIcons"

import type { InsightsPackageCellProps } from "./InsightsPackageCell.types"

export const InsightsPackageCell = ({ runtime }: InsightsPackageCellProps) => (
  <InsightsIcons
    isKeyTranslation
    icons={[Runtime]}
    textPopoverByIndex={["general.runtime"]}
    values={[runtime]}
  />
)
