import { TABS_PAGE_CONTAINER_ID } from "@/const/tab.constants"
import styles from "./PageWithTabsTemplate.module.scss"

import type { PageWithTabsTemplateProps } from "./PageWithTabsTemplate.types"

export const PageWithTabsTemplate = ({ header }: PageWithTabsTemplateProps) => (
  <div id={TABS_PAGE_CONTAINER_ID} className={styles.container}>
    <div className={styles.header}>{header}</div>
  </div>
)
