import { Divider } from "@/atoms"
import { getAllSeverities } from "@/domain/vulnerability"
import { ExpandedRowTable } from "@/molecules/ExpandedRowTable"
import { LabelExpandedRow } from "@/molecules/LabelExpandedRow"
import { getColumnsExpandedRow } from "./getColumnsExpandedRow"
import { LicenseList } from "./LicenseList"
import styles from "./SbomTabImageDrawer.module.scss"

import type { ParamsGetColumnsTable } from "@/organisms/Drawers/components/TabDrawerTemplate"

export const ExpandedRow =
  (params: ParamsGetColumnsTable) =>
  ({ vulnerabilities, lastSeen, license }: any) => {
    const { t } = params

    return (
      <>
        <div className={styles.labelsContainerExpandableRow}>
          <LabelExpandedRow
            label={`${t("general.license")}:`}
            value={<LicenseList licenses={license?.items ?? []} />}
          />
          <Divider type="vertical" />
          <LabelExpandedRow
            label={`${t("general.lastSeen")}:`}
            value={lastSeen}
          />
        </div>

        <ExpandedRowTable
          rowKey="cve"
          columns={getColumnsExpandedRow(params)}
          dataSource={getAllSeverities()
            .map((severity) => vulnerabilities[severity] || [])
            .flat()}
        />
      </>
    )
  }
