import { Button, Divider, Drawer, notification, Spin } from "@/atoms"
import useServices from "@/hooks/useServices"
import { JiraSoftwareLogoOutlined, Language } from "@/icons"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useCustomMaskClass } from "@/organisms/Drawers/hooks/useDrawer"
import {
  usePackageImageInfo,
  usePackageVulnerabilities
} from "@/pages/SbomPage/application/package/usePackage"
import { ApplicationInfo } from "@/pages/SbomPage/components/molecules/ApplicationInfo"
import { FixCard } from "@/pages/SbomPage/components/molecules/FixCard"
import { PackageRisk } from "@/pages/SbomPage/components/molecules/PackageRisk"
import { TitlePackageDrawer } from "../../molecules/TitlePackageDrawer"

import styles from "./PackageDrawer.module.scss"

import type { PackageDrawerProps } from "."

export const PackageDrawer: React.FC<PackageDrawerProps> = (propsDrawer) => {
  const { open, onClose, record: packageData } = propsDrawer
  const data = packageData || {
    id: "",
    language: undefined,
    name: "",
    version: "",
    reportIds: []
  }
  const {
    response: vulnerabilitiesResponse = {},
    isLoading: isLoadingVulnerabilities
  } = usePackageVulnerabilities(packageData)

  const {
    response: imageInformationResponse = {},
    isLoading: isLoadingImageInformation
  } = usePackageImageInfo(packageData)

  const props = {
    ...data,
    ...vulnerabilitiesResponse,
    ...imageInformationResponse,
    key: undefined
  }

  const { auth } = useServices()

  const isDemoUser = auth.isDemo()
  const [, maskClassName] = useCustomMaskClass()

  return (
    <Drawer
      contentWrapperStyle={{ width: "var(--width-drawer-sbom)" }}
      open={open}
      maskClassName={maskClassName}
      title={
        <TitleDrawer
          icon={<Language language={data.language} />}
          title={`${data.name} (${data.version})`}
          subTitle={<TitlePackageDrawer {...data} />}
          aside={
            isDemoUser && (
              <Button
                onClick={() => {
                  notification.success({
                    description:
                      "KOS-637 added to backlog in Kodem Security: Kosmos board and was assigned to @infrayoni",
                    message: "Task created",
                    duration: 3
                  })
                }}
              >
                <JiraSoftwareLogoOutlined /> Create Jira Issue
              </Button>
            )
          }
          classes={{ aside: styles.asideTitleDrawer }}
        />
      }
      onClose={onClose}
    >
      <div className={styles.wrap}>
        <FixCard
          isDemoUser={isDemoUser}
          classes={{ card: styles.easyFixCard }}
          data={packageData}
        />
        <ApplicationInfo isLoading={isLoadingImageInformation} {...props} />
        <Divider className={styles.divider} />
        {!isLoadingVulnerabilities ? (
          <div className={styles.section}>
            <PackageRisk {...props} />
          </div>
        ) : (
          <div className={styles.spinContainer}>
            <Spin />
          </div>
        )}
      </div>
    </Drawer>
  )
}
