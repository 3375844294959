import { useGetSbomFilters } from "@/api/useSbom"
import { getOptionsForFilters } from "./useFilters.selector"

export const useFilters = (t: (str: string) => string) => {
  const { response, isLoading } = useGetSbomFilters(
    {},
    { select: (response) => getOptionsForFilters(response, t) }
  )

  return { response, isLoading }
}
