import cn from "classnames"

import { useCreateKey } from "@/api/useAuthKeys"
import { Text } from "@/atoms"
import { EVENTS } from "@/const/event.constants"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { isKnownUserRole, isUserAdmin } from "@/domain/user"
import { useAuthKeys } from "@/pages/SettingsPage/application/auth/useAuthKeys"
import { AuthenticationMethodTab } from "../../organisms/AuthenticationMethodTab"
import { AuthKeysTab } from "../../organisms/AuthKeysTab"
import { CodeScanSastTab } from "../../organisms/CodeScanSastTab"
import { LabelsTab } from "../../organisms/LabelsTab"
import { ScmTab } from "../../organisms/ScmTab"
import { ScopesTab } from "../../organisms/ScopesTab"
import { UsersTab } from "../../organisms/UsersTab"

import styles from "./SettingsPage.module.scss"

import type { User } from "@/domain/user"
import type { TFunction } from "@/hooks/useTranslation"

type LabelProps = { key: string; activeKey?: string; admin?: boolean }

const getClasses = (key: string, activeKey?: string) =>
  cn(styles.title, activeKey === key && styles.active)

const getSectionTitle = (
  key: string,
  t: TFunction,
  featureFlags: Partial<Features.Flags> & { admin?: boolean } = {}
) => ({
  key,
  admin: false,
  label: <Text className={styles.titleInTab}>{t(`sections.${key}`)}</Text>,
  disabled: true,
  ...featureFlags
})

export const getLabel = (
  title: string,
  props: LabelProps & Partial<Features.Flags>
) => {
  const { admin, key, activeKey, ...featureFlags } = props
  const className = getClasses(key, activeKey)

  return {
    key,
    admin,
    label: <Text className={className}>{title}</Text>,
    ...featureFlags
  }
}

export const getItemsTabsByRole = (
  user: User,
  { activeKey, t }: { activeKey: undefined | string; t: TFunction }
) => {
  if (!isKnownUserRole(user)) {
    return []
  }

  const TAB_KEYS = TAB_KEYS_BY_PAGE.SETTINGS

  return [
    getSectionTitle("organization", t),
    {
      children: <AuthenticationMethodTab />,
      ...getLabel(t("auth.title"), { admin: true, key: TAB_KEYS[2], activeKey })
    },
    {
      children: <UsersTab />,
      ...getLabel(t("users.title"), { key: TAB_KEYS[0], activeKey })
    },
    getSectionTitle("preferences", t),
    {
      children: <ScopesTab />,
      ...getLabel(t("scopes.title"), { key: TAB_KEYS[1], activeKey })
    },
    {
      children: <LabelsTab />,
      ...getLabel(t("labels.title"), {
        key: TAB_KEYS[9],
        admin: true,
        activeKey
      })
    },
    {
      children: <CodeScanSastTab />,
      ...getLabel(t("sections.sast"), {
        admin: true,
        key: TAB_KEYS[8],
        activeKey
      })
    },
    getSectionTitle("protect", t),
    {
      children: (
        <ScmTab
          policyType="SCM"
          settingsRoute={NAMES_ROUTES.SETTINGS.SCM}
          analyticsEvents={EVENTS.ANALYTIC_EVENTS.SETTINGS.POLICIES.SCM}
        />
      ),
      ...getLabel(t("scm.title"), {
        key: TAB_KEYS[3],
        activeKey
      })
    },
    {
      children: (
        <ScmTab
          policyType="CI"
          settingsRoute={NAMES_ROUTES.SETTINGS.CI}
          analyticsEvents={EVENTS.ANALYTIC_EVENTS.SETTINGS.POLICIES.CI}
        />
      ),
      ...getLabel(t("ci.title"), {
        key: TAB_KEYS[7],
        activeKey
      })
    },
    getSectionTitle("keys", t),
    {
      children: (
        <AuthKeysTab
          tabName={TAB_KEYS[4]}
          title={t("apikeys.title")}
          description={t("apikeys.description")}
          useAuthKeys={useAuthKeys}
          useCreateKey={useCreateKey}
          keyType="API_KEYS"
          analyticsEvents={EVENTS.ANALYTIC_EVENTS.SETTINGS.AUTH_KEYS.API_KEYS}
        />
      ),
      ...getLabel(t("apikeys.title"), {
        key: TAB_KEYS[4],
        activeKey
      })
    },
    {
      children: (
        <AuthKeysTab
          tabName={TAB_KEYS[5]}
          title={t("deploymentkeys.title")}
          description={t("deploymentkeys.description")}
          useAuthKeys={useAuthKeys}
          useCreateKey={useCreateKey}
          keyType="DEPLOYMENT_KEYS"
          analyticsEvents={
            EVENTS.ANALYTIC_EVENTS.SETTINGS.AUTH_KEYS.DEPLOYMENT_KEYS
          }
        />
      ),
      ...getLabel(t("deploymentkeys.title"), {
        key: TAB_KEYS[5],
        admin: true,
        activeKey
      })
    },
    {
      children: (
        <AuthKeysTab
          tabName={TAB_KEYS[6]}
          title={t("servicekeys.title")}
          description={t("servicekeys.description")}
          useAuthKeys={useAuthKeys}
          useCreateKey={useCreateKey}
          keyType="SERVICE_KEYS"
          analyticsEvents={
            EVENTS.ANALYTIC_EVENTS.SETTINGS.AUTH_KEYS.SERVICE_KEYS
          }
        />
      ),
      ...getLabel(t("servicekeys.title"), {
        key: TAB_KEYS[6],
        admin: true,
        activeKey
      })
    }
  ].filter(({ admin }) => {
    if (admin) {
      return isUserAdmin(user)
    }

    return true
  })
}
