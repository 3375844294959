import { Divider, SeverityLetter } from "@/atoms"
import { ExploitabilityTag } from "@/molecules/ExploitabilityTag"
import { LinkRisk } from "@/molecules/LinkRisk"
import { LabelExpandedRow } from "../LabelExpandedRow"

import styles from "./VulnerabilitiesPackageCell.module.scss"

import type { TFunction } from "@/hooks/useTranslation"

export const VulnerabilitiesPackageCell =
  (t?: TFunction) =>
  (
    _: unknown,
    { severity, cve, cvss, fix, fixVersions, exploitMaturity }: any
  ) => {
    const fixString = (fixVersions || fix || []).join(", ")

    return (
      <div className={styles.container}>
        <SeverityLetter severity={severity} />
        <LinkRisk cve={cve} classes={{ text: styles.cve }} />
        <Divider className={styles.divider} type="vertical" />
        <LabelExpandedRow className={styles.cvss} label="CVSS:" value={cvss} />
        <Divider className={styles.divider} type="vertical" />
        <LabelExpandedRow
          label="Fix:"
          value={<span className={styles.fixValue}>{fixString || "-"}</span>}
          style={{ maxWidth: 550 }}
          ellipsis={{
            tooltip: {
              rootClassName: styles.tooltip,
              title: (
                <div className={styles.tooltip}>
                  <ul>
                    {(fixVersions || []).map((version: string) => (
                      <li key={version}>{version}</li>
                    ))}
                  </ul>
                </div>
              )
            }
          }}
        />
        <ExploitabilityTag t={t} maturity={exploitMaturity} />
      </div>
    )
  }
