import { ScoreExplanationProps } from "@/api/useTriage.types"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { SeverityTag } from "@/molecules/SeverityTag"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { InsightsCell } from "@/pages/TriagePage/components/molecules/InsightsCell"

export const ScoreExplanation = ({
  record,
  flex,
  bordered = true
}: {
  record: ScoreExplanationProps
  bordered?: boolean
  flex?: boolean
}) => {
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const insights = {
    tag: true,
    runtime: false,
    internetFacing: "UNDETERMINED" as const,
    ingress: false,
    inTheWildExploitMaturity: false,
    pocExploitMaturity: false
  }

  const { riskInsights, epss, cvss, severity } = record
  const exposure =
    riskInsights.internetFacing === "YES" || !!riskInsights.ingress
  const emptyValue = "-"
  const epssValue = !!epss ? `${(Number(epss) * 100).toFixed(2)}%` : epss
  return (
    <OverviewDescriptions
      flex={flex}
      bordered={bordered}
      column={1}
      layout="horizontal"
      items={[
        {
          label: (
            <OverviewDescriptions.Label>
              {t("general.severity")}
            </OverviewDescriptions.Label>
          ),
          children: (
            <span>
              <SeverityTag t={t} severity={severity} />
              <span>{!!cvss && ` (CVSS ${cvss})`}</span>
            </span>
          )
        },
        {
          label: (
            <OverviewDescriptions.Label>
              {t("general.usage")}
            </OverviewDescriptions.Label>
          ),
          className: "tag-score-explanation",
          children: riskInsights.runtime
            ? InsightsCell(
                t,
                !!internetFacingFF
              )({
                ...insights,
                runtime: riskInsights.runtime
              })
            : emptyValue
        },
        {
          label: <OverviewDescriptions.Label>EPSS</OverviewDescriptions.Label>,
          children: epssValue ?? emptyValue
        },
        {
          label: (
            <OverviewDescriptions.Label>
              Exploit Maturity
            </OverviewDescriptions.Label>
          ),
          children:
            riskInsights.exploitMaturity === "IN_THE_WILD"
              ? InsightsCell(
                  t,
                  !!internetFacingFF
                )({
                  ...insights,
                  inTheWildExploitMaturity:
                    riskInsights.exploitMaturity === "IN_THE_WILD"
                })
              : emptyValue,
          className: "tag-score-explanation"
        },
        {
          label: (
            <OverviewDescriptions.Label>
              {t("general.exposure")}
            </OverviewDescriptions.Label>
          ),
          children: exposure
            ? InsightsCell(
                t,
                !!internetFacingFF
              )({
                ...insights,
                internetFacing: riskInsights.internetFacing,
                ingress: riskInsights.ingress
              })
            : emptyValue,
          className: "tag-score-explanation"
        }
      ]}
    />
  )
}
