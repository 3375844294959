import cn from "classnames"

import { Card, Popover, Tag, Text } from "@/atoms"
import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { useLogger } from "@/hooks/useLogger"
import { ExternalLinkIcon } from "@/molecules/ExternalLinkIcon"
import { ImportCommandCard } from "@/molecules/ImportCommandCard"
import type { ImageDetailsPackageData } from "@/pages/SbomPage/application/package/usePackage.types"
import { BaseImageInfo } from "../BaseImageInfo"
import { CodeRepositories } from "../CodeRepositories"
import { Description } from "../Description"
import { PackagePaths } from "../PackagePaths"

import styles from "./ImageCard.module.scss"

export const ImageCard = ({
  specDeclarations,
  packagePaths,
  imageName,
  imageAccessPermission,
  historyEntries,
  baseImageDigest,
  baseImageNames
}: ImageDetailsPackageData) => {
  const { logger } = useLogger()
  const isPublicImage = imageAccessPermission === "public"

  return (
    <Card
      className={styles.card}
      title={
        <div className={styles.title}>
          <div
            className={cn(
              styles.wrapImageLabel,
              isPublicImage && styles.isPublicImage
            )}
          >
            <Description
              label="Image: "
              classes={{ label: styles.imageDescription }}
            >
              <Popover
                rootClassName={styles.rootClassImagePopover}
                content={
                  <Text
                    copyable={{
                      text: imageName,
                      onCopy: () => {
                        logger.info(ANALYTIC_EVENTS.SBOM.COPYABLE.IMAGE_NAME, {
                          children: imageName
                        })
                      }
                    }}
                  >
                    {imageName}
                  </Text>
                }
              >
                <Text ellipsis>{imageName}</Text>
              </Popover>
              <ExternalLinkIcon
                href={`/inventory/images?imageName=${imageName}`}
              />
            </Description>
          </div>
          {isPublicImage && <Tag className={styles.tag}>Public Image</Tag>}
        </div>
      }
    >
      <CodeRepositories data={specDeclarations} />
      <PackagePaths data={packagePaths} />
      <BaseImageInfo digest={baseImageDigest} items={baseImageNames} />
      {!!historyEntries.length && (
        <div>
          <Description label="Package import command:" />
          <div className={styles.historyEntriesWrap}>
            {historyEntries.map((item, index: number) => (
              <ImportCommandCard key={index} {...item} />
            ))}
          </div>
        </div>
      )}
    </Card>
  )
}
