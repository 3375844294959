import { Select } from "@/atoms"
import { HeaderPage } from "@/organisms/HeaderPage"
import type { InventoryEnvironmentProps } from "../../../hooks/useDashboard.types"
import styles from "./HeaderDashboardPage.module.scss"

export const HeaderDashboardPage = (props: InventoryEnvironmentProps) => {
  const { currentValue, response, setCurrentValueInventoryEnvironment } = props
  const { options } = response || {}

  return (
    <HeaderPage
      titleTranslationKey="navigation.dashboard"
      sider={
        <Select
          className={styles.select}
          options={options}
          value={currentValue}
          data-testid="select-environment-header-dashboard"
          onChange={(_, values) => {
            if (Array.isArray(values)) {
              setCurrentValueInventoryEnvironment(values[0])
            } else {
              setCurrentValueInventoryEnvironment(values)
            }
          }}
        />
      }
    />
  )
}
