import { useNavigate } from "react-router-dom"

import { Button } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ViewsOutlined } from "@/icons"

import styles from "./FooterMenu.module.scss"

import { useFeatureFlags } from "@/hooks/useUser"
import { FooterMenuProps } from "."

export const FooterMenu: React.FC<FooterMenuProps> = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { applyDisabled, clearDisabled, manageScopesDisabled, actions } = props
  const releaseDataAuthorizationFF = useFeatureFlags("releaseDataAuthorization")

  return (
    <div className={styles.container}>
      <Button
        icon={<ViewsOutlined />}
        disabled={manageScopesDisabled}
        onClick={() => {
          actions.setOpenKeys([])
          navigate("/settings/scopes")
        }}
      >
        {t("settings.scopes.menu.manageScopes")}
      </Button>
      <div>
        {!releaseDataAuthorizationFF && (
          <Button
            type="text"
            onClick={() => actions.cancelScope()}
            disabled={clearDisabled}
          >
            {t("general.clear")}
          </Button>
        )}
        <Button
          disabled={applyDisabled}
          onClick={() => {
            actions.applyScope()
            actions.setOpenKeys([])
          }}
        >
          {t("general.apply")}
        </Button>
      </div>
    </div>
  )
}
