import { Ingress, InternetFacing, PublicImage } from "@/icons"
import { InsightsIcons } from "@/molecules/InsightsIcons"

import type { InsightsApplicationCellProps } from "./InsightsApplicationCell.types"

export const InsightsApplicationCell =
  ({ internetFacingFF }: { internetFacingFF?: boolean }) =>
  ({
    imageAccessPermission,
    ingress,
    internetFacing
  }: InsightsApplicationCellProps) => (
    <InsightsIcons
      isKeyTranslation
      icons={
        internetFacingFF
          ? [PublicImage, Ingress, InternetFacing]
          : [PublicImage, Ingress]
      }
      textPopoverByIndex={[
        "general.publicImage",
        "general.ingress",
        "general.internetFacing"
      ]}
      values={[
        imageAccessPermission === "public",
        !!ingress,
        internetFacing === "Yes"
      ]}
    />
  )
