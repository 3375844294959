import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ResourceCell } from "./ResourceCell"

export const getColumnsTable = ({
  onRowClick
}: {
  onRowClick: (params: { key: string }, sourceControl: boolean) => void
}): object[] => {
  return [
    {
      title: "Resources",
      width: "100%",
      render: ResourceCell(onRowClick),
      ...getDefaultColumnParams("resource")
    }
  ]
}
