import type { SortOrder } from "@/atoms"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"

export const getDefaultColumnParams = (
  dataIndex: string,
  sorter?: Omit<ORDER_BY_PROPS, "onSorterChange">,
  attributes: { customSorterName?: string } = {}
) => {
  return {
    dataIndex,
    key: dataIndex,
    ellipsis: true,
    ...(sorter
      ? {
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder:
            sorter.orderBy === dataIndex ? sorter.ascending : undefined,
          sortDirections: ["descend", "ascend", "descend"] as SortOrder[],
          ...attributes
        }
      : {})
  }
}
