import ServicesProvider from "@/organisms/ServicesProvider"
import ThemeProvider from "@/organisms/ThemeProvider"
import UserProvider from "@/organisms/UserProvider"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"

import "@/theme/index.scss"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/light.css"

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <ServicesProvider>
        <BrowserRouter>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </ServicesProvider>
    </ThemeProvider>
  </QueryClientProvider>
)
