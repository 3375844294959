import { LabelTab } from "../LabelTab"

export const ItemsTabs = (state: any, drawerState: any, props: any) => {
  const { tabs, components } = props
  const [{ activeTab, counts, forceRender }, actions] = state

  return tabs.map((name: string, index: number) => {
    const ChildrenComponent = components[index]

    return {
      key: name,
      forceRender,
      label: <LabelTab name={name} count={counts[name]} />,
      children: (
        <ChildrenComponent
          isActive={name === activeTab}
          actions={actions}
          {...drawerState}
        />
      )
    }
  })
}
