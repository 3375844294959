import { TEASERS } from "@/const/teaser.constants"
import { getCountByAllSeverity } from "@/domain/vulnerability"
import { useLogger } from "@/hooks/useLogger"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { ExportButton } from "@/molecules/ExportButton"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import { useFilters } from "@/pages/SbomPage/application/filters/useFilters"
import { useSbom } from "@/pages/SbomPage/application/sbom/useSbom"
import { TabTemplate } from "@/templates/TabTemplate"
import { PackageDrawer } from "../PackageDrawer"
import { getColumnsTable } from "./getColumnsTable"
import {
  EXPLOITABILITY_FILTER_OPTIONS,
  getFilters,
  getItemsKeys
} from "./getItemsFilter"

import type { TRecord } from "."

export const SbomTab = ({ scope }: { scope: string }) => {
  const { t } = useTranslation()
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { response: options, isLoading: isLoadingFilters } = useFilters(t)
  const { logger, EVENTS } = useLogger()
  const { response, isLoading, filters } = useSbom(
    {
      scope
    },
    {},
    getItemsKeys()
  )
  const { data, metadata } = response || {}
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const columns = getColumnsTable(orderByProps, {
    t,
    internetFacingFF: !!internetFacingFF
  })
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const filterDisabled = !!dataReadinessFF || isLoadingFilters

  useTooltip(data)

  return (
    <>
      <TabTemplate<TRecord>
        resizable
        highlightRow
        columns={columns}
        dataSource={data}
        metadata={metadata}
        loading={isLoading || isLoadingFilters}
        locale={{
          teasers: {
            dataReadiness: true,
            kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
          }
        }}
        onChange={(_pagination, _filters, sorter: any) => {
          onSorterChange(sorter)

          const { field, order } = sorter

          logger.info(EVENTS.ANALYTIC_EVENTS.SBOM.SORT, { field, order })
        }}
        SearchComponent={
          <Filter.Provider isLoading={filterDisabled}>
            <Filter.Template
              isLoading={filterDisabled}
              aside={
                <ExportButton
                  isLoading={filterDisabled}
                  filters={filters}
                  exportType="Sbom"
                />
              }
              search={
                filterDisabled ? (
                  <InputSmartSearchLoading />
                ) : (
                  <SmartSearch
                    id={scope}
                    data={options}
                    filters={getFilters(t)}
                  />
                )
              }
            >
              <Filter.Checkbox
                keyFilter="environment"
                items={options?.["environment"]}
                placeHolderDefaultValue="Environments"
                isLoading={filterDisabled}
              />
              <Filter.Insights
                filters={getFilters(t)}
                isLoading={filterDisabled}
              />
              <Filter.Checkbox
                keyFilter="exploitMaturity"
                items={EXPLOITABILITY_FILTER_OPTIONS}
                placeHolderDefaultValue="Exploitability"
                isLoading={filterDisabled}
              />
            </Filter.Template>
          </Filter.Provider>
        }
        onRowClick={(record: TRecord) => {
          logger.info(EVENTS.ANALYTIC_EVENTS.SBOM.DRAWER.OPENED, {
            package: record.name,
            score: record.kodemScore,
            insights: record.insights,
            vulnerabilitiesLength: record.vulnerabilitiesLength,
            vulnerabilities: getCountByAllSeverity(record.vulnerabilities || {})
          })
        }}
        DrawerComponent={PackageDrawer}
      />
    </>
  )
}
