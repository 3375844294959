import { BaseImage, InternetFacing, Runtime } from "@/icons"
import { InsightsIcons } from "@/molecules/InsightsIcons"

import type { Package } from "@/domain/package"
import type { InsightsSbomCellArgs, InsightsSbomCellProps } from "."

export const InsightsSbomCell =
  ({ internetFacingFF }: InsightsSbomCellArgs) =>
  (
    {
      runtime,
      fromBaseImage,
      triggerable // TODO: temporary variable for demo
    }: InsightsSbomCellProps,
    { internetFacing }: Package
  ) => {
    const commonProps = { triggerable, isKeyTranslation: true }

    if (internetFacingFF) {
      return (
        <InsightsIcons
          icons={[Runtime, InternetFacing, BaseImage]}
          textPopoverByIndex={[
            "general.runtime",
            "general.internetFacing",
            "general.baseImage"
          ]}
          values={[runtime, internetFacing === "Yes", fromBaseImage]}
          {...commonProps}
        />
      )
    }

    return (
      <InsightsIcons
        icons={[Runtime, BaseImage]}
        textPopoverByIndex={["general.runtime", "general.baseImage"]}
        values={[runtime, fromBaseImage]}
        {...commonProps}
      />
    )
  }
