import { Card, Link } from "@/atoms"
import { getLinkByVulnerability } from "@/domain/vulnerability"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { ImpactCell } from "@/molecules/ImpactCell"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { IntroduceThroughInsightsICell } from "@/pages/TriagePage/components/molecules/IntroduceThroughInsightsICell"
import { PackageVersions } from "../PackageVersions"

import styles from "./SummaryCard.module.scss"

export const SummaryCard = ({ record }: { record: any }) => {
  const { t } = useTranslation()
  const { onRowClick } = useDrawer("")

  if (!record) return null

  const { packageName, introducedThroughInsights, cwe } = record
  const { applicationsCount } = record as any
  const emptyValue = "-"
  const someIntroducedThroughInsightsExist = Object.keys(
    introducedThroughInsights
  ).some(
    (key) =>
      !!introducedThroughInsights[key as keyof typeof introducedThroughInsights]
  )

  const items = [
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.packageVersion_other")}
        </OverviewDescriptions.Label>
      ),
      children: (
        <PackageVersions
          name={packageName}
          version={record.packageVersions.join(", ")}
        />
      )
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.introducedThrough")}
        </OverviewDescriptions.Label>
      ),
      children: someIntroducedThroughInsightsExist
        ? IntroduceThroughInsightsICell(t)({
            tag: true,
            ...introducedThroughInsights
          })
        : emptyValue
    },
    {
      label: <OverviewDescriptions.Label>CWE</OverviewDescriptions.Label>,
      children: cwe.description ? (
        <Link
          className={styles.cweLink}
          href={getLinkByVulnerability({ cve: cwe.id })}
          target="_blank"
        >
          {cwe.id}
        </Link>
      ) : (
        emptyValue
      )
    },
    {
      label: <OverviewDescriptions.Label>OWASP</OverviewDescriptions.Label>,
      children: record?.owasp ? record.owasp : emptyValue
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.impact")}
        </OverviewDescriptions.Label>
      ),
      children:
        applicationsCount.items.length > 0
          ? ImpactCell(t, onRowClick)(applicationsCount)
          : emptyValue
    }
  ]

  return (
    <Card
      className={styles.container}
      title={<Heading level={6} title="Summary" />}
    >
      <OverviewDescriptions flex column={1} layout="horizontal" items={items} />
    </Card>
  )
}
