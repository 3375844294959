import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useHttp } from "@/hooks/useHttp"

import type { Config, Params } from "@/hooks/useHttp"
import type {
  PackageImageInformationResponse,
  PackageVulnerabilitiesResponse,
  SbomFiltersResponse,
  SbomPackagesParams,
  SbomParams,
  SbomResponse
} from "./useSbom.types"

export const useGetSbom = <T = SbomResponse, S = T>(
  params: Params<SbomParams>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.SBOM.LIST, params, config)

  return context
}

export const useGetPackageVulnerabilitiesById = <
  T = PackageVulnerabilitiesResponse,
  S = T
>(
  packageId: string,
  params: Params<SbomPackagesParams>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.SBOM.PACKAGES}/${packageId}/vulnerabilities`,
    params,
    config
  )

  return context
}

export const useGetPackageImageInformationById = <
  T = PackageImageInformationResponse,
  S = T
>(
  packageId: string,
  params: Params<SbomPackagesParams>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.SBOM.PACKAGES}/${packageId}/images`,
    params,
    config
  )

  return context
}

export const useGetSbomFilters = <T = SbomFiltersResponse, S = T>(
  params?: Params<{}>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.SBOM.FILTERS, params, config)

  return context
}
