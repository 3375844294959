import { ScopeMenu } from "@/organisms/ScopeMenu"
import { TitleSideNavigation as Title } from "./TitleSideNavigation"

import type { ScopeSideNavigationComponent } from ".."

export const ScopeSideNavigation: ScopeSideNavigationComponent = (props) => {
  const { collapsed, t } = props

  return (
    <>
      <Title collapsed={collapsed}>{t("general.scope")}</Title>
      <ScopeMenu collapsed={collapsed} />
    </>
  )
}
