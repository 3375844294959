import { createContext, useContext } from "react"

export const ExportContext = createContext<{
  tasksByUrl: Record<string, {}>
  addTask: (url: string, params?: Record<string, string>) => void
  removeTask: (url: string) => void
}>({
  tasksByUrl: {},
  addTask: () => {},
  removeTask: () => {}
})

export const useExport = () => {
  const context = useContext(ExportContext)

  return context
}
