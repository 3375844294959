import cn from "classnames"
import { useParams } from "react-router-dom"

import { Card, Form, Input, message, Radio, Select } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useTranslation } from "@/hooks/useTranslation"
import {
  AlignLeftOutlined,
  EyeOutlined,
  FileProtectOutlined,
  ThunderboltOutlined
} from "@/icons"
import { InformationPopover } from "@/molecules/InformationPopover"
import {
  ALL_SCOPE,
  SPECIFIC_SCOPE
} from "@/pages/SettingsPage/application/policies/usePolicies.selector"
import { isSuppressSubTypePolicy } from "../../../helpers"
import { ButtonListForm } from "../../molecules/ButtonListForm"
import { RemoveButtonListForm } from "../../molecules/RemoveButtonListForm"
import { SectionFormTemplate } from "../../templates/SectionFormTemplate"
import { ConditionSection } from "./components/ConditionSection"
import { TagInput } from "./components/TagInput"
import {
  getConditionsForPayload,
  getEmptyScope,
  getInitialValues,
  getScopeForPayload
} from "./helpers"

import styles from "./ScmForm.module.scss"

import type { ScmFormComponent, ScmFormProps, SpecificScope } from "."

export const ScmForm: ScmFormComponent = (props: ScmFormProps) => {
  const {
    isNewPolicy,
    form,
    response,
    actions,
    scopeOptions,
    detailsTranslationKey = "general.details"
  } = props
  const { t } = useTranslation()
  const { subType } = useParams()
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "settings.policies.form"
  })
  const { t: tMessage } = useTranslation("translation", {
    keyPrefix: "settings.policies.messages"
  })

  const rules = [
    { required: true, message: t("error.requiredField") },
    {
      validator(_: unknown, value: unknown) {
        if (
          !Array.isArray(value) ||
          value?.findIndex((v) => v === "*") === -1
        ) {
          return Promise.resolve()
        }

        return Promise.reject(new Error("Invalid value"))
      }
    }
  ]

  const currentScopeFieldName = "currentScope"
  const currentScope = Form.useWatch(currentScopeFieldName, form)
  const isSpecificScope = currentScope === SPECIFIC_SCOPE
  const rulesCurrentScope = isSpecificScope ? rules : undefined

  const specificScopesFieldName = "specificScopes"
  const specificScopes = Form.useWatch<[SpecificScope]>(
    specificScopesFieldName,
    form
  )

  const [messageApi, messageContext] = message.useMessage()
  const { conditionsOptions = [] } = props
  const setFormIsDirty = () => form.setFieldsValue({ isDirty: true })

  return (
    <Form
      scrollToFirstError
      className={styles.form}
      form={form}
      initialValues={getInitialValues(response, isNewPolicy)}
      layout="vertical"
      onChange={setFormIsDirty}
      onFinish={async (values) => {
        try {
          const {
            currentScope,
            specificScopes,
            conditions,
            currentConditions,
            isDirty,
            ...rest
          } = values

          const payload = {
            policy: {
              scope: getScopeForPayload(values),
              conditions: getConditionsForPayload(values),
              enabled: isNewPolicy ? true : response?.data[0]?.enabled,
              policyType: subType
                ? subType?.charAt(0).toUpperCase() + subType?.slice(1)
                : undefined,
              ...rest
            }
          }

          if (!isNewPolicy) {
            if (!response) return

            const { id, name } = response.data[0]
            await actions.updateContext.mutateAsync({
              needle: `/${id}`,
              data: payload
            })

            message.success(tMessage("updated.success", { name }))
          } else {
            const result = await actions.createContext.mutateAsync(payload)

            const { name } = result.data[0]
            message.success(tMessage("created.success", { name }))
          }

          props.logCreatePolicy(payload.policy, isNewPolicy)
          const { ROOT, SCM } = NAMES_ROUTES.SETTINGS
          actions.navigate(`/${ROOT}/${SCM}/${subType}`)
        } catch (err: any) {
          if (typeof err?.data?.detail === "string")
            return messageApi.error(err.data.detail)

          messageApi.error(t("error.serverError"))
        }
      }}
    >
      <SectionFormTemplate
        id="details"
        title={tForm(`sections.${detailsTranslationKey}`)}
        Icon={AlignLeftOutlined}
      >
        <Form.Item
          required={false}
          name="name"
          label={tForm("name.label")}
          rules={rules}
        >
          <Input placeholder={tForm("name.placeholder")} />
        </Form.Item>
        <Form.Item
          required={false}
          name="description"
          label={tForm("description.label")}
        >
          <Input placeholder={tForm("description.placeholder")} />
        </Form.Item>
      </SectionFormTemplate>
      <SectionFormTemplate
        id="scope"
        title={tForm("sections.scope")}
        Icon={EyeOutlined}
      >
        <Form.Item required={false} name={currentScopeFieldName} rules={rules}>
          <Radio.Group className={styles.radioGroup}>
            <Card className={styles.card}>
              <Radio value={ALL_SCOPE}>{tForm("allRepo")}</Radio>
            </Card>
            <Card className={styles.card}>
              <Radio value={SPECIFIC_SCOPE}>
                <span className={styles.titleWithPopover}>
                  {tForm("specificScope")}
                  <InformationPopover.Popover
                    content={
                      <InformationPopover.CommonTemplate>
                        {tForm("scope.tooltip")}
                      </InformationPopover.CommonTemplate>
                    }
                  />
                </span>
              </Radio>
              {isSpecificScope && (
                <Form.List name={specificScopesFieldName}>
                  {(fields, operation) => {
                    const options = (scopeOptions || []).map((option) => {
                      return {
                        disabled:
                          (specificScopes || []).findIndex(
                            (scope) => scope?.name === option.value
                          ) !== -1,
                        ...option
                      }
                    })

                    return (
                      <div className={cn(styles.scopeGroup, styles.onlyButton)}>
                        {fields.map((field, index) => {
                          const name = specificScopes?.[index]?.name
                          const inputPlaceholder = scopeOptions?.find(
                            (option) => option.value === name
                          )?.placeholder

                          return (
                            <div key={field.key} className={styles.listItem}>
                              <Form.Item
                                className={styles.formItem}
                                name={[field.name, "name"]}
                                rules={rulesCurrentScope}
                              >
                                <Select
                                  disabled={!rulesCurrentScope}
                                  options={options}
                                  placeholder={tForm(
                                    "scope.placeholders.select"
                                  )}
                                  fieldNames={{
                                    label: "label",
                                    value: "value"
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                className={styles.formItem}
                                name={[field.name, "value"]}
                                rules={rulesCurrentScope}
                                validateTrigger={["onBlur"]}
                              >
                                <TagInput
                                  disabled={!name}
                                  placeholder={inputPlaceholder}
                                />
                              </Form.Item>
                              <RemoveButtonListForm
                                onClick={() => operation.remove(index)}
                              />
                            </div>
                          )
                        })}
                        <ButtonListForm
                          disabled={fields.length === scopeOptions?.length}
                          onClick={() => {
                            operation.add(getEmptyScope())
                          }}
                        >
                          {t("general.and")}
                        </ButtonListForm>
                      </div>
                    )
                  }}
                </Form.List>
              )}
            </Card>
          </Radio.Group>
        </Form.Item>
      </SectionFormTemplate>
      <SectionFormTemplate
        id="conditions"
        title={tForm("sections.conditions")}
        Icon={FileProtectOutlined}
      >
        <ConditionSection
          form={form}
          conditionsOptions={conditionsOptions}
          rules={rules}
          setFormIsDirty={setFormIsDirty}
        />
      </SectionFormTemplate>
      {!isSuppressSubTypePolicy(subType) && (
        <SectionFormTemplate
          classes={{ title: styles.actionsTitle }}
          id="actions"
          title={tForm("sections.actions")}
          Icon={ThunderboltOutlined}
        >
          <Form.Item required={false} name="action" rules={rules}>
            <Radio.Group className={styles.radioGroup}>
              <Radio value="WARN">{tForm("warn")}</Radio>
              <Radio value="FAIL">{tForm("fail")}</Radio>
            </Radio.Group>
          </Form.Item>
        </SectionFormTemplate>
      )}
      <Form.Item className={styles.hide} name="isDirty" />
      {messageContext}
    </Form>
  )
}
