import { Button, Dropdown, Tooltip } from "@/atoms"
import { useExport } from "@/hooks/useExport"
import { useLogger } from "@/hooks/useLogger"
import { DownloadOutlined } from "@ant-design/icons"

import { useSearchParams } from "react-router-dom"
import type { ExportButtonProps } from "."
import { getExportItems } from "./getExportItems.helper"

export const ExportButton = ({ exportType, isLoading }: ExportButtonProps) => {
  const exportItems = getExportItems(exportType)
  const [searchParams] = useSearchParams()
  const { tasksByUrl, addTask } = useExport()
  const { logger } = useLogger()
  const {
    url: keyByUrl,
    label,
    queryStringParams = [],
    analytic,
    excludeParams = false
  } = exportItems[0]
  const isDisabled = !!tasksByUrl[keyByUrl] || isLoading

  if (exportItems.length > 1) {
    return (
      <Dropdown
        disabled={isDisabled}
        menu={{
          items: exportItems,
          onClick: ({ key }) => {
            const result = exportItems.find((item) => item.key === key)

            if (result) {
              addTask(result.url, result.params)

              if (analytic) {
                const { event, type } = analytic

                logger.info(event, { type })
              }
            }
          }
        }}
        trigger={["click"]}
      >
        <Button icon={<DownloadOutlined />} />
      </Dropdown>
    )
  }

  const include = () => {
    const params = queryStringParams.reduce(
      (acc, name) => {
        const value = searchParams.get(name)

        if (value) acc[name] = value

        return acc
      },
      {} as Record<string, string>
    )
    return params
  }

  const exclude = () => {
    queryStringParams.forEach((name) => {
      searchParams.delete(name)
    })

    const result: Record<string, string> = {}
    searchParams.forEach((value, key) => {
      result[key] = value
    })

    return result
  }

  return (
    <Tooltip title={label}>
      <Button
        disabled={isDisabled}
        icon={<DownloadOutlined />}
        onClick={() => {
          addTask(keyByUrl, excludeParams ? exclude() : include())
          if (analytic) {
            const { event, type } = analytic
            logger.info(event, { type })
          }
        }}
      />
    </Tooltip>
  )
}
