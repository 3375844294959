import * as PoliciesApi from "@/api/usePolicies"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { capitalizeFirstLetter } from "@/helpers/string.helpers"
import { useLogger } from "@/hooks/useLogger"
import {
  getConditionsOptions,
  getPoliciesScm,
  getScopeOptionsScm
} from "./usePolicies.selector"

import type { PolicyType } from "@/api/usePolicies.types"
import type { Config, Params } from "@/hooks/useHttp"

const hooksMap = {
  SCM: {
    useGet: PoliciesApi.useGetPolicies,
    useCreate: PoliciesApi.useCreatePolicy,
    useUpdate: PoliciesApi.useUpdatePolicy,
    useDelete: PoliciesApi.useDeletePolicy,
    useScopeOptions: PoliciesApi.useScopeOptions,
    useConditionsOptions: PoliciesApi.useConditionsOptions,
    queryUrl: RESOURCES_NAMES.SETTINGS.POLICIES
  },
  CI: {
    useGet: PoliciesApi.useGetCIPolicies,
    useCreate: PoliciesApi.useCreateCIPolicy,
    useUpdate: PoliciesApi.useUpdateCIPolicy,
    useDelete: PoliciesApi.useDeleteCIPolicy,
    useScopeOptions: PoliciesApi.useScopeOptions,
    useConditionsOptions: PoliciesApi.useCIConditionsOptions,
    queryUrl: RESOURCES_NAMES.SETTINGS.CI_POLICIES
  }
}

export const usePolicies = (
  attr: { type: PolicyType; subType?: string },
  needle?: string,
  config?: Config<ReturnType<typeof getPoliciesScm>>
) => {
  const { type, subType } = attr

  const params = {
    needle,
    policyType: !!subType ? capitalizeFirstLetter(subType) : subType
  }
  const { useGet } = hooksMap[type]
  const { response: scopeOptions, isLoading: scopeOptionsIsLoading } =
    useScopeOptions(type)
  const options = needle ? { staleTime: 0, gcTime: 0 } : {}
  const context = useGet(params, {
    select: (data) => getPoliciesScm(data, scopeOptions),
    enabled: !scopeOptionsIsLoading,
    ...options,
    ...config
  })

  return context
}

export const useCreatePolicy = (type: PolicyType, params?: Params) => {
  const { logger } = useLogger()
  const { useCreate, queryUrl } = hooksMap[type]
  const context = useCreate(params, undefined, (queryClient) => ({
    onSuccess: (response) => {
      try {
        const { id, policyType } = response.data[0]

        queryClient.setQueryData([`${queryUrl.LIST}/${id}`, {}], response)
        queryClient.refetchQueries({
          queryKey: [queryUrl.LIST, { policyType }]
        })
      } catch (err) {
        logger.error(err as Error)
      } finally {
        return Promise.resolve(response)
      }
    }
  }))

  return context
}

export const useUpdatePolicy = (type: PolicyType, params?: Params) => {
  const { logger } = useLogger()
  const { useUpdate, queryUrl } = hooksMap[type]

  const context = useUpdate(params, undefined, (queryClient) => ({
    onSuccess: async (response) => {
      try {
        const { id, policyType } = response.data[0]

        if (id) {
          queryClient.setQueryData([`${queryUrl.LIST}/${id}`, {}], response)
          queryClient.refetchQueries({
            queryKey: [queryUrl.LIST, { policyType }]
          })
        }
      } catch (err) {
        logger.error(err as Error)
      } finally {
        return Promise.resolve(response)
      }
    }
  }))

  return context
}

export const useDeletePolicy = (type: PolicyType, params?: Params) => {
  const { useDelete } = hooksMap[type]
  const context = useDelete(params)

  return context
}

export const useConditionsOptions = (
  type: PolicyType,
  params: Params<{ policyType: string }>
) => {
  const { useConditionsOptions } = hooksMap[type]
  const context = useConditionsOptions(params, {
    select: getConditionsOptions
  })

  return context
}

export const useScopeOptions = (type: PolicyType) => {
  const { useScopeOptions: useScopeOptionsHook } = hooksMap[type]
  const context = useScopeOptionsHook({
    select: getScopeOptionsScm
  })

  return context
}
