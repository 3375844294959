import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { FixabilityCell } from "@/molecules/FixabilityCell"
import { ImpactCell } from "@/molecules/ImpactCell"
import { IssueTitleCell } from "@/molecules/IssueTitleCell"
import { KodemScoreIssueCell } from "@/molecules/KodemScoreCell"
import { TriageIssueActionCell } from "@/molecules/TriageActionCell"
import type { ParamsGetColumnsTable } from "@/organisms/Drawers/components/TabDrawerTemplate"
import { InsightsCell } from "@/pages/TriagePage/components/molecules/InsightsCell"
import { IntroduceThroughInsightsICell as IntroduceThroughInsightsCell } from "@/pages/TriagePage/components/molecules/IntroduceThroughInsightsICell"

export const getColumnsTable = ({
  orderByProps: sorter,
  t,
  internetFacingFF,
  setIssueRecord
}: ParamsGetColumnsTable) => [
  {
    title: "Issues",
    render: IssueTitleCell(t, setIssueRecord),
    width: "100%",
    ...getDefaultColumnParams("packageName")
  },
  {
    title: "Score",
    width: 72,
    className: "center-align-tab-template-component",
    render: KodemScoreIssueCell(t),
    ...getDefaultColumnParams("kodemScore", sorter)
  },
  {
    title: "Insights",
    width: 100,
    render: InsightsCell(t, !!internetFacingFF),
    ...getDefaultColumnParams("riskInsights")
  },
  {
    title: "Fixability",
    width: 130,
    render: FixabilityCell(t),
    ...getDefaultColumnParams("fixability")
  },
  {
    title: "Introduce Through",
    width: 150,
    render: IntroduceThroughInsightsCell(t),
    ...getDefaultColumnParams("introducedThroughInsights")
  },
  {
    title: "Impact",
    width: 80,
    className: "center-align-tab-template-component",
    render: ImpactCell(t),
    ...getDefaultColumnParams("applicationsCount", sorter, {
      customSorterName: "relatedApplications"
    })
  },
  {
    title: "Actions",
    width: 80,
    className: "center-align-tab-template-component",
    render: TriageIssueActionCell(t),
    ...getDefaultColumnParams("tickets")
  },
  {
    title: "Discovered",
    width: 110,
    render: CellTable,
    ...getDefaultColumnParams("discoveredDaysAgo", sorter, {
      customSorterName: "discoveredAt"
    })
  }
]
