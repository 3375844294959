import { Button, Space, Text, Tooltip } from "@/atoms"
import { useToggle } from "@/hooks/useToggle"
import { useTranslation } from "@/hooks/useTranslation"
import { BulletedList } from "../BulletedList"
import { Description } from "../Description"

import styles from "./CodeRepositories.module.scss"

import { ExternalLinkOutlined } from "@/icons"
import type { CodeRepositoriesProps } from "./CodeRepositories.types"

export const CodeRepositories = ({ data = [] }: CodeRepositoriesProps) => {
  const { value: showMoreToggled, toggle } = useToggle(false)
  const { t } = useTranslation("translation", { keyPrefix: "sbom.drawer" })

  if (!data || data.length === 0) {
    return null
  }

  const moreThenOne = data.length > 1
  const pathsOfFirstElement = data[0]?.paths
  const moreThenTwoPaths = pathsOfFirstElement?.length > 2
  const arr = showMoreToggled
    ? data
    : [
        {
          ...data[0],
          paths: pathsOfFirstElement?.slice(0, 2) || pathsOfFirstElement
        }
      ]
  const classes = moreThenOne
    ? { item: styles.orderedListItem }
    : { item: styles.oneItem, wrap: styles.oneItemWrap }
  const useShowMore = moreThenOne || moreThenTwoPaths

  return (
    <div className={styles.container}>
      <Description label={`${t("codeRepository", { count: data.length })}:`}>
        {moreThenOne ? null : arr[0].repoName}
        {moreThenOne || (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/inventory/branch?repositoryName=${arr[0].repoName}`}
          >
            <ExternalLinkOutlined className={styles.linkIcon} />
          </a>
        )}
      </Description>
      <BulletedList ordered classes={classes}>
        {arr.map(({ repoName: name, paths: fileAndLines }) => (
          <div>
            {moreThenOne && <Text>{name}</Text>}
            <BulletedList classes={{ item: styles.lineItem }}>
              {(fileAndLines || []).map(
                ({ filePath, relativeFilePath, lineNumber }) => (
                  <Tooltip title={<Text copyable>{filePath}</Text>}>
                    <Space className={styles.file}>
                      <Text strong>File:</Text>
                      <Text ellipsis dir="rtl">
                        <bdi>{relativeFilePath}</bdi>
                      </Text>
                      <Text strong>|</Text>
                      <Text strong>Line:</Text>
                      {lineNumber}
                    </Space>
                  </Tooltip>
                )
              )}
            </BulletedList>
          </div>
        ))}
      </BulletedList>
      {useShowMore && (
        <Button className={styles.button} type="link" onClick={toggle}>
          {showMoreToggled ? t("showLess") : t("showMore")}
        </Button>
      )}
    </div>
  )
}
