import { Tooltip } from "@/atoms"
import { IssuesSummaryFC } from "../IssuesSummaryFC"

import styles from "./IssuesSummaryCell.module.scss"

import type { NumberConvertor } from "@/hooks/useNumberConvertor"
import type { TFunction } from "@/hooks/useTranslation"
import type { IssuesSummary } from "."

export const IssuesSummaryCell =
  (
    { convertors }: { t: TFunction; convertors: NumberConvertor },
    { limit }: { limit?: number } = {}
  ) =>
  (issues: IssuesSummary) => {
    if (!Array.isArray(issues)) return null
    const other: IssuesSummary = limit ? issues.slice(limit) : []
    const hiddenCount = limit
      ? other.reduce((acc, [, value]) => acc + value, 0)
      : 0
    return (
      <span className={styles.issueSummaryContainer}>
        <IssuesSummaryFC
          issues={issues.slice(0, limit)}
          convertors={convertors}
        />
        {!!hiddenCount && (
          <Tooltip
            title={
              <IssuesSummaryFC
                issues={other}
                convertors={convertors}
                Tooltip={Tooltip}
              />
            }
          >
            <span
              className={styles.hiddenCount}
            >{`+${convertors.abbreviateNumber(hiddenCount)}`}</span>
          </Tooltip>
        )}
      </span>
    )
  }
