import cn from "classnames"
import { useEffect, useRef, useState } from "react"

import { Divider, Search, Spin, Text } from "@/atoms"
import { CheckOutlined } from "@/icons"
import { Heading } from "@/molecules/Heading"

import styles from "./ListMenu.module.scss"

import type { ListMenuProps } from "."

export const ListMenu: React.FC<ListMenuProps> = (props) => {
  const {
    title,
    items: data,
    setSelectedItem,
    selectedId,
    description,
    footer,
    empty,
    search,
    isLoading
  } = props
  const searchRef = useRef<any>(null)
  const stopPropagation = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation()
  }

  const [items, setItems] = useState(data)
  const isEmpty = !items?.length && !search
  const showList = !isEmpty

  useEffect(() => {
    if (isLoading && !search) return

    setItems(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div
      className={styles.container}
      onKeyDown={stopPropagation}
      onClick={stopPropagation}
    >
      <div className={styles.header}>
        <Heading level={4} title={title} />
        {showList && (
          <>
            {description && <Text className={styles.text}>{description}</Text>}
            <Search
              allowClear
              ref={searchRef}
              onSearch={props.actions.setSearch}
            />
          </>
        )}
      </div>
      <Divider className={styles.divider} />
      {showList && (
        <div className={styles.list}>
          {items.map((item) => {
            const { id, name } = item
            const isActive = id === selectedId

            return (
              <div
                key={id}
                className={cn(styles.item, isActive && styles.active)}
                onClick={() => setSelectedItem(item)}
              >
                <Text ellipsis key={id}>
                  {name}
                </Text>
                {isActive && <CheckOutlined />}
              </div>
            )
          })}
          {isLoading && search && (
            <div className={styles.loading}>
              <Spin />
            </div>
          )}
        </div>
      )}
      <Divider className={styles.divider} />
      {!!footer && showList && <div className={styles.footer}>{footer}</div>}
      {isEmpty && !!empty && <div className={styles.empty}>{empty}</div>}
    </div>
  )
}
