import { IssuesTab } from "@/organisms/Drawers/components/IssuesTab"
import {
  getDefaultFiltersInventoryImageIssuesDrawer,
  getDefaultParamsOpenSourceIssue
} from "@/organisms/Drawers/hooks/useParamsFilter"

import type { IssuesTabImageDrawerComponent } from "."

export const IssuesTabImageDrawer: IssuesTabImageDrawerComponent = (props) => {
  return (
    <IssuesTab
      getInitialParamsFilter={getDefaultFiltersInventoryImageIssuesDrawer}
      defaultParams={getDefaultParamsOpenSourceIssue()}
      includeApplicationFilter
      {...props}
    />
  )
}
