import cn from "classnames"

import { ExploitMaturity } from "@/domain/package"
import { ExploitIcon } from "@/icons"
import { IconTag } from "@/molecules/IconTag"

import styles from "./ExploitabilityTag.module.scss"

export const ExploitabilityTag = ({
  maturity,
  t
}: {
  maturity?: ExploitMaturity
  t?: (key: string) => string
}) => {
  if (!maturity || maturity === "UNDETERMINED") {
    return null
  }

  const onlyIcon = !t

  return (
    <IconTag
      classes={{
        tag: onlyIcon ? cn(styles.tag, styles.onlyIcon) : styles.tag
      }}
      icon={<ExploitIcon background={onlyIcon} type={maturity} />}
      text={onlyIcon ? "" : t(`general.exploitability.${maturity}`)}
    />
  )
}
