import { Collapse, Skeleton, Title } from "@/atoms"
import { Description } from "../Description"
import { ImageCard } from "../ImageCard"

import styles from "./ApplicationInfo.module.scss"

import type { ApplicationInfoProps } from "."

export const ApplicationInfo = (props: ApplicationInfoProps) => {
  const {
    isLoading,
    service,
    environment,
    namespace,
    imagesDetails = []
  } = props

  return (
    <Collapse ghost defaultActiveKey={["info"]} className={styles.collapse}>
      <Collapse.Panel
        className={styles.panel}
        key="info"
        header={
          <Title className={styles.title} level={5}>
            Application Context
          </Title>
        }
      >
        <Description label="Application:">{service}</Description>
        <Description label="Environment:">{environment}</Description>
        <Description label="Namespace:">{namespace}</Description>
        <>
          <Description label="Related images:" />
          {isLoading ? (
            <Skeleton active />
          ) : imagesDetails && imagesDetails.length > 0 ? (
            <div className={styles.wrapImageCard}>
              {imagesDetails.map((image, index) => (
                <ImageCard key={index} {...image} />
              ))}
            </div>
          ) : (
            "No related images found"
          )}
        </>
      </Collapse.Panel>
    </Collapse>
  )
}
