import cn from "classnames"
import { useState } from "react"

import { Button, Card, Text, Tooltip } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CloudSync, SyncOutlined } from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"
import { DeploymentKeyButton } from "../../molecules/DeploymentKeyButton"
import { InlineInput } from "../../molecules/InlineInput"
import { getDefaultValues, getTextForValue } from "./helpers"

import styles from "./DeployCommand.module.scss"

import type { DeployCommandProps } from "."

export const DeployCommand = (props: DeployCommandProps) => {
  const { data, noError, classes = {} } = props
  const { version, environment } = data || {}
  const { t } = useTranslation()
  const defaultValues = getDefaultValues({ environment })
  const [values, setValues] = useState(defaultValues)
  const [error, setError] = useState(!noError)
  const firstLine = `helm upgrade --install kortex kortex-${version}.tgz \\`
  const secondLine = `--namespace ${values.namespace} \\`
  const thirdLine = `--set kortex.environment=${getTextForValue(
    values.environment,
    defaultValues.environment
  )},\\`
  const fourthLine = `kortex.deploymentKey=${getTextForValue(
    values.deploymentKey,
    defaultValues.deploymentKey
  )},\\`
  const fifthLine = `kortex.image.pullSecretName=${getTextForValue(
    values.pullSecretName,
    defaultValues.pullSecretName
  )} \\`
  const sixthLine = `--create-namespace`

  const onChange = ({ name, value }: any) => {
    setValues({ ...values, [name]: value })
  }
  const onError = () => setError(true)

  return (
    <Card
      className={cn(styles.container, classes.container, error && styles.error)}
    >
      {!error && (
        <>
          <div className={styles.content}>
            <Text>{firstLine}</Text>
            <Text>
              --namespace
              <InlineInput
                key={`${values.key}_namespace`}
                name="namespace"
                value={values.namespace}
                onChange={onChange}
              />
              <Text>{" \\ "}</Text>
            </Text>
            <Text>
              --set kortex.environment=
              <InlineInput
                key={`${values.key}_environment`}
                name="environment"
                value={values.environment}
                onChange={onChange}
              />
              <Text>{", \\"}</Text>
            </Text>
            <Text>
              kortex.deploymentKey=
              <InlineInput
                key={`${values.key}_deploymentKey`}
                name="deploymentKey"
                value={values.deploymentKey}
                onChange={onChange}
                buttons={<DeploymentKeyButton onError={onError} />}
              />
              <Text>{", \\"}</Text>
            </Text>
            <Text>
              kortex.image.pullSecretName=
              <InlineInput
                key={`${values.key}_pullSecretName`}
                name="pullSecretName"
                value={values.pullSecretName}
                onChange={onChange}
              />
              <Text>{" \\ "}</Text>
            </Text>
            <Text>--create-namespace</Text>
          </div>
          <div className={styles.buttons}>
            <Tooltip title={t("deployments.resetChart")}>
              <Button
                icon={<SyncOutlined />}
                size="small"
                onClick={() => {
                  setValues(getDefaultValues())
                }}
              />
            </Tooltip>
            <CopyButton
              type="primary"
              text={`${firstLine}\n${secondLine}\n${thirdLine}\n${fourthLine}\n${fifthLine}\n${sixthLine}`}
            >
              {t("general.copy")}
            </CopyButton>
          </div>
        </>
      )}
      {error && (
        <>
          <CloudSync className={styles.icon} />
          <Text className={styles.text}>
            {t("deployments.error.loadError")}
          </Text>
        </>
      )}
    </Card>
  )
}
