import { lazy, Suspense } from "react"
import { useParams } from "react-router-dom"

import { Flex, Spin } from "@/atoms"
import { HeaderPage } from "@/organisms/HeaderPage"
import { PageTemplate } from "@/templates/PageTemplate"

import styles from "./ThreatPage.module.scss"

const Content = lazy(() => import("./ContentThreatPage"))

export const ThreatPage = () => {
  const { incident } = useParams()

  return (
    <PageTemplate
      full
      header={<HeaderPage titleTranslationKey="Incidents" />}
      classes={{ main: incident && styles.main }}
    >
      <Suspense
        fallback={
          <Flex align="center" justify="center" className={styles.loader}>
            <Spin />
          </Flex>
        }
      >
        <Content incident={incident} />
      </Suspense>
    </PageTemplate>
  )
}
