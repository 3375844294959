import { useActiveKeyTabsInventory } from "@/pages/InventoryPage/hooks/useActiveKeyTabsInventory"
import { PageWithTabsTemplate } from "@/templates/PageWithTabsTemplate"
import { HeaderInventoryPage } from "./components/organisms/HeaderInventoryPage"

export const InventoryPage = () => {
  return (
    <PageWithTabsTemplate
      header={
        <HeaderInventoryPage
          useActiveKeyTabsInventory={useActiveKeyTabsInventory}
        />
      }
    />
  )
}
