import type { Fixability, PackageRecord } from "@/api/useIssues.types"
import type { CodeRepoFinding, PackageFixRecord } from "@/domain/issue"
import type { SourceItem } from "./SourceList.types"

export const findingsToSources = (findings: CodeRepoFinding[]) => {
  // grouped findings by source
  const grouped = findings.reduce(
    (acc, curr) => {
      if (!acc[curr.source]) {
        acc[curr.source] = {
          computedFixes: [],
          introducedThrough: [],
          computationError: null
        }
      }
      acc[curr.source].computedFixes = [
        ...acc[curr.source].computedFixes,
        ...curr.computedFixes
      ]
      acc[curr.source].introducedThrough = [
        ...acc[curr.source].introducedThrough,
        ...curr.introducedThrough
      ]

      if (curr.fixability) {
        acc[curr.source].fixability = curr.fixability
      }

      if (curr.computationError) {
        acc[curr.source].computationError = curr.computationError
      }

      return acc
    },
    {} as Record<
      string,
      {
        computedFixes: PackageFixRecord[]
        introducedThrough: PackageRecord[][]
        fixability?: Fixability
        computationError: string | null
      }
    >
  )

  const sources: SourceItem[] = Object.entries(grouped).map(([key, val]) => {
    return {
      name: key,
      computedFixes: val.computedFixes,
      introducedThrough: val.introducedThrough,
      fixability: val.fixability,
      computationError: val.computationError
    }
  })

  return sources
}
