export const getFilters = (
  keys: { key: string; type: string }[] = [],
  scope: string,
  searchParams: URLSearchParams
) =>
  keys.reduce(
    (acc, { key, type }) => {
      const value = searchParams.get(key)

      if (type === "checkbox") {
        acc[key] = value?.split(",") || []
      }

      if (type === "radio" || type === "search") {
        acc[key] = value ? [value] : []
      }

      if (type === "input" && value) {
        acc[key] = value
      }

      return acc
    },
    { scope } as Record<string, string | string[]>
  )
