import { useLogger } from "@/hooks/useLogger"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { ExpandedRowTable } from "@/molecules/ExpandedRowTable"
import { ExpandIconTable } from "@/molecules/ExpandIconTable"
import { TabTemplate } from "@/templates/TabTemplate"

import { useFeatureFlags } from "@/hooks/useUser"
import type { TabDrawerTemplateProps } from "."

export const TabDrawerTemplate = <D, R = any>(
  props: TabDrawerTemplateProps<D, R>
) => {
  const {
    getColumnsTable,
    dataContext,
    filtersContext,
    FilterComponent,
    headerComponent,
    setIssueRecord
  } = props
  const { onSorterChange, ...orderByProps } = useOrderBy({
    onChange: filtersContext?.patchParams
  })
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const { logger, EVENTS } = useLogger()
  const { response, isLoading } = dataContext as any
  const { data, metadata } = response || {}
  const paramsGetColumnsTable = {
    orderByProps,
    t,
    internetFacingFF,
    setIssueRecord
  }

  useTooltip(data)

  return (
    <TabTemplate
      dataSource={data}
      metadata={metadata}
      loading={isLoading}
      columns={getColumnsTable(paramsGetColumnsTable)}
      expandable={{
        expandIcon: ExpandIconTable,
        expandRowByClick: true,
        rowExpandable: ({ expandable }) => !!expandable,
        expandedRowRender: (record) => {
          if (props.expandedRowRender) {
            return props.expandedRowRender(paramsGetColumnsTable)(record)
          }

          const { rowData } = record
          const { getColumnsExpandedRow } = props

          if (!getColumnsExpandedRow) {
            return null
          }

          return (
            <ExpandedRowTable
              rowKey="key"
              columns={getColumnsExpandedRow(paramsGetColumnsTable)}
              dataSource={rowData}
            />
          )
        }
      }}
      asideFooter={props.asideFooter}
      rowSelection={props.rowSelection}
      onChange={(_pagination, _filters, sorter: any) => {
        onSorterChange(sorter)

        const { field, order } = sorter

        logger.info(EVENTS.ANALYTIC_EVENTS.DRAWER.SORT, { field, order })
      }}
      onPaginationChange={(pageNumber) => {
        filtersContext.onPaginationChange(pageNumber)
        logger.info(EVENTS.ANALYTIC_EVENTS.DRAWER.PAGINATION, { pageNumber })
      }}
      SearchComponent={FilterComponent}
      headerComponent={headerComponent}
    />
  )
}
