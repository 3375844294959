import { Collapse } from "@/atoms"
import { mapSeverities } from "@/domain/vulnerability"
import cn from "classnames"
import { SeverityLabel } from "../SeverityLabel"
import { VulnerabilityCard } from "../VulnerabilityCard"

import styles from "./VulnerabilityList.module.scss"

import type { VulnerabilityListProps } from "."

export const VulnerabilityList = ({
  vulnerabilitiesBySeverityLevel,
  highestExploitMaturities
}: VulnerabilityListProps) => (
  <>
    {mapSeverities((severity, i) => {
      const vulnerabilities = vulnerabilitiesBySeverityLevel[severity]

      if (!vulnerabilities || severity === "negligible") {
        return null
      }

      const count = vulnerabilities.length
      return (
        <Collapse
          ghost
          key={severity}
          rootClassName={cn(styles.container, i !== 0 && styles.margin)}
        >
          <Collapse.Panel
            key={severity}
            header={
              <SeverityLabel
                count={count}
                severity={severity}
                highestExploitMaturity={highestExploitMaturities?.[severity]}
              />
            }
          >
            {vulnerabilities.map((data: any, index) => (
              <VulnerabilityCard key={`${severity}${index}`} data={data} />
            ))}
          </Collapse.Panel>
        </Collapse>
      )
    })}
  </>
)
