import { useRef } from "react"

import { Anchor } from "@/atoms"

import styles from "./AsideBreadcrumbPage.module.scss"

import type { AsideBreadcrumbPageComponent } from "."

export const AsideBreadcrumbPage: AsideBreadcrumbPageComponent = ({
  items
}) => {
  const anchorItems = useRef(
    items.map((item) => ({
      href: `#${item.key}`,
      ...item
    }))
  ).current

  return (
    <Anchor
      replace
      className={styles.container}
      items={anchorItems}
      getCurrentAnchor={(activeLink) => activeLink || anchorItems[0].href}
      offsetTop={24}
      getContainer={() =>
        document.getElementById("aside-page-with-breadcrumbs-template") ||
        window
      }
    />
  )
}
