import { Popover, Text } from "@/atoms"
import React from "react"
import styles from "./LicenseList.module.scss"

const MAX_LICENSES = 3

export const LicenseList: React.FC<{
  // List of licenses to display
  licenses: string[]
  // Number of licenses to display before abbreviating
  abbreviate?: number
}> = (props) => {
  const { licenses = [], abbreviate = MAX_LICENSES } = props

  if (licenses.length === 0) {
    return <>-</>
  }

  const count = licenses.length
  const fullLicensesList = licenses.join(", ")
  const fullList = <Text className={styles.text}>{fullLicensesList}</Text>

  if (count <= abbreviate) {
    return fullList
  }

  return (
    <Popover content={fullList}>
      <Text className={styles.text}>
        {licenses.slice(0, abbreviate).join(", ")}{" "}
        <span className={styles.counter}>+{count - abbreviate}</span>
      </Text>
    </Popover>
  )
}
