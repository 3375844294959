import { useGetSbom } from "@/api/useSbom"
import { useQueryString } from "@/hooks/useQueryString"
import useServices from "@/hooks/useServices"
import { useSearchParams } from "react-router-dom"
import { getFilters } from "./filters"
import { getSbomTabsData } from "./useSbom.selector"

export const useSbom = (
  params: { scope: string },
  config?: { enabled?: boolean },
  keys: { key: string; type: string }[] = []
) => {
  const { auth } = useServices()
  const [searchParams] = useSearchParams()
  const paramsQueryString = useQueryString([
    "orderBy",
    "ascending",
    "descending"
  ])

  const filters = encodeURIComponent(
    JSON.stringify(getFilters(keys, params.scope, searchParams))
  )

  const { response, isLoading } = useGetSbom(
    {
      orderBy: "name",
      ascending: true,
      ...paramsQueryString,
      filters
    },
    {
      select: (res) => getSbomTabsData(res, { isDemo: auth.isDemo() }),
      ...(config || {})
    }
  )

  return { response, isLoading, filters, searchParams }
}
