import { useCreateKey } from "@/api/useAuthKeys"
import { SourceIntegrationType } from "@/api/useAuthKeys.types"
import { kodemCILink } from "@/api/useIntegrations.selector"
import { KodemCIPublicImageName } from "@/api/useIntegrations.types"
import { Button, Carousel, Modal, Steps, Text, Title, Tooltip } from "@/atoms"
import { isUserAdmin } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { KeyIcon, ReadOutlined } from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"
import { CreateKeyModal } from "@/pages/SettingsPage/components/organisms/AuthKeysTab"
import { useState } from "react"
import { useNavigate } from "react-router"
import { CarouselItem } from "./CarouselItem"
import styles from "./HowToUseIntegration.module.scss"
import { HowToUseProps, KodemCiIntegration } from "./HowToUseIntegration.types"

export const HowToUseIntegration = (props: HowToUseProps) => {
  const { user } = useUser()
  const { logger, EVENTS } = useLogger()

  const eventKey = props.name.toUpperCase() as KodemCiIntegration
  const { OPENED_GENERATE_KEY, COMMAND_GENERATE_KEY } =
    EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.KODEMCI[eventKey]

  const isGenerateDisabled = !isUserAdmin(user)
  const navigate = useNavigate()

  const [, modalContextHolder] = Modal.useModal()
  const [showModal, setShowModal] = useState<boolean | number>(false)
  const { t: tKodemCI } = useTranslation("translation", {
    keyPrefix: "integrations.connect.kodemCI"
  })

  const pullCommand = `docker pull ${KodemCIPublicImageName}:latest`
  const exportCommand = "export KODEM_SERVICE_KEY=secret"

  return (
    <>
      <div className={styles.howToUseSection}>
        <Title level={5} className={styles.title}>
          {props.title}
          <Button
            icon={<ReadOutlined />}
            type="link"
            href={kodemCILink}
            target="_blank"
          >
            {tKodemCI("readInstructions")}
          </Button>
        </Title>
        <Steps
          size="small"
          className={styles.steps}
          direction="vertical"
          children={
            <>
              <Steps.Step
                status="process"
                title={tKodemCI("steps.pull.title")}
                description={
                  <div>
                    <Text type="secondary">
                      {tKodemCI("steps.pull.description")}
                    </Text>
                    <pre className={styles.codeBlock}>
                      <code>{pullCommand}</code>
                      <CopyButton text={pullCommand} type="link" />
                    </pre>
                  </div>
                }
              />
              <Steps.Step
                status="process"
                title={tKodemCI("steps.generateKey.title")}
                description={
                  <div className={styles.stepItem}>
                    <Text type="secondary">
                      {tKodemCI("steps.generateKey.description")}
                    </Text>
                    <div className={styles.buttonWrapper}>
                      <Tooltip
                        title={
                          isGenerateDisabled &&
                          tKodemCI("steps.generateKey.buttonTooltip")
                        }
                        placement="top"
                      >
                        <Button
                          type="primary"
                          icon={<KeyIcon />}
                          onClick={() => {
                            logger.info(OPENED_GENERATE_KEY)
                            setShowModal(Date.now())
                          }}
                          disabled={isGenerateDisabled}
                        >
                          {tKodemCI("steps.generateKey.buttonText")}
                        </Button>
                      </Tooltip>
                    </div>

                    <Text type="secondary">
                      {tKodemCI("steps.generateKey.note")}
                    </Text>
                    <pre className={styles.codeBlock}>
                      <code>{exportCommand}</code>
                      <CopyButton text={exportCommand} type="link" />
                    </pre>
                  </div>
                }
              />
              <Steps.Step
                status="process"
                title={tKodemCI("steps.scan.title")}
                description={
                  props.howToUse?.runScanCommandSample !== "TBD" && (
                    <div>
                      <Text type="secondary">
                        {tKodemCI("steps.scan.description")}
                      </Text>
                      <Carousel arrows infinite={false}>
                        <CarouselItem
                          titleLine1={tKodemCI("steps.scan.scanSample")}
                          codeSnippet={props.howToUse?.runScanCommandSample}
                        />
                        <CarouselItem
                          titleLine1={tKodemCI(
                            "steps.scan.scanCompareSampleLine1"
                          )}
                          titleLine2={tKodemCI(
                            "steps.scan.scanCompareSampleLine2"
                          )}
                          codeSnippet={props.howToUse?.runScanCompareSample}
                        />
                      </Carousel>
                    </div>
                  )
                }
              />
              <Steps.Step
                status="process"
                title={tKodemCI(`steps.integrate.title.${props.name}`)}
                description={
                  props.howToUse?.integrationSample !== "TBD" && (
                    <div>
                      <Text type="secondary">
                        {tKodemCI(`steps.integrate.description.${props.name}`)}
                      </Text>
                      <div className={styles.integrationSample}>
                        <div>
                          <pre className={styles.codeBlock}>
                            <code className={styles.scrollable}>
                              {props.howToUse?.integrationSample}
                            </code>
                          </pre>
                        </div>
                        <div>
                          <CopyButton
                            text={props.howToUse?.integrationSample}
                            type="link"
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              />
              <Steps.Step
                status="process"
                title={tKodemCI("steps.ciPolicies.title")}
                description={
                  <Button
                    className={styles.noPadding}
                    type="link"
                    target="_blank"
                    onClick={() => navigate("/settings/ci")}
                  >
                    {tKodemCI("steps.ciPolicies.buttonText")}
                  </Button>
                }
              />
            </>
          }
        />
      </div>
      <CreateKeyModal
        key={`${showModal}`}
        open={!!showModal}
        useCreateKey={useCreateKey}
        logCreateKey={(keyName) => {
          logger.info(COMMAND_GENERATE_KEY, { keyName })
        }}
        keyType={"SERVICE_KEYS"}
        onClose={() => {
          setShowModal(false)
        }}
        sourceIntegration={props.name as SourceIntegrationType}
      />
      {modalContextHolder}
    </>
  )
}
