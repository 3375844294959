import {
  EXPLOITABILITY_FILTER_OPTIONS,
  SEVERITIES_FILTER_OPTIONS
} from "@/const/options.constants"
import { TFunction } from "@/hooks/useTranslation"

import type { ContextFilters } from "@/organisms/Drawers/hooks/useParamsFilter.types"
import type { Filters } from "@/organisms/SmartSearch/types/options.types"
import { getCVEFilter } from "@/pages/InventoryPage/utils/filters.utils"

export { SEVERITIES_FILTER_OPTIONS, EXPLOITABILITY_FILTER_OPTIONS }

export const getInitialValues = (props: ContextFilters) => props.params

export const getFilters = (t: TFunction): Filters => [
  {
    key: "name",
    label: t("filters.package"),
    type: "input",
    helper: [{ description: "Matches package names as a substring" }]
  },
  getCVEFilter(),
  {
    key: "severity",
    label: t("filters.severity"),
    type: "multiple",
    options: SEVERITIES_FILTER_OPTIONS
  },
  {
    key: "runtime",
    label: t("filters.runtime"),
    type: "single",
    order: 1,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO", disabled: true },
      { label: "N/A", value: "Undetermined", disabled: true }
    ],
    helper: [{ description: "Packages that are seen in runtime" }]
  },
  {
    key: "fromBaseImage",
    label: t("filters.fromBaseImage"),
    type: "single",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      { label: "N/A", value: "Undetermined", disabled: true }
    ]
  },
  {
    key: "exploitMaturity",
    label: t("filters.exploitability"),
    type: "multiple",
    options: EXPLOITABILITY_FILTER_OPTIONS
  }
]
