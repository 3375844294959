import {
  useGetPackageImageInformationById,
  useGetPackageVulnerabilitiesById
} from "@/api/useSbom"
import {
  getPackageImageInformationData,
  getPackageVulnerabilitiesData
} from "./usePackage.selector"

import type { Package } from "@/domain/package"

export const usePackageImageInfo = (packageData: Package | null) => {
  const { id, reportIds, language, name } = packageData || {
    id: "",
    reportIds: []
  }
  const enabled = !!id && reportIds.length > 0
  const { response, isLoading } = useGetPackageImageInformationById(
    id,
    { packageName: name, packageLanguage: language, reportId: reportIds },
    { enabled, select: getPackageImageInformationData }
  )

  return { response, isLoading }
}

export const usePackageVulnerabilities = (packageData: Package | null) => {
  const { id, reportIds, language, name } = packageData || {
    id: "",
    reportIds: []
  }
  const enabled = !!id && reportIds.length > 0
  const { response, isLoading } = useGetPackageVulnerabilitiesById(
    id,
    { packageName: name, packageLanguage: language, reportId: reportIds },
    { enabled, select: getPackageVulnerabilitiesData }
  )

  return { response, isLoading }
}
