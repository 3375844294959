import { useRef } from "react"

import { Filter } from "@/organisms/FilterNew/Filter"
import { SmartSearch } from "@/organisms/SmartSearch/SmartSearch"
import * as filterActions from "./getFilters"

import { useTranslation } from "@/hooks/useTranslation"
import type { SbomFilterCodeRepositoryDrawerComponent } from "."

export const SbomFilterCodeRepositoryDrawer: SbomFilterCodeRepositoryDrawerComponent =
  (props) => {
    const { t } = useTranslation()
    const initialState = useRef(filterActions.getInitialValues(props)).current
    const filters = useRef(filterActions.getFilters(t)).current

    return (
      <Filter.Provider
        initialState={initialState}
        useQueryString={false}
        onChange={props.onChange}
      >
        <Filter.Template
          search={
            <SmartSearch
              id="PackageFilterCodeRepositoriesDrawer"
              filters={filters}
            />
          }
        >
          <Filter.Checkbox
            order="none"
            keyFilter="severity"
            items={filterActions.SEVERITIES_FILTER_OPTIONS}
            placeHolderDefaultValue="Severity"
          />
          <Filter.Insights filters={filters} />
          <Filter.Checkbox
            keyFilter="exploitMaturity"
            items={filterActions.EXPLOITABILITY_FILTER_OPTIONS}
            placeHolderDefaultValue="Exploitability"
          />
        </Filter.Template>
      </Filter.Provider>
    )
  }
