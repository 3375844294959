import dayjs from "dayjs"

import { NAMESPACE_DEFAULT_VALUE } from "@/const/default-values"
import { formatVulnerabilitiesBySeverity } from "@/domain/vulnerability/vulnerability.formatters"

export const getSbomTabsData = (
  response: any,
  { isDemo }: { isDemo: boolean }
) => {
  const { data } = response || {}
  const { packages = [], totalResults = 0, pageNumber = 0 } = data || {}
  const metadata = { total: totalResults, page: pageNumber }

  return {
    metadata,
    data: packages.map(
      (
        {
          id,
          displayName,
          namespace,
          vulnerabilities = {},
          licenses = [],
          insights,
          ...dataRecord
        }: any,
        index: number
      ) => {
        const vulnerabilitiesKeys = Object.keys(vulnerabilities || {})
        let nextInsights = insights || {}

        if (isDemo) {
          const { name, service } = dataRecord
          const useSequelizeDemoPackage = name === "sequelize"
          const isTriggerable =
            name === "zlib1g" &&
            (service === "kosmos-api-v1" || service === "worker-kosmos-api-v1")

          if (useSequelizeDemoPackage) {
            nextInsights = { ...insights, direct: true }
          }

          if (isTriggerable) {
            nextInsights = { triggerable: true, ...insights }
          }
        }

        return {
          id,
          key: `${id}_${dataRecord.timestamp}_${dataRecord.version}_${dataRecord.service}}`,
          displayName,
          ...dataRecord,
          insights: nextInsights,
          namespace: namespace || NAMESPACE_DEFAULT_VALUE,
          license: {
            id: `${id}_${index}`,
            count: licenses.length - 1,
            items: licenses,
            label: licenses[0] || ""
          },
          vulnerabilitiesLength: vulnerabilitiesKeys.length,
          expandable: vulnerabilitiesKeys.length > 0,
          vulnerabilities: formatVulnerabilitiesBySeverity(vulnerabilities),
          lastSeen: dayjs(
            new Date(parseInt(dataRecord.timestamp) * 1000)
          ).format("YYYY-MM-DD HH:mm")
        }
      }
    )
  }
}
