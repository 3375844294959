import { Card, Divider, Empty, ScoreBadge, Space, Text, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { VulnerabilityList } from "../VulnerabilityList"

import { VulnerabilityCounts } from "@/molecules/VulnerabilityCounts"
import styles from "./PackageRisk.module.scss"

export const PackageRisk = ({
  highestExploitMaturities,
  vulnerabilities,
  vulnerabilitiesLength,
  kodemScore
}: any) => {
  const { t } = useTranslation()

  if (!vulnerabilities) {
    return null
  }
  if (vulnerabilitiesLength === 0) {
    return <Empty description={t("sbom.drawer.noCVE")} />
  }
  return (
    <div className={styles.container}>
      <Space className={styles.titleRow}>
        <Title className={styles.title} level={5}>
          Package Risk
        </Title>
        <Card className={styles.card}>
          <Text type="secondary">Kodem Score:</Text>
          <ScoreBadge score={kodemScore} />
          <Divider className={styles.divider} type="vertical" />
          <Text type="secondary">Vulnerabilities:</Text>
          <VulnerabilityCounts
            classes={{ tag: styles.vulnerabilityTag }}
            vulnerabilities={vulnerabilities}
          />
        </Card>
      </Space>
      <VulnerabilityList
        vulnerabilitiesBySeverityLevel={vulnerabilities}
        highestExploitMaturities={highestExploitMaturities}
      />
    </div>
  )
}
