import cn from "classnames"

import { Menu } from "@/atoms"
import { getColorNumberForUserAvatar } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import { UserItem } from "@/molecules/UserItem"
import styles from "./UserMenu.module.scss"

export const UserMenu = () => {
  const { user, signOut } = useUser()

  if (!user.email) {
    return <div className={cn(styles.container, styles.noUser)} />
  }

  return (
    <Menu
      className={styles.container}
      theme="dark"
      items={[
        {
          label: (
            <UserItem
              darkBackground
              testId="user-menu-item-primary-navigation"
              colorNumber={getColorNumberForUserAvatar(user.email)}
              {...user}
            />
          ),
          className: styles.userItemUserMenu,
          popupClassName: styles.popupClassName,
          key: "userItem",
          children: [
            {
              label: "Sign out",
              key: "signOut",
              onClick: signOut
            }
          ]
        }
      ]}
    />
  )
}
