import { IssuesTab } from "@/organisms/Drawers/components/IssuesTab"
import {
  getDefaultFiltersInventoryImageIssuesDrawer,
  getDefaultParamsOpenSourceIssue
} from "@/organisms/Drawers/hooks/useParamsFilter"

import type { IssueTabApplicationDrawerComponent } from "."

export const IssueTabApplicationDrawer: IssueTabApplicationDrawerComponent = (
  props
) => (
  <IssuesTab
    getInitialParamsFilter={getDefaultFiltersInventoryImageIssuesDrawer}
    defaultParams={getDefaultParamsOpenSourceIssue()}
    {...props}
  />
)
