import { useEffect, useState } from "react"

import { Spin } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./TotalPagination.module.scss"

import type { TotalPaginationComponent } from "."

export const TotalPagination: TotalPaginationComponent = (props) => {
  const convertors = useNumberConvertor()
  const { t } = useTranslation()
  const { projects, issues, isLoading } = props
  const [showSpin, setShowSpin] = useState(isLoading)

  useEffect(() => {
    setShowSpin(isLoading)
  }, [isLoading])

  if (showSpin)
    return <Spin className="ant-spin-default-style" size="small" spinning />

  return (
    <div className={styles.container}>
      <span>
        {convertors.intlNumber(projects)}
        <span className={styles.text}>
          {t("general.project", {
            count: projects
          })}
        </span>
      </span>
      <span>|</span>
      {typeof issues === "number" && (
        <span>
          {convertors.intlNumber(issues)}
          <span className={styles.text}>
            {t("general.issue", { count: issues })}
          </span>
        </span>
      )}
    </div>
  )
}
