import {
  getVulnerabilityColorBySeverity,
  mapSeverities,
  VulnerabilitiesBySeverity
} from "@/domain/vulnerability"
import { isEmpty } from "@/helpers/utils"
import cn from "classnames"
import styles from "./Vulnerabilities.module.scss"

type VulnerabilityCountsProps = {
  vulnerabilities: VulnerabilitiesBySeverity
  rowIndex?: number
  classes?: { tag?: string }
  disabled?: boolean
  disableIndividualBlocks?: boolean
}

export const VulnerabilityCounts = ({
  disabled,
  vulnerabilities,
  rowIndex,
  classes = {},
  disableIndividualBlocks = false
}: VulnerabilityCountsProps) => {
  return (
    <div
      className={cn(
        styles.container,
        ((!disableIndividualBlocks && disabled) || isEmpty(vulnerabilities)) &&
          styles.disabled
      )}
    >
      {mapSeverities((severity) => {
        if (severity === "negligible") {
          return null
        }

        const vulnerabilityBySeverity = vulnerabilities?.[severity]
        const vulnerabilityLength = vulnerabilityBySeverity?.length || 0
        const isDisabled = !vulnerabilityLength
        return (
          <span
            key={severity}
            className={cn(
              styles.tag,
              classes.tag,
              disableIndividualBlocks && isDisabled && styles.disabled
            )}
            style={{
              backgroundColor: getVulnerabilityColorBySeverity(severity)
            }}
            data-vulnerabilities={
              rowIndex === undefined ? rowIndex : `${rowIndex},${severity}`
            }
          >
            {vulnerabilityLength}
          </span>
        )
      })}
    </div>
  )
}
