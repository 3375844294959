import { useGetSbom } from "@/api/useSbom"
import { Tabs } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { LabelTabItem } from "@/molecules/LabelTabItem"
import { HeaderPage } from "@/organisms/HeaderPage"
import { useActiveKeyTabsInventory } from "@/pages/InventoryPage/hooks/useActiveKeyTabsInventory"
import { useEffect, useMemo, useTransition } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { SbomTab } from "../SbomTab"
import styles from "./HeaderSbomPage.module.scss"

export const HeaderSbomPage = () => {
  const TAB_KEYS = TAB_KEYS_BY_PAGE.SBOM
  const params = { pageNumber: 0, pageSize: 1 }
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const { response: sbomRuntimeResponse, isLoading: isLoadingRuntime } =
    useGetSbom({
      scope: "runtime",
      ...params
    })
  const { response: sbomAllResponse, isLoading: isLoadingAll } = useGetSbom({
    scope: "all",
    ...params
  })
  const isLoading = isLoadingRuntime || isLoadingAll
  const totalResultsRuntime = isLoading
    ? 0
    : sbomRuntimeResponse?.data?.totalResults
  const totalResultsAll = isLoading ? 0 : sbomAllResponse?.data?.totalResults

  const navigation = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const { abbreviateNumber } = useNumberConvertor({
    showPlaceholder: !!dataReadinessFF
  })
  const { activeKey } = useActiveKeyTabsInventory()

  const items = useMemo(
    () => [
      {
        key: TAB_KEYS[0],
        label: (
          <LabelTabItem
            title={t("general.runtime")}
            count={
              totalResultsRuntime ? abbreviateNumber(totalResultsRuntime) : 0
            }
            overflowCount={999}
          />
        ),
        children: <SbomTab scope="runtime" />
      },
      {
        key: TAB_KEYS[1],
        label: (
          <LabelTabItem
            title={t("general.all")}
            count={totalResultsAll ? abbreviateNumber(totalResultsAll) : 0}
            overflowCount={999}
          />
        ),
        children: <SbomTab scope="all" />
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalResultsRuntime, totalResultsAll]
  )

  useEffect(() => {
    if (activeKey === undefined) {
      navigation(`/sbom/${items[0].key}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition()

  return (
    <>
      <HeaderPage
        className={{ container: styles.container }}
        titleTranslationKey="navigation.sbom"
        tabs={
          <Tabs
            destroyInactiveTabPane={!!dataReadinessFF}
            activeKey={activeKey}
            items={items}
            onChange={(key) => {
              startTransition(() => {
                searchParams.set("pageNumber", "0")
                navigation(`/sbom/${key}?${searchParams.toString()}`)
              })
            }}
          />
        }
      />
      <div className={styles.sectionLeftPaddingBackground} />
    </>
  )
}
