import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import type { ExportItems, ExportType } from "."

const ExcludeParams = true
const BlackListParams = ["pageNumber", "pageSize"]

export const getExportItems = (
  exportType: ExportType,
  filters: string = ""
): ExportItems => {
  switch (exportType) {
    case "Branch":
      return [
        {
          key: "1",
          label: "Export Code Repositories to CSV",
          url: `${RESOURCES_NAMES.INVENTORIES.BRANCH_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.CODE_REPOSITORIES_TAB.EXPORT,
            type: "csv"
          }
        }
      ]
    case "Application":
      return [
        {
          key: "1",
          label: "Export Applications to CSV",
          url: `${RESOURCES_NAMES.INVENTORIES.APPLICATION_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.APPLICATIONS.EXPORT,
            type: "csv"
          }
        }
      ]
    case "Image":
      return [
        {
          key: "1",
          label: "Export Images to CSV",
          url: `${RESOURCES_NAMES.INVENTORIES.IMAGES_EXPORT}`,
          queryStringParams: BlackListParams,
          excludeParams: ExcludeParams,
          analytic: {
            event: ANALYTIC_EVENTS.INVENTORY.IMAGES.EXPORT,
            type: "csv"
          }
        }
      ]
    case "Sbom":
      return [
        {
          key: "1",
          label: "SBOM CSV Report",
          url: `${RESOURCES_NAMES.SBOM.EXPORT}`,
          params: { format: "csv", filters },
          analytic: {
            event: ANALYTIC_EVENTS.SBOM.EXPORT,
            type: "csv"
          }
        },
        {
          key: "2",
          label: "SBOM JSON Report",
          url: `${RESOURCES_NAMES.SBOM.EXPORT}`,
          params: { format: "json", filters },
          analytic: {
            event: ANALYTIC_EVENTS.SBOM.EXPORT,
            type: "json"
          }
        }
      ]
    default:
      return []
  }
}
