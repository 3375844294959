import { Badge } from "@/atoms"
import { useFilters, useStateFilter } from "@/hooks/useFilters"
import {
  BaseImage,
  DirectDependency,
  EasyFix,
  ExploitIconsGroup,
  Fix,
  IndirectDependency,
  Ingress,
  InternetFacing,
  PublicImage,
  Runtime
} from "@/icons"
import cn from "classnames"
import { useEffect } from "react"
import { DropdownFilter } from "../DropdownFilter"
import { InsightRadio } from "../InsightRadio"

import styles from "./InsightsFilter.module.scss"

const filterMeta = {
  fromBaseImage: { title: "Base Image", Icon: BaseImage },
  isDirect: { title: "Direct Package", Icon: DirectDependency },
  isIndirect: { title: "Indirect Package", Icon: IndirectDependency },
  hasEasyFix: { title: "Easy Fix", Icon: EasyFix },
  easyFix: { title: "Easy Fix", Icon: EasyFix },
  hasFix: { title: "Has Fix", Icon: Fix },
  ingress: { title: "Ingress", Icon: Ingress },
  publicImage: { title: "Public Image", Icon: PublicImage },
  runtime: { title: "Runtime", Icon: Runtime },
  internetFacing: { title: "Internet Facing", Icon: InternetFacing },
  poc: { title: "Exploit POC", Icon: ExploitIconsGroup.POC },
  inTheWild: {
    title: "Exploit in the Wild",
    Icon: ExploitIconsGroup.IN_THE_WILD
  }
} as const

export const InsightsFilter = ({ filters, isLoading }: any) => {
  const items = filters
    .filter(({ key }: { key: keyof typeof filterMeta }) => !!filterMeta[key])
    .map((filter: { key: keyof typeof filterMeta }) => ({
      ...filterMeta[filter.key],
      ...filter
    }))
    .sort((a: any, b: any) => `${a.order}`.localeCompare(`${b.order}`))
  const { searchParams, setSearchParams, setHash } = useFilters()
  const createInitialValues = () =>
    items.reduce(
      (acc: any, { key }: any) => {
        acc[key] = searchParams.get(key)

        return acc
      },
      {} as Record<string, any>
    )
  const { filterState, reset, onFilterStateChange, onClear, onOk } =
    useStateFilter(createInitialValues())

  const menuItems = items.map(({ key, ...props }: any) => ({
    key,
    className: cn(styles.menuItem, filterState.values[key] && styles.withValue),
    label: (
      <InsightRadio
        name={key}
        value={filterState.values[key]}
        onChange={onFilterStateChange}
        {...props}
      />
    )
  }))

  const count = Object.values(createInitialValues()).filter(Boolean).length

  useEffect(() => {
    const result = menuItems.some(({ key }: any) => {
      const checked = filterState.values[key]
      const checkedInUrl = searchParams.get(key)

      return checked !== checkedInUrl
    })

    if (result) {
      reset(createInitialValues())
    }

    onOk()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <DropdownFilter
      disabled={isLoading}
      menuItems={menuItems}
      isPlaceholder={!count}
      okDisabled={!filterState.dirty}
      clearDisabled={!filterState.allowClear}
      onOpenChange={(open: boolean) => {
        if (open) {
          reset(createInitialValues(), false)
        }
      }}
      onClear={onClear}
      onOk={() => {
        setSearchParams((prev) => {
          const next = new URLSearchParams(prev)

          next.set("pageNumber", "0")

          menuItems.forEach(({ key }: any) => {
            const value = filterState.values[key]

            if (value) {
              next.set(key, `${value}`)
            } else {
              next.delete(key)
            }
          })

          return next
        })

        setHash(Date.now())
      }}
    >
      Insights
      {!!count && (
        <Badge
          count={count}
          className={cn(styles.badge, isLoading && styles.disabled)}
        />
      )}
    </DropdownFilter>
  )
}
