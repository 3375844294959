import { usePackagesApplication } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SbomTab } from "@/organisms/Drawers/components/SbomTab"

import { SbomTabApplicationDrawerComponent } from "."

export const SbomTabApplicationDrawer: SbomTabApplicationDrawerComponent = (
  props
) => {
  return (
    <SbomTab
      getDefaultParams={(resourceId: string) => ({ service: [resourceId] })}
      hook={usePackagesApplication}
      {...props}
    />
  )
}
