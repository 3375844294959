import { useEffect, useState } from "react"

import { useGetIntegrationsList } from "@/api/useIntegrations"
import { useIssue } from "@/api/useTriage"
import { Col, Drawer, Row } from "@/atoms"
import useServices from "@/hooks/useServices"
import { ContentDismissedTag, IconDismissedTag } from "@/molecules/DismissedTag"
import {
  decodeParams,
  encodeParams
} from "@/organisms/Drawers/applications/common/common"
import { ActionCard } from "@/organisms/Drawers/components/TriageDrawer/components/ActionsCard"
import { TRIAGE_DRAWER_ID, WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { useLink } from "@/organisms/Drawers/hooks/useLink"
import { useStateDrawer } from "@/organisms/Drawers/hooks/useStateDrawer"
import { getIssuesTriageSelector } from "@/pages/TriagePage/application/useTriage.selector"
import { RuntimeEvidence } from "../IssuesTab/IssueExpandableCell/components/RuntimeEvidence"
import AttackSimulation from "./components/AttackSimulation"
import { FixesCard } from "./components/FixesCard"
import { MetaIssue } from "./components/MetaIssue"
import { ScoreCardExplanation } from "./components/ScoreCardExplanation"
import { SummaryCard } from "./components/SummaryCard"
import { TitleTriageDrawer } from "./components/TitleTriageDrawer"
import { VulnerableCodeCards } from "./components/VulnerableCodeCards"

import styles from "./TriageDrawer.module.scss"

import type { JiraMetadata } from "@/api/useIntegrations.types"
import type { TriageDrawerComponent } from "."

export const TriageDrawer: TriageDrawerComponent = (props) => {
  const drawer = useDrawer(props.id)
  const attr = { decode: decodeParams, encode: encodeParams, drawer }
  const drawerState = useStateDrawer(attr, props)
  const { response, refetch } = useIssue(drawerState.record?.id, undefined, {
    select: getIssuesTriageSelector
  })
  const { open } = props
  const { data } = response || {}
  const issue = data?.[0]
  const [record, setRecord] = useState(issue)

  useEffect(() => {
    if (issue) setRecord(issue)
  }, [issue])

  const isVulnerableCode = record?.issueType === "Vulnerable_Code"
  const context = useGetIntegrationsList()
  const { response: integrationResponse } = context
  const { metadata = {} } = integrationResponse || {}
  const hasIntegration = (metadata as JiraMetadata)?.integrationsStatus?.jira
  const linkToDrawer = useLink(props.id)
  const { auth } = useServices()
  const isDemo = auth.isDemo()
  const width = isDemo ? 1100 : WIDTH_DEFAULT
  const showDismissedPlaceholder = record && record.status === "dismissed"

  return (
    <Drawer
      id={TRIAGE_DRAWER_ID}
      destroyOnClose
      resizable
      className={styles.drawer}
      width={width + props.widthAdditive}
      minWidth={850}
      title={
        <>
          {showDismissedPlaceholder && (
            <div className={styles.dismissedSection}>
              <IconDismissedTag className={styles.iconDismissedSection} />
              <ContentDismissedTag
                email={record.dismissedBy}
                timestamp={record.dismissedAt}
                reason={record.dismissReason}
              />
            </div>
          )}
          <div className={styles.titleWrapper}>
            <TitleTriageDrawer
              loading={!record}
              hasIntegration={hasIntegration}
              record={record}
              params={props.params}
              refetch={refetch}
              linkToDrawer={linkToDrawer}
            />
          </div>
        </>
      }
      open={open}
      onClose={() => {
        if (drawerState.drawerId) drawer.close()
      }}
    >
      {!!record && (
        <>
          <div className={styles.wrapper}>
            <MetaIssue record={record} />
          </div>
          <Row gutter={[16, 16]} className={styles.row}>
            <Row gutter={[16, 16]} className={styles.rowWrapper}>
              <Col span={14}>
                <SummaryCard record={record} />
              </Col>
              <Col span={10}>
                <ScoreCardExplanation record={record} />
              </Col>

              <RuntimeEvidence isCard record={record} />

              {isDemo && (
                <AttackSimulation
                  issueId={record?.id}
                  hasIntegration={hasIntegration}
                />
              )}
              {record?.tickets?.length > 0 && (
                <Col span={24}>
                  <ActionCard.Collapse tickets={record.tickets} />
                </Col>
              )}
              {!isVulnerableCode && (
                <Col span={24}>
                  <FixesCard record={record} />
                </Col>
              )}
              {isVulnerableCode && <VulnerableCodeCards record={record} />}
            </Row>
            {!isVulnerableCode && (
              <Col span={24}>
                <FixesCard type="resources" record={record} />
              </Col>
            )}
          </Row>
        </>
      )}
    </Drawer>
  )
}
