import { Popover, Space, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { EyeOutlined } from "@/icons"
import { BulletedList } from "../BulletedList"
import { Description } from "../Description"

import styles from "./BaseImageInfo.module.scss"

import type { BaseImageInfoProps } from "."

export const BaseImageInfo = ({ digest, items }: BaseImageInfoProps) => {
  const keyPrefix = "sbom.drawer.baseImageSection"
  const { t } = useTranslation("translation", { keyPrefix })
  const classesDescription = { label: styles.labelDescription }

  if (!items?.length) {
    return null
  }

  const [firstName, ...alternativeImagesNames] = items
  const isAlternativeImagesNames = !!alternativeImagesNames?.length

  return (
    <div className={styles.container}>
      <Description label={`${t("title")}:`} />
      <BulletedList
        classes={{
          wrap: styles.wrapBulletedList
        }}
      >
        <Description
          copyable
          copyableText={digest}
          label={`${t("digest")}:`}
          classes={classesDescription}
        >
          {digest?.substring(7, 19)}
        </Description>
        <Description
          copyable
          label={`${t("name")}:`}
          classes={classesDescription}
        >
          {firstName}
        </Description>
        {isAlternativeImagesNames && (
          <div data-li="nostyle">
            <Popover
              rootClassName={styles.popover}
              content={
                <div>
                  <Description
                    label={`${t("alternativeNamesTitlePopover")}:`}
                    classes={classesDescription}
                  />
                  <BulletedList>
                    {(alternativeImagesNames || []).map((value) => (
                      <Text copyable>{value}</Text>
                    ))}
                  </BulletedList>
                </div>
              }
            >
              <Space className={styles.space}>
                <Text>{t("alternativeNames")}</Text>
                <EyeOutlined />
              </Space>
            </Popover>
          </div>
        )}
      </BulletedList>
    </div>
  )
}
