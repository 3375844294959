import { DeploymentUnitOutlined, IconWithFrame } from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useApplicationEntity } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { useLink } from "@/organisms/Drawers/hooks/useLink"

import type { EntityRecord } from "@/organisms/Drawers/applications/common"

export const TitleApplicationDrawer = (
  props: EntityRecord & { drawerId: string }
) => {
  const context = useApplicationEntity(props.record?.id)
  const [{ response, isLoading }] = context || {}
  const { data } = response || {}
  const link = useLink(props.drawerId)

  if (isLoading || !data?.length) return <SkeletonTitle />

  const { displayName, data: appData } = data[0] as any
  const { environment, namespace } = appData || {}

  return (
    <TitleDrawer
      icon={IconWithFrame(DeploymentUnitOutlined)}
      title={displayName}
      subTitle={`Environment: ${environment} | Namespace: ${namespace}`}
      aside={<CopyLinkButton link={link} />}
    />
  )
}
