export const DRAWERS_PARAM_NAME = "d"

export const CODE_REPOSITORY_DRAWER_ID = "b"

export const APPLICATION_DRAWER_ID = "a"

export const IMAGE_DRAWER_ID = "i"

export const TRIAGE_DRAWER_ID = "g"

export const WIDTH_DEFAULT = 1050
