import { usePackagesImage } from "@/organisms/Drawers/applications/inventory/useInventory"
import { SbomTab } from "@/organisms/Drawers/components/SbomTab"

import { SbomTabImageDrawerComponent } from "."

export const SbomTabImageDrawer: SbomTabImageDrawerComponent = (props) => {
  return (
    <SbomTab
      getDefaultParams={(imageId: string) => ({ imageId: [imageId] })}
      hook={usePackagesImage}
      {...props}
    />
  )
}
