import type { Item, KeyRow, PayloadSetFlags } from "./useSelection.types"

export const SELECT_ROW = "SELECT_ROW" as const

export const onSelectRow = (
  parentRowId: KeyRow,
  nestedRowId: KeyRow,
  selected: boolean
) => ({
  parentRowId,
  nestedRowId,
  selected,
  type: SELECT_ROW
})

export const SELECT_ROWS_NESTED_TABLE = "SELECT_ROWS_NESTED_TABLE" as const

export const onSelectRowsInNestedTable = (
  parentRowId: KeyRow,
  nestedRowIds: KeyRow[]
) => ({
  parentRowId,
  nestedRowIds,
  type: SELECT_ROWS_NESTED_TABLE
})

export const SELECT_ALL_ROWS_NESTED_TABLE =
  "SELECT_ALL_ROWS_NESTED_TABLE" as const

export const onSelectAllRowsInNestedTable = (
  parentRowId: KeyRow,
  selected: boolean
) => ({
  selected,
  parentRowId,
  type: SELECT_ALL_ROWS_NESTED_TABLE
})

export const DISABLED_PARENT_ROW_SELECTION =
  "DISABLED_PARENT_ROW_SELECTION" as const

export const disabledParentRowForSelection = (parentRowId: KeyRow) => ({
  parentRowId,
  type: DISABLED_PARENT_ROW_SELECTION
})

export const SET_IDS_NESTED_TABLE = "SET_IDS_NESTED_TABLE" as const

export const setIdsNestedTable = <I extends Item>(
  items: I[],
  parentRowId: KeyRow
) => ({
  items,
  parentRowId,
  type: SET_IDS_NESTED_TABLE
})

export const EXPANDED_PARENT_ROW = "EXPANDED_PARENT_ROW" as const

export const expandedParentRow = (parentRowId: KeyRow, expanded?: boolean) => ({
  parentRowId,
  expanded,
  type: EXPANDED_PARENT_ROW
})

export const SET_UPDATING_STATE_FOR_PARENT_ROWS =
  "SET_UPDATING_STATE_FOR_PARENT_ROWS" as const

export const setUpdatingStateForParentRow = (
  parentRowIds: KeyRow[],
  withErrorRowIds?: KeyRow[]
) => ({
  type: SET_UPDATING_STATE_FOR_PARENT_ROWS,
  parentRowIds,
  withErrorRowIds
})

export const SET_FLAGS_STATE = "SET_FLAGS_STATE" as const

export const setFlags = (payload: PayloadSetFlags = {}) => ({
  type: SET_FLAGS_STATE,
  payload
})

export const UNSELECT_ALL = "UNSELECT_ALL" as const

export const unselectAll = () => ({
  type: UNSELECT_ALL
})
