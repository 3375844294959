import { useNavigate } from "react-router-dom"

import { Button, Empty, Link, message } from "@/atoms"
import { NAMES_ROUTES, VALUES_PARAMS } from "@/const/routes.constants"
import { isUserAdmin } from "@/domain/user"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useQueryString } from "@/hooks/useQueryString"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { ViewsOutlined } from "@/icons"
import { Heading } from "@/molecules/Heading"
import {
  useDeleteScope,
  useScopes
} from "@/pages/SettingsPage/application/scopes/useScopes"
import { TabTemplate } from "@/templates/TabTemplate"
import { VerticalTabTemplate } from "../../templates/VerticalTabTemplate"
import { Filter } from "../Filter"
import { getColumnsTable } from "./getColumnsTable"

import styles from "./ScopesTab.module.scss"

export const ScopesTab = () => {
  const { t } = useTranslation()
  const { t: tScopes } = useTranslation("translation", {
    keyPrefix: "settings.scopes"
  })
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const params = useQueryString(["orderBy", "ascending"])
  const { response, isLoading } = useScopes(params)
  const { deleteScope, isLoading: deleteScopeIsLoading } = useDeleteScope(
    params,
    messageApi
  )
  const { data, metadata } = response || {}
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { user } = useUser()
  const isAdmin = isUserAdmin(user)
  const { SETTINGS } = NAMES_ROUTES
  const { ROOT, SCOPES } = SETTINGS
  const rootUrl = `/${ROOT}/${SCOPES}`
  const linkToForm = `${rootUrl}/${VALUES_PARAMS.NEW_ENTITY}`

  return (
    <VerticalTabTemplate
      title={
        <Heading
          level={4}
          title={tScopes("title")}
          popover={{
            className: styles.popover,
            content: (
              <div className={styles.popoverContent}>
                <span>{tScopes("descriptions.message1")}</span>
              </div>
            )
          }}
        />
      }
      button={
        <Link to={linkToForm}>
          <Button
            className={styles.button}
            icon={<ViewsOutlined />}
            type="primary"
            disabled={!isAdmin}
          >
            {tScopes("createScope")}
          </Button>
        </Link>
      }
    >
      <TabTemplate
        tabPosition="left"
        loading={isLoading || deleteScopeIsLoading}
        columns={getColumnsTable(orderByProps, {
          t,
          deleteScope: deleteScope,
          duplicateScope: (duplicateScopeId: string) => {
            navigate(`${linkToForm}/${duplicateScopeId}`)
          },
          user
        })}
        dataSource={data}
        metadata={metadata}
        locale={{
          emptyText: (
            <>
              {isAdmin && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <div className={styles.empty}>
                      <span>{tScopes("noScopes")}</span>
                      <Link to={linkToForm}>
                        <Button type="link">
                          {tScopes("createFirstNewScope")}
                        </Button>
                      </Link>
                    </div>
                  }
                />
              )}
              {!isAdmin && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <div className={styles.empty}>
                      <span>{tScopes("noScopesUser")}</span>
                      <span>{tScopes("noScopesUserMsg")}</span>
                    </div>
                  }
                />
              )}
            </>
          )
        }}
        SearchComponent={<Filter />}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${rootUrl}/${record.id}`)
          }
        })}
        onChange={(_pagination, _filters, sorter: any) => {
          onSorterChange(sorter)
        }}
      />
      {contextHolder}
    </VerticalTabTemplate>
  )
}
