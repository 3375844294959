import { Image, SourceControlIcon } from "@/icons"
import { IconTextCell } from "@/molecules/IconTextCell"

import type { ResourceComponent } from "."

export const Resource: ResourceComponent = ({ type, name, sourceControl }) => {
  if (type === "ImageRepository") {
    return <IconTextCell noTooltip icon={<Image />} text={name} />
  }

  return (
    <IconTextCell
      noTooltip
      icon={<SourceControlIcon sourceControl={sourceControl} />}
      text={name}
    />
  )
}
