import { useFeatureFlags } from "@/hooks/useUser"
import { WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { CodeIssueTab } from "../CodeIssueTab"
import { TabsDrawerTemplate } from "../TabsDrawerTemplate"
import { IssuesTabCodeRepoDrawer } from "./components/IssuesTabCodeRepoDrawer"
import { OverviewTabCodeRepositoryDrawer } from "./components/OverviewTabCodeRepositoryDrawer"
import { SbomTabCodeRepositoryDrawer } from "./components/SbomTabCodeRepositoryDrawer"
import { TitleCodeRepositoryDrawer } from "./components/TitleCodeRepositoryDrawer"

import type { CodeRepositoryDrawerComponent } from "./CodeRepositoryDrawer.types"

export const CodeRepositoryDrawer: CodeRepositoryDrawerComponent = ({
  widthAdditive,
  ...props
}) => {
  const tabsDefault = ["overview", "sbom", "scaIssues"]
  const tabsWithSast = ["overview", "sbom", "scaIssues", "sastIssues"]
  const components = [
    OverviewTabCodeRepositoryDrawer,
    SbomTabCodeRepositoryDrawer,
    IssuesTabCodeRepoDrawer,
    CodeIssueTab
  ]
  const showCodeIssueTab = useFeatureFlags("sastScanFeature")
  const tabs = showCodeIssueTab ? tabsWithSast : tabsDefault

  return (
    <TabsDrawerTemplate
      name="CodeRepositoryDrawer"
      tabs={tabs}
      components={components}
      Title={TitleCodeRepositoryDrawer}
      width={WIDTH_DEFAULT + widthAdditive}
      {...props}
    />
  )
}
