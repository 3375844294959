import { Component } from "react"
import ReactDOM from "react-dom"

import { Table } from "@/atoms"

import styles from "./ResizableTable.module.scss"

import type { TableProps } from "@/atoms"

export class ResizableTable<R extends { key: string }> extends Component<
  TableProps<R>
> {
  column: HTMLElement | null
  draggable: HTMLElement | null
  table: HTMLTableElement | null
  offset: number
  tableWidth: number
  tableWidthMin: number
  previousColumnWidth: number
  columnIndex: number | null
  columnWidth: number
  columnsState: "px" | "auto"
  draggingAttributeName: string
  initialWidthAttributeName: string
  clearColumnTimeout: NodeJS.Timeout | null

  constructor(props: TableProps<R>) {
    super(props)

    this.table = null
    this.column = null
    this.draggable = null
    this.offset = 0
    this.tableWidth = 0
    this.tableWidthMin = 0
    this.columnIndex = 0
    this.columnWidth = 0
    this.columnsState = "px"
    this.previousColumnWidth = 0
    this.draggingAttributeName = "data-dragging-resizable"
    this.initialWidthAttributeName = "data-initial-width-resizable"
    this.clearColumnTimeout = null
  }

  componentDidMount(): void {
    const el = ReactDOM.findDOMNode(this) as Element

    this.table = el?.getElementsByTagName("table")[0]
    this.tableWidthMin = this.table?.clientWidth || 0
    this.resizable()
    this.clearColumnsWidth()
  }

  componentDidUpdate(prevProps: Readonly<TableProps<R>>): void {
    if (prevProps.dataSource !== this.props.dataSource && this.table) {
      this.table.style.setProperty(
        "--height-resizable-table",
        `${this.table.clientHeight}px`
      )
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener("mouseup", this.handleMouseup)
    document.removeEventListener("mousemove", this.handleMousemove, true)

    if (!this.table) return

    this.table.removeEventListener("mousedown", this.handleMousedown)
    this.table.removeEventListener("dblclick", this.doubleClick)
  }

  getColumnWidthBySettings = (index: number) => {
    const columnWidthBySettings = this.props.columns?.[index].width || "auto"

    return typeof columnWidthBySettings === "number"
      ? `${columnWidthBySettings}px`
      : columnWidthBySettings
  }

  getThElements = () => this.table?.querySelectorAll("th") || []

  switchWidthInTh = (auto?: boolean) => {
    if (this.columnsState === (auto ? "auto" : "px")) return

    this.getThElements().forEach((th, index) => {
      if (this.columnIndex !== index)
        th.style.width =
          auto && th.clientWidth > 100 ? "auto" : `${th.clientWidth}px`
    })

    this.columnsState = auto ? "auto" : "px"
  }

  resizable() {
    if (!this.table) return

    const elements = this.getThElements()
    const indexLatsElements = elements.length - 1

    elements.forEach((th, index) => {
      if (th.innerHTML === th.innerText) {
        th.innerHTML = `<div class="ant-table-column-title">${th.innerText}</div>`
      }

      const isLastElement = index === indexLatsElements

      if (isLastElement) {
        th.style.width = this.getColumnWidthBySettings(index)
        return
      }

      const div = document.createElement("div")
      const attributeValue = `${index}`

      div.classList.add(styles.resizerTh)
      div.setAttribute(this.draggingAttributeName, attributeValue)
      th.setAttribute(this.draggingAttributeName, attributeValue)
      th.appendChild(div)
      th.style.width = this.getColumnWidthBySettings(index)
    })

    this.table.addEventListener("mousedown", this.handleMousedown)
    this.table.addEventListener("dblclick", this.doubleClick)
    document.addEventListener("mouseup", this.handleMouseup)
    document.addEventListener("mousemove", this.handleMousemove, true)
  }

  applyStyles(el: HTMLElement | null, styles: Record<string, string>) {
    if (!el) return

    Object.keys(styles).forEach((key: any) => {
      el.style[key] = styles[key]
    })
  }

  applyTableStyles(isDown?: boolean) {
    const userSelect = isDown ? "none" : "auto"
    const cursor = isDown ? "col-resize" : "default"
    const value = isDown ? "visible" : "initial"
    const priority = isDown ? "important" : undefined

    this.applyStyles(this.table, { userSelect, cursor })
    this.getThElements().forEach((th) =>
      th.style.setProperty("overflow", value, priority)
    )
  }

  getIndex = (target: HTMLElement) =>
    parseInt(target.getAttribute(this.draggingAttributeName) || "")

  getColumnByIndex = (index: unknown) =>
    this.table?.querySelector(
      `th[${this.draggingAttributeName}="${index}"]`
    ) as HTMLElement | null

  doubleClick = (event: Event) => {
    const target = event.target as HTMLElement

    if (target === null) return

    const column = this.getColumnByIndex(this.getIndex(target))

    if (!column || !this.table) return

    const initialWidth = column.getAttribute(this.initialWidthAttributeName)
    const currentWidth = column.clientWidth
    const diff = currentWidth - parseInt(initialWidth || "0")

    column.style.width = `${initialWidth}px`
    this.table.style.width = `${this.tableWidth - diff}px`
  }

  handleMousedown = (event: any) => {
    const target = event.target as HTMLElement

    if (target === null) return

    this.columnIndex = this.getIndex(target)

    this.column = this.getColumnByIndex(this.getIndex(target))

    if (!this.column || !this.table) return
    if (this.clearColumnTimeout) clearTimeout(this.clearColumnTimeout)

    const initialWidth = this.column.getAttribute(
      this.initialWidthAttributeName
    )

    if (!initialWidth) {
      this.column.setAttribute(
        this.initialWidthAttributeName,
        this.column.clientWidth.toString()
      )
    }

    this.column.addEventListener("click", this.preventDefault)
    this.offset = event.clientX
    this.columnWidth = this.column.clientWidth || 0
    this.tableWidth = this.table.rows[0].clientWidth || this.tableWidth
    this.column.style.transition = "none"
    this.draggable = this.table.querySelector(
      `div[${this.draggingAttributeName}="${this.columnIndex}"]`
    )
    this.draggable?.classList.add(styles.divDraggable)
    this.applyTableStyles(true)
    this.column.style.setProperty("z-index", "2", "important")
  }

  handleMousemove = (event: any) =>
    requestAnimationFrame(() => {
      if (!this.column || !this.table) return

      const diff = event.clientX - this.offset
      const nextColumnWidth = this.columnWidth + diff
      const nextWidthTable = this.tableWidth + diff

      if (nextWidthTable < this.tableWidthMin) {
        this.switchWidthInTh(
          this.previousColumnWidth > nextColumnWidth ? true : false
        )
      } else this.switchWidthInTh()

      if (nextColumnWidth < 80) return

      this.previousColumnWidth = nextColumnWidth
      this.column.style.width = `${nextColumnWidth}px`
      this.table.style.width = `${nextWidthTable}px`
    })

  handleMouseup = () => {
    if (!this.column || !this.table) return

    this.draggable?.classList.remove(styles.divDraggable)
    this.applyStyles(this.column, { zIndex: "initial", transition: "initial" })
    this.applyTableStyles()

    this.clearColumnTimeout = setTimeout(() => {
      this.column?.removeEventListener("click", this.preventDefault)
      this.column = null
      this.draggable = null
      this.columnIndex = null
    }, 100)
  }

  preventDefault(event: Event) {
    event.preventDefault()
    event.stopPropagation()
  }

  clearColumnsWidth() {
    if (!this.table) return

    let colgroup: any = null
    const childNodes = this.table.childNodes

    childNodes.forEach((node: any) => {
      if (node.tagName === "COLGROUP") {
        colgroup = node
      }
    })

    if (colgroup) {
      colgroup.childNodes.forEach((node: any) => {
        this.applyStyles(node, { width: "auto" })
      })
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.cover} />
        <Table {...this.props} />
      </div>
    )
  }
}
