import React from "react"

import { IssuesTab } from "@/organisms/Drawers/components/IssuesTab"
import {
  getDefaultFiltersInventorySastIssuesDrawer,
  getDefaultParamsSastIssue
} from "@/organisms/Drawers/hooks/useParamsFilter"

import type { TabComponentProps } from "@/organisms/Drawers/components/TabsDrawerTemplate"

export const CodeIssueTab: React.FC<TabComponentProps> = (
  props: TabComponentProps
) => (
  <IssuesTab
    tabKey="sastIssues"
    getInitialParamsFilter={getDefaultFiltersInventorySastIssuesDrawer}
    defaultParams={getDefaultParamsSastIssue()}
    includeApplicationFilter
    {...props}
  />
)

export default CodeIssueTab
