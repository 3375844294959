import { useEffect, useState } from "react"

import { useGetScopes } from "@/api/useScopes"
import { X_SCOPE_NAME } from "@/const/local-storage.constants"
import { isDataAuthorizationReleased } from "@/domain/user"
import { useQueryClient } from "@/hooks/useHttp"
import { useServices } from "@/hooks/useServices"
import { useFeatureFlags, useUser } from "@/hooks/useUser"

import type { Scope } from "@/api/useScopes.types"
import type { Item, ScopeMenuState } from "./ScopeMenu.types"

export const useScope = () => {
  const queryClient = useQueryClient()
  const { http, localStorage } = useServices()
  const { user, setCurrentScope } = useUser()
  const { currentScopeId } = user
  const [isFetchedAfterMount, setIsFetchedAfterMount] = useState(false)
  const [scope, setScope] = useState<ScopeMenuState>({
    selectedId: currentScopeId,
    currentScopeName: localStorage.getItem(X_SCOPE_NAME) || ""
  })
  const [search, setSearch] = useState("")
  const { currentScopeName, selectedId } = scope
  const params = !!search ? { query: search } : {}
  const { response, isLoading } = useGetScopes(params)
  const { data } = response || {}
  const scopesMenuKey = "scopes"
  const releaseDataAuthorizationFF = useFeatureFlags("releaseDataAuthorization")
  const isWelcomeDismissedFF = useFeatureFlags("isWelcomeDismissed")

  useEffect(() => {
    if (currentScopeName) localStorage.setItem(X_SCOPE_NAME, currentScopeName)
    else localStorage.removeItem(X_SCOPE_NAME)
  }, [currentScopeName, localStorage])

  useEffect(() => {
    const name =
      data?.find(({ id }: any) => id === currentScopeId)?.name ||
      currentScopeName

    if (name !== currentScopeName) {
      return setScope((prevState) => ({
        ...prevState,
        currentScopeName: name
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (isFetchedAfterMount || !isWelcomeDismissedFF) return

    if (!isLoading) {
      setIsFetchedAfterMount(true)
    }
  }, [isFetchedAfterMount, isLoading, isWelcomeDismissedFF])

  useEffect(() => {
    if (!isDataAuthorizationReleased(user) || !isFetchedAfterMount) return

    const dataValue = data || []

    if (!!currentScopeId) {
      // check if the current scope is still permitted
      const selectedScope = dataValue.find(
        ({ id }: Scope) => id === currentScopeId
      )
      if (selectedScope) return

      setScope({})
      setCurrentScope(null)
      setOpenKeys([scopesMenuKey])
      return
    }

    const defaultScope =
      dataValue.length === 1
        ? dataValue[0]
        : dataValue.find(({ isAllResources }: Scope) => isAllResources)

    if (defaultScope) {
      setSelectedScope(defaultScope)
      return changeScopeActions(defaultScope.id)
    }

    setScope({})
    setCurrentScope(null)
    setOpenKeys([scopesMenuKey])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchedAfterMount])

  const updateScopeState = (nextState: ScopeMenuState) => {
    setScope((prevState) => ({
      ...prevState,
      ...nextState
    }))
  }

  const resetToCurrentScope = () => {
    updateScopeState({ selectedId: currentScopeId })
  }

  const setSelectedScope = (values: Item) => {
    updateScopeState({
      selectedId: values.id,
      selectedName: values.name
    })
  }

  const changeScopeActions = (selectedId: string | null) => {
    setCurrentScope(selectedId)
    http.setCurrentScope(selectedId)
    queryClient.cancelQueries()
    queryClient.clear()
  }

  const cancelScope = () => {
    changeScopeActions(null)
    setScope({})
  }

  const applyScope = () => {
    changeScopeActions(selectedId || null)
    setScope((prevState) => ({
      ...prevState,
      currentScopeName: prevState.selectedName
    }))
  }

  const [openKeys, setOpenKeys] = useState<string[]>([])

  useEffect(() => {
    if (!currentScopeId && selectedId) {
      cancelScope()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScopeId])

  const actions = {
    resetToCurrentScope,
    setSelectedScope,
    applyScope,
    cancelScope,
    setOpenKeys,
    setSearch: (value?: string) => {
      setSearch(value || "")
      setScope({}) // reset scope
    }
  }

  return {
    data,
    search,
    selectedId,
    currentScopeId,
    currentScopeName,
    openKeys,
    actions,
    isLoading,
    isFetchedAfterMount,
    releaseDataAuthorizationFF,
    scopesMenuKey
  }
}
