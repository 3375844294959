import { TabDrawerTemplate } from "@/organisms/Drawers/components/TabDrawerTemplate"
import { useCounts } from "@/organisms/Drawers/hooks/useCounts"
import {
  getDefaultFiltersImageDrawer,
  useParamsFilter
} from "@/organisms/Drawers/hooks/useParamsFilter"
import { SbomFilterImageDrawer } from "../SbomFilterImageDrawer"
import { ExpandedRow } from "./ExpandedRow"
import { getColumnsExpandedRow } from "./getColumnsExpandedRow"
import { getColumnsTable } from "./getColumnsTable"
import { getFiltersValues } from "./getFiltersValues"

export const SbomTab = (props: any) => {
  const { hook } = props
  const tabKey = "sbom"
  const filtersContext = useParamsFilter({
    filterKey: tabKey,
    defaultParams: { scope: "all" },
    initialParams: getDefaultFiltersImageDrawer("severity"),
    initialFilters: ["severity", "riskId", "runtime"],
    ...props
  })
  const { record, getDefaultParams } = props
  const [dataContext, countsContext] = hook(
    record?.id,
    (value: string) =>
      filtersContext.utils.getParamsWithFilters(
        { ...getDefaultParams(value), ...filtersContext.params },
        getFiltersValues
      ),
    (value: string) =>
      filtersContext.utils.getParamsWithFilters({
        scope: "all",
        ...getDefaultParams(value)
      })
  )

  useCounts(tabKey, countsContext, props)

  return (
    <TabDrawerTemplate<typeof dataContext>
      dataContext={dataContext}
      filtersContext={filtersContext}
      getColumnsTable={getColumnsTable}
      getColumnsExpandedRow={getColumnsExpandedRow}
      expandedRowRender={ExpandedRow}
      FilterComponent={<SbomFilterImageDrawer {...filtersContext} />}
    />
  )
}
