import type { DataTask, PropsTask, TaskResponse } from "./tasks.types"

const refetch = (url: string, taskId: string, props: PropsTask) => {
  const { timeoutIdByURL, http, onError, onSuccess } = props

  timeoutIdByURL.current[url] = setTimeout(async () => {
    try {
      const {
        data: { payload, status }
      } = await http.get<TaskResponse>(`${url}/${taskId}`, {})

      if (!timeoutIdByURL.current[url]) {
        return
      }

      if ((status === "PENDING" || status === "STARTED") && !payload) {
        return refetch(url, taskId, props)
      }

      if (status === "SUCCESS" && payload) {
        return onSuccess(payload.downloadUrl, url)
      }

      onError(url)
    } catch {
      onError(url)
    }
  }, 2000)
}

export const createTask = async (
  { url, params }: DataTask,
  props: PropsTask
) => {
  try {
    const { http } = props
    const {
      data: { taskId }
    } = await http.get<{ data: { taskId: string } }>(url, {
      params
    })

    refetch(url, taskId, props)
  } catch {
    props?.onError(url)
  }
}
