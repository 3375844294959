import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { TFunction } from "@/hooks/useTranslation"
import { ExploitabilityCell } from "@/molecules/ExploitabilityCell"
import { LanguageIconCell } from "@/molecules/LanguageIconCell"
import { MoreTableCell } from "@/molecules/MoreTableCell"
import { VulnerabilitiesCellWithoutTooltip } from "@/molecules/VulnerabilitiesCellWithoutTooltip"
import { InsightsSbomCell } from "@/pages/SbomPage/components/molecules/InsightsSbomCell"

import type { SortOrder } from "@/atoms"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"

export const getColumnsTable = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">,
  { t, internetFacingFF }: { t: TFunction; internetFacingFF: boolean }
) => [
  {
    title: "Package",
    sorter: true,
    showSorterTooltip: false,
    className: "package-column-sbom-table",
    render: LanguageIconCell,
    ...getDefaultColumnParams("name", sorter)
  },
  {
    title: "Version",
    width: 120,
    render: CellTable,
    ...getDefaultColumnParams("version", sorter)
  },
  {
    title: "Vulnerabilities",
    width: 162,
    render: VulnerabilitiesCellWithoutTooltip,
    ...getDefaultColumnParams("vulnerabilities")
  },
  {
    title: "Exploitability",
    width: 118,
    render: ExploitabilityCell(t),
    ...getDefaultColumnParams("exploitMaturity", sorter)
  },
  {
    title: "Impact",
    width: 165,
    render: CellTable,
    ...getDefaultColumnParams("service", sorter)
  },
  {
    title: "Namespace",
    width: 110,
    render: CellTable,
    ...getDefaultColumnParams("namespace", sorter)
  },
  {
    title: "Insights",
    width: 90,
    render: InsightsSbomCell({ internetFacingFF }),
    ...getDefaultColumnParams("insights")
  },
  {
    title: "Licenses",
    width: 120,
    render: MoreTableCell,
    ...getDefaultColumnParams("license")
  },
  {
    title: "Environment",
    width: 120,
    ...getDefaultColumnParams("environment", sorter)
  },
  {
    title: "Last Seen",
    width: 130,
    sorter: true,
    showSorterTooltip: false,
    sortDirections: ["descend", "ascend"] as SortOrder[],
    ...getDefaultColumnParams("lastSeen", sorter)
  }
]
