import cn from "classnames"

import { BetaLabel, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { TabsTemplate } from "@/templates/TabsTemplate"

import styles from "./HeaderPage.module.scss"

import type { HeaderPageProps } from "."

export const HeaderPage = (props: HeaderPageProps) => {
  const {
    title,
    titleTranslationKey,
    sider,
    tabs,
    beta,
    className = {},
    testId = "HeaderPage"
  } = props
  const { t } = useTranslation()
  const Wrap = tabs ? TabsTemplate : "div"

  return (
    <Wrap
      className={cn(
        styles.container,
        {
          [styles.inline]: !tabs,
          [styles.tabs]: tabs
        },
        className.container
      )}
      data-testid={testId}
    >
      {titleTranslationKey && (
        <Title
          className={styles.titleTranslationKey}
          level={4}
          data-testid={`${testId}-title`}
        >
          {t(titleTranslationKey)}
          {beta && <BetaLabel />}
        </Title>
      )}
      {title}
      {sider}
      {tabs}
    </Wrap>
  )
}
