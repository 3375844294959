import { useMemo, useRef, useState } from "react"

import {
  useGetInventoryEnvironment,
  useGetInventorySnapshot
} from "@/api/useInventory"
import { useGetPosture } from "@/api/usePosture"
import { useParallelQueries } from "@/hooks/useHttp"
import { useFunnelTriage } from "@/pages/TriagePage/components/organisms/FunnelTriagePage"
import {
  environmentInventoryPropsSelector,
  getAllEnvironmentOption,
  snapshotInventoryPropsSelector
} from "../selectors/inventory.selector"
import { posturePropsSelector } from "../selectors/posture.selector"
import { getQueriesSBOM } from "./helpers"

import type { DashboardProps, TotalResponse } from "./useDashboard.types"

const useDashboard = (): DashboardProps => {
  const allEnvironmentOption = useRef(getAllEnvironmentOption()).current
  const [
    currentValueInventoryEnvironment,
    setCurrentValueInventoryEnvironment
  ] = useState(allEnvironmentOption)

  const allEnvironmentOptionSelected =
    currentValueInventoryEnvironment.value === allEnvironmentOption.value
  const queryStringRequest = allEnvironmentOptionSelected
    ? {}
    : {
        paramsById: {
          environmentId: currentValueInventoryEnvironment.value
        },
        paramsByName: {
          environment: currentValueInventoryEnvironment.name,
          pageSize: 1,
          pageNumber: 0
        }
      }

  const inventoryEnvironmentResponse = useGetInventoryEnvironment(undefined, {
    select: environmentInventoryPropsSelector
  })
  const postureResponse = useGetPosture(queryStringRequest.paramsById, {
    select: posturePropsSelector
  })
  const inventorySnapshotResponse = useGetInventorySnapshot(
    queryStringRequest.paramsById,
    { select: snapshotInventoryPropsSelector }
  )

  const queries = useMemo(
    () => getQueriesSBOM(queryStringRequest.paramsByName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentValueInventoryEnvironment]
  )
  const [context] = useParallelQueries<TotalResponse>(queries)
  const { name: environment } = currentValueInventoryEnvironment
  const funnelContext = useFunnelTriage({
    overrideParams: environment ? { environment } : {}
  })
  const inventorySnapshotIsLoadingCompound =
    inventorySnapshotResponse.isLoading || context[0].isLoading
  const unavailable =
    !inventorySnapshotIsLoadingCompound &&
    !inventorySnapshotResponse.response?.containerImage.total
  const { response, isLoading, ...restInventorySnapshotResponse } =
    inventorySnapshotResponse
  const totalPackages = context[0].data?.data?.totalResults || 0
  const inUseTotalPackages = context[1].data?.data?.totalResults || 0

  return {
    postureProps: {
      currentValue: currentValueInventoryEnvironment,
      ...postureResponse,
      isLoading: postureResponse.isLoading || inventorySnapshotIsLoadingCompound
    },
    inventorySnapshotProps: {
      response: response
        ? { ...response, packages: { new: 0, total: totalPackages } }
        : response,
      isLoading: inventorySnapshotIsLoadingCompound,
      ...restInventorySnapshotResponse
    },
    inventoryEnvironmentProps: {
      setCurrentValueInventoryEnvironment,
      currentValue: currentValueInventoryEnvironment,
      ...inventoryEnvironmentResponse,
      isLoading:
        inventoryEnvironmentResponse.isLoading ||
        inventorySnapshotIsLoadingCompound
    },
    packagesInUseProps: {
      unavailable,
      response: {
        inUseTotal: inUseTotalPackages,
        notInUseTotal: totalPackages - inUseTotalPackages
      },
      isLoading:
        inventorySnapshotIsLoadingCompound ||
        context[0].isLoading ||
        context[1].isLoading
    },
    funnelProps: { context: funnelContext }
  }
}

export default useDashboard
