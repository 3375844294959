import cn from "classnames"
import { useEffect, useRef } from "react"

import { Col, Row, Table } from "@/atoms"
import { TABS_PAGE_CONTAINER_ID } from "@/const/tab.constants"
import { DATA_OPENED_IN_DRAWER } from "@/const/table-constants"
import { useTeasers } from "@/hooks/useTeasers"
import { EmptyTable } from "@/molecules/EmptyTable"
import { FiltersTab } from "@/organisms/FiltersTab"
import { PaginationTab } from "@/organisms/PaginationTab"
import { ResizableTable } from "@/organisms/ResizableTable"
import { DataReadiness } from "./components/DataReadiness/DataReadiness"
import { useDrawerTabTemplate } from "./useDrawerTabTemplate"

import styles from "./TabTemplate.module.scss"

import type { RecordProp, TabTemplateProps } from "."

export const TabTemplate = function <R extends RecordProp>(
  props: TabTemplateProps<R>
) {
  const {
    dataSource,
    columns,
    loading,
    onChange,
    onPaginationChange,
    expandable,
    DrawerComponent,
    rowSelection,
    totalRender,
    asideFooter,
    components,
    resizable,
    idsInDrawers,
    drawerProps = {},
    classes = {},
    highlightRow,
    headerComponent,
    rowKey,
    showHeader,
    ...attr
  } = props || {}
  const ref = useRef<HTMLDivElement>(null)
  const { open: openDrawerProps, ...drawerPropsRest } = drawerProps
  const teasers = useTeasers(props.locale)
  const [drawerState, setDrawerState] = useDrawerTabTemplate()
  const { locale = {}, SearchComponent } = attr
  const showDataReadinessPlaceholder = teasers[0]
  const dataSourceLength = dataSource?.length
  const className = cn(styles.wrap, {
    [styles.loading]:
      !showDataReadinessPlaceholder &&
      dataSourceLength === undefined &&
      loading,
    [styles.empty]: dataSourceLength === 0 && !loading,
    [styles.collectingData]: showDataReadinessPlaceholder,
    [styles.withoutSearchComponent]: !SearchComponent,
    [styles.withExpandableComponent]: !!expandable,
    [styles.noData]: dataSourceLength === 0 && !loading
  })
  const useOnRow =
    !!DrawerComponent || props.onRow || props.onRowClick ? true : undefined
  const { open, index: indexSelectedRecord } = drawerState
  const selectedRecord =
    (indexSelectedRecord !== null && dataSource?.[indexSelectedRecord]) || null

  const onRow = (record: R, index?: number) => {
    if (props.disableDrawer) return {}

    if (props.onRow) {
      return props.onRow(record)
    }

    const keySelectedRecord = selectedRecord && selectedRecord?.key

    return {
      [DATA_OPENED_IN_DRAWER]:
        !!idsInDrawers?.[record.key] ||
        (highlightRow && open && record.key === keySelectedRecord),
      onClick: (e: React.MouseEvent) => {
        const el = e.nativeEvent.target as HTMLElement
        const classes = ".ant-popover,.ant-tooltip" // classes of Popover and Tooltip components
        const clickOnPopoverOrTooltipComponent = el?.closest(classes)

        if (clickOnPopoverOrTooltipComponent) return

        if (index !== undefined) setDrawerState({ index, open: true })

        props.onRowClick?.(record)
      }
    }
  }

  const wheelEventListener = useRef((e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }).current

  useEffect(() => {
    const el = document.getElementById(TABS_PAGE_CONTAINER_ID)

    if (!el) return

    if (loading) {
      el.addEventListener("wheel", wheelEventListener, {
        passive: false
      })
    }

    return () => {
      el.removeEventListener("wheel", wheelEventListener)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const TableComponent = resizable ? ResizableTable : (Table as React.FC)

  return (
    <Row ref={ref} className={className} data-id-tab-template="row">
      {headerComponent && (
        <Col span={24} style={{ paddingTop: "16px" }}>
          {headerComponent}
        </Col>
      )}
      {SearchComponent && (
        <Col
          span={24}
          className={cn(
            styles.filters,
            !showDataReadinessPlaceholder && "filters-tab-template-component-ks"
          )}
        >
          <FiltersTab
            showPlaceholder={showDataReadinessPlaceholder}
            scrollY={resizable}
            {...attr}
          />
        </Col>
      )}
      <Col span={24}>
        {showDataReadinessPlaceholder && (
          <DataReadiness
            type={teasers[1].type}
            columns={columns}
            locale={{ dataReadiness: teasers[1] }}
          />
        )}
        {!showDataReadinessPlaceholder && (
          <TableComponent
            key="TabTemplate"
            rowKey={rowKey}
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onChange={onChange}
            onRow={useOnRow && onRow}
            expandable={{ columnWidth: "20px", ...expandable }}
            rowClassName={cn(useOnRow && styles.row)}
            rowSelection={rowSelection}
            locale={{ emptyText: <EmptyTable containerRef={ref} />, ...locale }}
            components={components}
            showHeader={showHeader}
          />
        )}
        {DrawerComponent && (
          <DrawerComponent
            destroyOnClose
            open={openDrawerProps || drawerState.open}
            record={selectedRecord}
            onClose={() => {
              setDrawerState({ index: indexSelectedRecord, open: false })
            }}
            {...drawerPropsRest}
          />
        )}
      </Col>
      <Col className={cn(styles.footer, classes.footer)}>
        {!!asideFooter && <div className={styles.aside}>{asideFooter}</div>}
        {!showDataReadinessPlaceholder && (
          <div className={styles.pagination}>
            <PaginationTab
              loading={loading}
              pageSize={props.pageSize}
              metadata={props.metadata}
              onPaginationChange={onPaginationChange}
              totalRender={totalRender}
            />
          </div>
        )}
      </Col>
    </Row>
  )
}
