import { Fixability } from "@/api/useIssues.types"
import { Text } from "@/atoms"
import type {
  ApplicativeImageFinding,
  CodeRepoFinding,
  ImagePackageFix
} from "@/domain/issue"
import type { TFunction } from "@/hooks/useTranslation"
import { InformationPopover } from "@/molecules/InformationPopover"
import { SourceList } from "@/organisms/Drawers/components/SourceList"
import { findingsToSources } from "@/organisms/Drawers/components/SourceList/utils"
import { Item, List } from "@/pages/InventoryPage/components/molecules/List"
import styles from "./PackageUpdates.module.scss"

export type PackageUpdatesProps = {
  codeRepoFindings: CodeRepoFinding[]
  imageFindings: ApplicativeImageFinding[]
  fixability: Fixability
  introducedThrough: React.ReactNode
  noLimits?: boolean
  copyable?: boolean
}

export const getUniqueImagePackageFixes = (
  findings: ApplicativeImageFinding[]
): ImagePackageFix[] => {
  const uniqueFixes: ImagePackageFix[] = []

  findings?.forEach((finding) => {
    finding.computedFixes.forEach((fix) => {
      const isUnique = uniqueFixes.every(
        (uniqueFix) =>
          uniqueFix.packageName + uniqueFix.fixType !==
          fix.packageName + fix.fixType
      )

      if (isUnique) {
        uniqueFixes.push(fix)
      }
    })
  })

  return uniqueFixes
}

export const PackageUpdates: React.FC<
  PackageUpdatesProps & { t: TFunction; tKey: string }
> = ({
  codeRepoFindings,
  imageFindings,
  introducedThrough,
  t,
  tKey,
  copyable
}) => {
  const hasImageFixes = imageFindings?.some((f) => f.computedFixes?.length > 0)
  const uniqueFixes = getUniqueImagePackageFixes(imageFindings)
  const sources = findingsToSources(codeRepoFindings)

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div>
          <Text className={styles.subtitleSection}>
            {`${t(`${tKey}packageUpdate`)}:`}
          </Text>
          <InformationPopover.Popover
            content={
              <InformationPopover.CommonTemplate>
                {t(`${tKey}packageUpdatePopover`)}
              </InformationPopover.CommonTemplate>
            }
          />
        </div>
        <div className={styles.inContainer}>
          <div
            className={styles.column}
            data-element-issue-expandable-cell-view="list-code-repo-fixes"
          >
            {hasImageFixes && (
              <List
                items={uniqueFixes}
                render={({ packageName, fixType }: ImagePackageFix) => {
                  return (
                    <Item
                      key={packageName + fixType}
                      value={""}
                      title={t(`general.${fixType}Update`)}
                    />
                  )
                }}
              />
            )}
            <SourceList
              sources={sources}
              type="computedFixes"
              noLimits={!!copyable}
            />
          </div>
          {introducedThrough}
        </div>
      </div>
    </div>
  )
}
