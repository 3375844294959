import { Text } from "@/atoms"
import { CopyButton } from "@/molecules/CopyButton"
import { Trans } from "react-i18next"
import styles from "./HowToUseIntegration.module.scss"

export const CarouselItem = ({
  titleLine1,
  titleLine2,
  codeSnippet
}: {
  titleLine1: string
  titleLine2?: string
  codeSnippet: string | undefined
}) => (
  <div className={styles.carouselItem}>
    <div className={styles.carouselItemTitle}>
      <Text type="secondary">
        <Trans>{titleLine1}</Trans>
        {titleLine2 && (
          <>
            <br />
            <Trans>{titleLine2}</Trans>
          </>
        )}
      </Text>
      <CopyButton text={codeSnippet} type="link" />
    </div>
    <pre className={styles.codeBlock}>
      <code className={styles.scrollable}>{codeSnippet}</code>
    </pre>
  </div>
)
