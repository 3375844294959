import { codeRepoLanguages } from "@/api/useInventory.types"
import {
  EXPLOITABILITY_FILTER_OPTIONS,
  SEVERITIES_FILTER_OPTIONS
} from "@/const/options.constants"
import { TFunction } from "@/hooks/useTranslation"

import type { Filters } from "@/organisms/SmartSearch/types/options.types"
import {
  getCVEFilter,
  getSeverityFilter
} from "@/pages/InventoryPage/utils/filters.utils"

export { SEVERITIES_FILTER_OPTIONS, EXPLOITABILITY_FILTER_OPTIONS }

export const getInitialValues = (props: { params: Record<string, any> }) =>
  props.params

export const getFilters = (t: TFunction): Filters => [
  {
    key: "packageName",
    label: t("filters.package"),
    type: "input",
    helper: [{ description: "Matches package names as a substring" }]
  },
  getCVEFilter(),
  getSeverityFilter(),
  {
    key: "version",
    label: t("filters.version"),
    type: "input",
    helper: [
      {
        description: "Matches package version as substring"
      }
    ]
  },
  {
    key: "languages",
    label: t("filters.languages"),
    type: "multiple",
    options: codeRepoLanguages.map((v) => ({ label: v, value: v })),
    helper: [
      {
        description: "Matches package language as substring"
      }
    ]
  },
  {
    key: "runtime",
    label: t("filters.runtime"),
    type: "single",
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO", disabled: true }
    ],
    helper: [{ description: "Packages that are seen in runtime" }]
  },
  {
    key: "exploitMaturity",
    label: t("filters.exploitability"),
    type: "multiple",
    options: EXPLOITABILITY_FILTER_OPTIONS
  }
]
