import cn from "classnames"

import { Text } from "@/atoms"

import styles from "../SideNavigation.module.scss"

import type { TitleSideNavigationComponent } from ".."

export const TitleSideNavigation: TitleSideNavigationComponent = (props) => (
  <Text className={cn(styles.title, props.collapsed && styles.hidden)}>
    {props.children}
  </Text>
)
