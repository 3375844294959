import { useFilters } from "@/hooks/useFilters"
import { useEffect, useMemo, useRef, useState } from "react"
import { COUNT_UNDO_VALUES_FOR_FILTER_INPUT } from "../const/limit.constants"
import { FilterService } from "../service/FilterService"
import { StringService } from "../service/StringService"

import type { FormEvent } from "react"

export const isKeyboardNavigation = ({ key, shiftKey }: React.KeyboardEvent) =>
  !shiftKey && (key === "ArrowLeft" || key === "ArrowRight")

export const isUndo = ({ key, metaKey }: React.KeyboardEvent) =>
  key === "z" && metaKey

export const isPaste = ({ key }: React.KeyboardEvent) => key === "v"

export const extractInnerText = ({
  currentTarget: { innerText }
}: FormEvent<HTMLDivElement>) => (innerText === "\n" ? "" : innerText)

export const useSearch = ({ id, data, filterOptions }: any) => {
  const ref = useRef<HTMLDivElement>(null)
  const initial = useRef<boolean>(false)
  const { searchParams, setSearchParams, hash } = useFilters()
  const [open, setOpen] = useState<boolean>(false)
  const [trigger, setTrigger] = useState<Record<string, any>>({})
  const rerender = (value = {}) => setTrigger(value)
  const previousText = useRef<{ text: string; caretPos: number }[]>([])

  const FilterServiceInstance = useRef<FilterService>(
    new FilterService(filterOptions)
  ).current
  const StringServiceInstance = useMemo<StringService>(
    () => new StringService(ref, FilterServiceInstance, { initialText: "" }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onClick = (e: any) => {
    const { key, domEvent, replaceByIndex } = e

    if (replaceByIndex !== undefined) return rerender()

    if (domEvent) {
      domEvent?.stopPropagation()
      domEvent?.preventDefault()
    }

    const indexForClear =
      e.target?.getAttribute("data-index-clear-filter-smart-search") ?? false

    if (indexForClear !== false) {
      StringServiceInstance.removeTokenByIndex(indexForClear)

      const tokens = StringServiceInstance.getTokens()

      if (tokens[indexForClear]?.isEmpty) {
        StringServiceInstance.removeTokenByIndex(indexForClear)
      }

      StringServiceInstance.setText(
        StringServiceInstance.buildTextFromTokens()
      ).tokenizer()
      StringServiceInstance.setCursorAtTheEnd()

      rerender()

      return
    }

    if (!key) {
      StringServiceInstance.updateCaretPosAndData()

      if (!document.getSelection()?.toString()) {
        rerender()
      }

      return
    }

    StringServiceInstance.setText(
      StringServiceInstance.buildTextFromTokens(
        key,
        StringServiceInstance.getCaretPos()
      )
    ).tokenizer(StringServiceInstance.getCaretPos())
    rerender()
  }

  const onInput = (event: FormEvent<HTMLDivElement>) => {
    try {
      let text = extractInnerText(event)
      const nativeEvent: any = event?.nativeEvent
      const data = nativeEvent?.data

      if (
        data === '"' &&
        !StringServiceInstance.isValidStringsWithQuote(text)
      ) {
        const caretPos = StringServiceInstance.getCaretPos()
        text = text.slice(0, caretPos) + '"' + text.slice(caretPos)
      }

      StringServiceInstance.setText(text).tokenizer().updateCaretPosAndData()
      rerender()
    } catch (error) {
      console.error(error)
    }
  }

  const onClear = () => {
    StringServiceInstance.setText("").tokenizer().updateCaretPosAndData()
    rerender()

    onKeyDown({ key: "Enter" } as any)
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    try {
      const { key } = e

      e.stopPropagation?.()

      if (isUndo(e)) {
        e.preventDefault()

        previousText.current.pop()

        const prev = previousText.current[previousText.current.length - 1]

        if (prev) {
          StringServiceInstance.setText(prev.text ?? "").tokenizer(
            prev.caretPos
          )

          rerender()
        }

        return
      }

      if (key === "ArrowDown") {
        e.preventDefault()

        document
          .getElementById(id)
          ?.querySelector<HTMLElement>(
            '[data-id-smart-search="ListMenuDropdownSmartSearch"]'
          )
          ?.focus()
        ref.current?.blur()
      }

      if (key === "Enter") {
        e.stopPropagation?.()
        e.preventDefault?.()

        const tokensWithValues = StringServiceInstance.getNotEmptyTokens()

        if (tokensWithValues.some(({ error }) => !!error)) {
          Array.from(
            (
              document.getElementById("input-smart-search") as HTMLDivElement
            )?.querySelectorAll(".value-smart-search, .label-smart-search")
          ).forEach((el) => {
            el.classList.remove("value-smart-search")
            el.classList.remove("edit-smart-search")
          })

          return
        }

        setSearchParams((prev) => {
          const params = new URLSearchParams(prev)

          params.set("pageNumber", "0")

          FilterServiceInstance.getFilterInOrder(
            tokensWithValues.map(({ id, delimiter, value }) => {
              if (id && !delimiter && !value) {
                return "query"
              }

              return id
            })
          ).forEach((filter, index) => {
            const { type } = filter
            let value = tokensWithValues[index]?.value

            if (type === "query") {
              const indexOfValue = tokensWithValues.findIndex(
                ({ id, delimiter, value }) => id && !delimiter && !value
              )

              value = tokensWithValues[indexOfValue]?.id
            }

            if (type === "single") {
              value = filter.options?.find(
                ({ label }) => label === value
              )?.value
            }

            if (type === "multiple" && value) {
              value = value
                .split(",")
                .map(
                  (v: string) =>
                    filter.options?.find(({ label }) => label === v)?.value
                )
                .filter(Boolean)
                .join(",")
            }

            if (value?.[value.length - 1] === ",") {
              value = value.slice(0, -1)
            }

            if (value) {
              const wrappedInQuotationMarks = /^".*"$/im.test(value || "")

              if (wrappedInQuotationMarks) {
                value = value.substring(1, value.length - 1)
              }

              params.set(filter.key, value)
            } else {
              params.delete(filter.key)
            }
          })

          return params
        })

        onBlur()
        ref.current?.blur()

        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onKeyUp = (e: React.KeyboardEvent) => {
    try {
      if (isKeyboardNavigation(e) || isPaste(e)) {
        StringServiceInstance.tokenizer().updateCaretPosAndData(
          StringServiceInstance.getCursorPosition()
        )

        rerender()

        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onFocus = () => {
    setOpen(true)
  }

  const onBlur = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (Object.keys(data).length === 0 || initial.current) return

    StringServiceInstance.setText(
      FilterServiceInstance.updateFiltersOptions(data)
        .syncFiltersValueWithSearchParams(searchParams)
        .getTextInSmartSearchFormat(searchParams)
    ).tokenizer()
    initial.current = true

    rerender()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, StringServiceInstance, FilterServiceInstance])

  useEffect(() => {
    if (open) return

    StringServiceInstance.setText(
      FilterServiceInstance.syncFiltersValueWithSearchParams(
        searchParams
      ).getTextInSmartSearchFormat(searchParams)
    ).tokenizer()

    rerender()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, hash])

  useEffect(() => {
    if (!open || ref.current === null) return

    ref.current.focus()
    StringServiceInstance.setCursorPosition()

    const prev = previousText.current[previousText.current.length - 1]
    const text = StringServiceInstance.getText()

    if (prev?.text === text) {
      return
    }

    previousText.current.push({
      text: StringServiceInstance.getText(),
      caretPos: StringServiceInstance.getCursorPosition()
    })

    if (previousText.current.length > COUNT_UNDO_VALUES_FOR_FILTER_INPUT) {
      previousText.current.shift()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  return {
    html: StringServiceInstance.buildHtmlFromTokens(),
    showPlaceholder: StringServiceInstance.getNotEmptyTokens().length === 0,
    open,
    refInput: ref,
    handlers: {
      onClick,
      onInput,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
      onClear
    },
    services: {
      FilterService: FilterServiceInstance,
      StringService: StringServiceInstance
    }
  }
}
