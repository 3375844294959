import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { TFunction } from "@/hooks/useTranslation"
import { UserCell } from "@/pages/SettingsPage/components/molecules/UserCell"
import { StatusTag } from "../../molecules/StatusTag"
import { UserActionsCell } from "../../molecules/UserActionsCell"

import { UserEntity } from "@/domain/user"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { ColumnType } from "antd/es/table"
import { UserScopesCell } from "../../molecules/UserScopesCell"

export const getColumnsTable = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">,
  {
    t,
    isUserAdmin,
    isDataAuthorizationReleased,
    resetOrResendPassword,
    deleteUser,
    editUser
  }: {
    t: TFunction
    isUserAdmin: boolean
    isDataAuthorizationReleased: boolean
    resetOrResendPassword: any
    deleteUser: any
    editUser: any
  }
): ColumnType<Required<UserEntity>>[] => {
  const columns = [
    {
      title: t("settings.users.columns.user"),
      width: "29%",
      render: UserCell,
      ...getDefaultColumnParams("email", sorter)
    },
    {
      title: t("settings.users.columns.role"),
      width: 80,
      render: (value: string) => {
        // returned capitalized value, (first letter uppercase and the rest lowercase)
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      },
      ...getDefaultColumnParams("role", sorter)
    },
    {
      title: t("settings.users.columns.jobDescription"),

      width: "13%",
      ...getDefaultColumnParams("jobDescription", sorter)
    },
    {
      title: t("settings.users.columns.scopes"),
      width: "24%",
      sorter: false,
      render: UserScopesCell(t)
    },
    {
      title: t("settings.users.columns.status"),
      width: "10%",
      render: StatusTag(t),
      ...getDefaultColumnParams("status", sorter)
    },
    {
      title: t("settings.users.columns.lastSeen"),
      width: "15%",
      sorter: true,
      showSorterTooltip: false,
      ...getDefaultColumnParams("lastLogin", sorter)
    },
    {
      width: 35,
      render: UserActionsCell({
        t,
        resetOrResendPassword,
        deleteUser,
        editUser
      })
    }
  ]

  if (!isUserAdmin) {
    return columns.slice(0, 3).concat(columns.slice(4, -1))
  }
  if (!isDataAuthorizationReleased) {
    return columns.slice(0, 3).concat(columns.slice(4))
  }
  return columns
}
