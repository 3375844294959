import { Badge, Title } from "@/atoms"
import { getVulnerabilityColorBySeverity } from "@/domain/vulnerability"
import { useTranslation } from "@/hooks/useTranslation"
import type { SeverityLabelProps } from "."
import { ExploitabilityTag } from "../../../../../molecules/ExploitabilityTag"
import styles from "./SeverityLabel.module.scss"

export const SeverityLabel = ({
  count,
  severity,
  highestExploitMaturity
}: SeverityLabelProps) => {
  const color = getVulnerabilityColorBySeverity(severity)
  const { t } = useTranslation()
  return (
    <div className={styles.wrap}>
      <Title className={styles.title} level={5} style={{ color }}>
        {severity}
        <Badge className={styles.badge} color={color} count={count} />
      </Title>
      <ExploitabilityTag maturity={highestExploitMaturity} t={t} />
    </div>
  )
}
