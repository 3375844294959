import * as TriageApi from "@/api/useTriage"
import init, { convert } from "htmltoadf"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import { shallowEqualObjects } from "@/helpers/utils"
import { fetcher, predicateHelpers, useQueryClient } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { usePrevious } from "@/hooks/usePrevious"
import { useQueryString } from "@/hooks/useQueryString"
import useServices from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import {
  actions,
  useSelection
} from "@/pages/TriagePage/application/useSelection"
import {
  getFiltersTriageSelector,
  getIssuesTriageSelectorLoadMore,
  getProjectsTriageSelector
} from "./useTriage.selector"

import type { ProjectsTriageResponse } from "@/api/useTriage.types"
import type { ExploitMaturity } from "@/domain/package"
import type { Params, QueryClient } from "@/hooks/useHttp.types"
import type { Data, Issues, Payload, UseDismissProps } from "./useTriage.types"

export const useFiltersTriage = () => {
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const select = (response: any) =>
    getFiltersTriageSelector(response, { t, internetFacingFF })
  const isEnabled = internetFacingFF !== undefined
  const context = TriageApi.useFiltersTriage({
    select,
    enabled: isEnabled
  })
  return [context, context.isLoading || !isEnabled] as const
}

export const useProjectsTriage = (keys: string[], isEnabled?: boolean) => {
  const params: Record<string, unknown> = useQueryString([
    "orderBy",
    "ascending",
    ...keys
  ])
  const previousParams = usePrevious(params)
  const queryClient = useQueryClient()
  const [selectionState, dispatch] = useSelection()
  const needResetProjectCache =
    selectionState.wasUpdated && !shallowEqualObjects(params, previousParams)

  if (needResetProjectCache) {
    queryClient.removeQueries({ queryKey: [RESOURCES_NAMES.TRIAGE.PROJECTS] })
    dispatch(
      actions.setFlags({ wasUpdated: false, closeAllExpandedParentRows: true })
    )
  }

  const context = TriageApi.useProjectsTriage(params, {
    select: getProjectsTriageSelector,
    enabled: isEnabled
  })
  const { orderBy, ascending, pageNumber, ...restParams } = params

  return [context, restParams] as const
}

export const useIssuesProjectTriage = (
  params: Params<{ needle: string }>,
  keys: string[],
  config?: any
) => {
  const paramsQueryString = useQueryString(["orderBy", "ascending", ...keys])

  const context = TriageApi.useLoadMoreIssuesProjectTriage(
    { ...paramsQueryString, ...params },
    {
      select: getIssuesTriageSelectorLoadMore,
      ...config
    }
  )

  return context
}

export const getOptionsUseDismissIssues =
  (params: Record<string, unknown>, services: ReturnType<typeof useServices>) =>
  (queryClient: QueryClient) => ({
    onSuccess: async (
      _: { projectId: string; needle: string; data: Payload },
      variables: { projectId: string; needle: string; data: Payload }
    ) => {
      const { projectId, needle } = variables

      const result = await queryClient.fetchQuery<ProjectsTriageResponse>({
        staleTime: 0,
        gcTime: 0,
        queryKey: [RESOURCES_NAMES.TRIAGE.PROJECTS, { projectId, ...params }],
        queryFn: ({ queryKey }) => fetcher({ queryKey } as any, services)
      })

      queryClient
        .getQueriesData<ProjectsTriageResponse>({
          queryKey: [RESOURCES_NAMES.TRIAGE.PROJECTS]
        })
        .forEach(([key, response]) => {
          const { data: dataInResponse = [] } = response || {}

          if (!dataInResponse?.length) return

          const index = dataInResponse.findIndex(({ id }) => id === projectId)

          if (index === -1) return

          queryClient.setQueryData<ProjectsTriageResponse | undefined>(
            key,
            (oldData) => {
              try {
                if (!oldData?.data?.length) return oldData

                const { data, ...restOldData } = oldData

                return {
                  data: data.map((project, currentIndex) =>
                    index === currentIndex
                      ? getProjectsTriageSelector({
                          ...result,
                          data: !!result?.data?.length
                            ? result.data
                            : [
                                {
                                  ...project,
                                  kodemScore: 0,
                                  relatedApplications: [],
                                  issuesSummary: {
                                    critical: 0,
                                    high: 0,
                                    medium: 0,
                                    low: 0,
                                    negligible: 0,
                                    unknown: 0
                                  },
                                  riskInsights: {
                                    runtime: false,
                                    internetFacing: "UNDETERMINED" as const,
                                    ingress: false,
                                    exploitMaturities: [
                                      "UNDETERMINED"
                                    ] as ExploitMaturity[]
                                  }
                                }
                              ]
                        }).data[0]
                      : project
                  ),
                  ...restOldData
                }
              } catch {
                return oldData
              }
            }
          )
        })

      const currentIssueUrl = `${RESOURCES_NAMES.TRIAGE.PROJECTS}${needle}`
      const currentIssueRequestKey = [currentIssueUrl, JSON.stringify(params)]
      const currentIssueRequestKeyHash = JSON.stringify(currentIssueRequestKey)

      queryClient.removeQueries({
        queryKey: [currentIssueUrl],
        predicate: ({ queryHash }) =>
          params ? queryHash !== currentIssueRequestKeyHash : false
      })
      queryClient.refetchQueries({ queryKey: currentIssueRequestKey })
      queryClient.resetQueries({
        predicate: predicateHelpers.includesKeysPredicate(
          [RESOURCES_NAMES.TRIAGE.ISSUES],
          ["/count"]
        )
      })
    },
    onSettled: undefined
  })

export const useDismissIssues = (
  params: Record<string, unknown>,
  props: UseDismissProps = {},
  getOptions: typeof getOptionsUseDismissIssues = getOptionsUseDismissIssues
) => {
  const [, dispatch] = useSelection()
  const services = useServices()
  const { t } = useTranslation()
  const context = TriageApi.useDismissIssues(
    params,
    getOptions(params, services)
  )
  const { logger, EVENTS } = useLogger()

  const updaterByProject = (projectId: string, data: Data) => {
    try {
      return context.mutateAsync({
        projectId,
        needle: `/${projectId}/issues`,
        data: {
          reason: data.reason,
          status: data.status,
          issueIds: data.issues.map((issue) => `${issue.id}`)
        }
      })
    } catch (err) {
      logger.error(err as Error)
    }
  }

  const updater = async (
    data: Issues,
    dismiss: boolean,
    attr?: Pick<Payload, "reason">
  ) => {
    let totalUpdatedIssues = undefined
    const projectIds = Object.keys(data || {})
    let successfullyUpdatedProjectIds: string[] = []
    let withErrorProjectIds: string[] = []
    const loadingMessageKey = `triage.${
      dismiss ? "dismissingIssue" : "reopeningIssue"
    }`

    if (props.showLoadingMessage) {
      const total = projectIds.reduce((acc, projectId) => {
        acc += data[projectId].length

        return acc
      }, 0)
      props.messageApi?.open({
        key: loadingMessageKey,
        type: "loading",
        content: t(loadingMessageKey, { count: total }),
        duration: 0
      })
    }

    try {
      dispatch(actions.setUpdatingStateForParentRow(projectIds))

      const result = await Promise.all(
        projectIds.map((projectId) =>
          updaterByProject(projectId, {
            status: dismiss ? "dismissed" : "open",
            issues: data[projectId],
            reason: dismiss ? attr?.reason : undefined
          })
        )
      )

      projectIds.forEach((_, index) => {
        if (!!(result[index] as { ok: boolean } | undefined)?.ok)
          successfullyUpdatedProjectIds.push(projectIds[index])
        else withErrorProjectIds.push(projectIds[index])
      })

      totalUpdatedIssues = successfullyUpdatedProjectIds.reduce(
        (acc, projectId) => {
          acc += data[projectId].length

          return acc
        },
        0
      )
    } catch (err) {
      logger.error(err as Error)
    } finally {
      dispatch(
        actions.setUpdatingStateForParentRow(
          successfullyUpdatedProjectIds,
          withErrorProjectIds
        )
      )

      props.messageApi?.destroy(loadingMessageKey)

      if (dismiss)
        props.messageApi?.success(
          t("triage.dismiss_issue_success", { count: totalUpdatedIssues })
        )

      props.onSuccess?.()

      const { DISMISS_ISSUE, REOPEN_ISSUE } = EVENTS.ANALYTIC_EVENTS.TRIAGE
      const { componentName } = props
      const meta = componentName ? { componentName } : undefined

      logger.info(dismiss ? DISMISS_ISSUE : REOPEN_ISSUE, meta)
    }
  }

  const dismissIssues = (data: Issues, attr?: Pick<Payload, "reason">) =>
    updater(data, true, attr)
  const reopenIssues = (data: Issues) => updater(data, false)

  return [{ dismissIssues, reopenIssues }, context] as const
}

export const useIssues = () => {
  const { logger, EVENTS } = useLogger()

  const createTicketTriage = TriageApi.useCreateTicketIssue()
  const client = useQueryClient()

  const onCreateTicket = async (
    dataIssue: Jira.CreateIssuePayload,
    props?: { id?: string; parentId?: string }
  ) => {
    if (!props) return

    try {
      const { id: issueId } = props

      if (!issueId) return

      logger.info(EVENTS.ANALYTIC_EVENTS.TRIAGE.CREATE_ISSUE_JIRA, {
        issueId: issueId
      })

      await init()

      dataIssue.jiraIssueFields.fields.description = JSON.parse(
        convert(dataIssue.jiraIssueFields.fields.description || "")
      )

      const {
        jiraIssueFields: { fields }
      } = dataIssue

      const result = await createTicketTriage.mutateAsync({
        data: {
          create_ticket_data: { data: { fields } },
          issues_ids: [issueId]
        } as any,
        needle: `/ticket`
      })

      await Promise.allSettled(
        client
          .getQueryCache()
          .getAll()
          .filter(
            ({ queryHash }) =>
              queryHash.includes(issueId) ||
              queryHash.includes(RESOURCES_NAMES.TRIAGE.PROJECTS)
          )
          .map((q) => client.invalidateQueries(q))
      )

      return result as any
    } catch (err) {
      return Promise.reject(err)
    }
  }

  return onCreateTicket
}
