import { CellTable, SortOrder } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ExploitabilityCell } from "@/molecules/ExploitabilityCell"
import { LanguageIconCell } from "@/molecules/LanguageIconCell"
import { VulnerabilitiesCell } from "@/molecules/VulnerabilitiesCell"
import { InsightsPackageCell } from "../InsightsPackageCell"

import type { ParamsGetColumnsTable } from "@/organisms/Drawers/components/TabDrawerTemplate"

export const getColumnsTable = ({ t, orderByProps }: ParamsGetColumnsTable) => [
  {
    title: "Package Name",
    sortDirections: ["descend", "ascend", "descend"] as SortOrder[],
    render: LanguageIconCell,
    width: "100%",
    ...getDefaultColumnParams("name", orderByProps),
    defaultSortOrder: "ascend" as SortOrder
  },
  {
    title: "Version",
    width: "100%",
    className: "version-column-table",
    render: CellTable,
    ...getDefaultColumnParams("version")
  },
  {
    title: "Fix Version",
    width: "100%",
    className: "fix-version-column-table",
    render: CellTable,
    ...getDefaultColumnParams("fixVersion")
  },
  {
    title: "Exploitability",
    width: 118,
    render: ExploitabilityCell(t),
    ...getDefaultColumnParams("exploitMaturity", orderByProps)
  },
  {
    title: "Insights",
    width: 80,
    render: InsightsPackageCell,
    ...getDefaultColumnParams("insights")
  },
  {
    title: "Vulnerabilities",
    width: 165,
    render: VulnerabilitiesCell,
    ...getDefaultColumnParams("vulnerabilities")
  }
]
