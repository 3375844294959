import cn from "classnames"

import { Menu } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ViewsOutlined } from "@/icons"
import { FooterMenu } from "./components/FooterMenu"
import { ListMenu } from "./components/ListMenu"
import { useScope } from "./useScope"

import { EmptyMenu } from "./components/EmptyMenu"

import styles from "./ScopeMenu.module.scss"

import type { ScopeMenuProps } from "."

export const ScopeMenu: React.FC<ScopeMenuProps> = ({ collapsed }) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings.scopes" })
  const {
    data,
    search,
    isLoading,
    isFetchedAfterMount,
    selectedId,
    currentScopeId,
    currentScopeName,
    openKeys,
    releaseDataAuthorizationFF,
    scopesMenuKey,
    actions
  } = useScope()
  const applyDisabled =
    (!selectedId && !currentScopeId) || `${selectedId}` === `${currentScopeId}`

  return (
    <Menu
      triggerSubMenuAction="click"
      disabled={!isFetchedAfterMount}
      className={cn(
        styles.menu,
        !collapsed && styles.notCollapsed,
        currentScopeName && styles.scopeIsActive
      )}
      theme="dark"
      openKeys={openKeys}
      onOpenChange={(openKeys) => {
        if (releaseDataAuthorizationFF && currentScopeId === null) return
        if (!!openKeys.length) {
          actions.resetToCurrentScope()
        }
        actions.setOpenKeys(openKeys)
      }}
      items={[
        {
          key: scopesMenuKey,
          label: currentScopeName || t("title"),
          icon: <ViewsOutlined />,
          popupClassName: styles.popupClassName,
          children: [
            {
              label: (
                <ListMenu
                  title={t("title")}
                  description={t("menu.description")}
                  selectedId={selectedId}
                  setSelectedItem={actions.setSelectedScope}
                  search={search}
                  isLoading={isLoading}
                  items={data || []}
                  footer={
                    <FooterMenu
                      applyDisabled={applyDisabled}
                      clearDisabled={!selectedId}
                      manageScopesDisabled={
                        releaseDataAuthorizationFF && currentScopeId === null
                      }
                      actions={actions}
                    />
                  }
                  empty={<EmptyMenu actions={actions} />}
                  actions={actions}
                />
              ),
              key: "scopesMenu"
            }
          ]
        }
      ]}
    />
  )
}
