import {
  Collapse,
  Form,
  Input,
  message,
  Select,
  SelectProps,
  Text
} from "@/atoms"
import { Role, ScopeMetadata } from "@/domain/user"
import { compareByDisabledAndLabel } from "@/helpers/compare.helper"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { InformationPopover } from "@/molecules/InformationPopover"
import {
  EditUserRequestPayload,
  InviteUserRequestPayload
} from "@/pages/SettingsPage/application/users/useUsersSettings.types"
import {
  filterEnabledValues,
  isOption,
  MultiSelectWithDisabledOptions
} from "@/pages/SettingsPage/components/molecules/MultiSelectWithDisabledOptions"
import { useEffect } from "react"
import type { Option, PermissionMode, UserFormProps } from "."
import { RadioLabel } from "../../molecules/RadioLabel"
import styles from "./UserForm.module.scss"

export const UserForm = (props: UserFormProps) => {
  const {
    form,
    scopeOptions,
    actions,
    setLoading,
    setValues,
    serverError,
    setServerError,
    onClose,
    user,
    isScopesLoading
  } = props
  const { t } = useTranslation("translation", {})
  const keyPrefix = "settings.userModal"
  const { t: tModal } = useTranslation("translation", { keyPrefix })
  const { t: tAction } = useTranslation("translation", {
    keyPrefix: `${keyPrefix}.${user.id ? "edit" : "invite"}`
  })
  const { logger, EVENTS } = useLogger()
  const dataAuthorizationReleasedFF = useFeatureFlags(
    "releaseDataAuthorization"
  )
  const roles: Role[] = ["ADMIN", "USER"]
  const roleOptions: SelectProps<Role>["options"] = roles.map((role) => ({
    label: t(`settings.users.roles.${role}`),
    value: role
  }))

  const KEYS: Record<string, PermissionMode> = {
    all: "ALL",
    specific: "SPECIFIC"
  }

  const getInitialValues = () => {
    return {
      email: user.email || "",
      role: user.role || "USER",
      activeKey:
        user?.permissions?.areAllScopesPermitted !== false
          ? KEYS.all
          : KEYS.specific,
      selectedScopes: scopeOptions
        ?.filter((scope) => scope.selected)
        .sort(compareByDisabledAndLabel)
    }
  }

  const activeKey = Form.useWatch("activeKey", form) || KEYS.all
  const selectedRole = Form.useWatch<Role>("role", form) || user.role || "USER"

  const email = Form.useWatch("email", form)

  useEffect(() => {
    if (email)
      form
        .validateFields()
        .then((values) => {
          setValues(values)
        })
        .catch(() => {
          setValues(null)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  const getSpecificScopes = (
    selectedScopes: (Option | string)[],
    areAllScopesPermitted: boolean
  ): ScopeMetadata[] => {
    const userSpecificScopes = areAllScopesPermitted
      ? []
      : filterEnabledValues(selectedScopes, scopeOptions).map<ScopeMetadata>(
          (scope) =>
            isOption(scope)
              ? { id: scope.value, name: scope.label }
              : { id: scope, name: "" }
        )
    return userSpecificScopes
  }

  const editUserSubmit = async (
    userId: string,
    values: {
      activeKey: string
      selectedScopes: (Option | string)[]
      role: Role
    }
  ) => {
    const { activeKey, selectedScopes, role } = values
    let request: EditUserRequestPayload
    if (!dataAuthorizationReleasedFF) {
      //TODO: Remove when data authorization is released
      request = {
        role: values.role,
        permissions: null
      }
    } else {
      const areAllScopesPermitted = activeKey === KEYS.all || role === "ADMIN"
      const userSpecificScopes = getSpecificScopes(
        selectedScopes,
        areAllScopesPermitted
      )
      request = {
        role: role,
        permissions: {
          areAllScopesPermitted: areAllScopesPermitted,
          specificScopes: userSpecificScopes
        }
      }
    }

    const { err } = await actions.editUser(userId, request)

    if (!err) {
      onClose()
      logger.info(EVENTS.ANALYTIC_EVENTS.SETTINGS.USERS.USER_UPDATED, {
        email
      })

      return message.success(tAction("successMessage", { email }))
    }

    throw new Error(err)
  }

  const inviteUserSubmit = async (values: {
    email: string
    activeKey: string
    selectedScopes: (Option | string)[]
  }) => {
    const { email, activeKey, selectedScopes } = values
    let request: InviteUserRequestPayload
    if (!dataAuthorizationReleasedFF) {
      //TODO: Remove when data authorization is released
      request = {
        userEmail: email,
        permissions: null
      }
    } else {
      const areAllScopesPermitted = activeKey === KEYS.all
      const userSpecificScopes = getSpecificScopes(
        selectedScopes,
        areAllScopesPermitted
      )
      request = {
        userEmail: email,
        permissions: {
          areAllScopesPermitted: areAllScopesPermitted,
          specificScopes: userSpecificScopes
        }
      }
    }
    const { err, data } = await actions.inviteUser(request)

    if (!err) {
      onClose()
      logger.info(EVENTS.ANALYTIC_EVENTS.SETTINGS.USERS.USER_INVITED, {
        email
      })
      return message.success(tAction("successMessage"))
    }

    const { detail } = data || {}

    if (detail && typeof detail === "string") {
      const text = tModal(detail)

      return form.setFields([
        {
          name: "email",
          value: values?.email,
          errors: [text === `${keyPrefix}.${detail}` ? detail : text]
        }
      ])
    }
    form.resetFields()

    throw new Error(err)
  }
  return (
    <Form
      form={form}
      layout="vertical"
      preserve={false}
      className={styles.form}
      clearOnDestroy
      onValuesChange={() => {
        form.setFieldValue("dirty", true)
      }}
      onFinish={async (values: {
        email: string
        role: Role
        activeKey: string
        selectedScopes: string[]
      }) => {
        try {
          setLoading(true)
          setServerError(false)
          return user.id
            ? await editUserSubmit(user.id, values)
            : await inviteUserSubmit(values)
        } catch (err: any) {
          setServerError(true)
        } finally {
          setLoading(false)
        }
      }}
      requiredMark={false}
      initialValues={getInitialValues()}
    >
      <Form.Item noStyle name="dirty" required={false} />
      <Form.Item
        className={styles.itemForm}
        required={true}
        name="email"
        label={t("general.email")}
        rules={[
          { type: "email", message: t("error.emailInvalid") || "" },
          { required: true, message: t("error.emailRequired") || "" }
        ]}
      >
        <Input
          placeholder={tModal("emailPlaceholder") || ""}
          autoComplete="off"
          onKeyDown={() => {
            if (serverError) setServerError(false)
          }}
          disabled={!!user.id}
        />
      </Form.Item>
      <Form.Item
        className={styles.itemForm}
        required={false}
        name="role"
        label={tModal("roleSelectLabel")}
      >
        <Select<Role>
          disabled={!!!user.id}
          options={roleOptions}
          onChange={(value) => {
            if (value === "ADMIN") {
              form.setFieldsValue({ activeKey: KEYS.all })
            }
          }}
        />
      </Form.Item>
      {dataAuthorizationReleasedFF && (
        <>
          <div>
            <Text className={styles.label}>{tModal("selectScopeLabel")}</Text>
            <InformationPopover.Popover
              content={
                <InformationPopover.CommonTemplate>
                  {tModal("scopesPopover")}
                </InformationPopover.CommonTemplate>
              }
            />
          </div>
          <Collapse
            accordion
            bordered={false}
            className={styles.collapse}
            activeKey={activeKey}
            items={[
              {
                key: `${KEYS.all}_disabled`,
                showArrow: false,
                label: (
                  <RadioLabel
                    label={tModal("allScopes")}
                    checked={activeKey === KEYS.all}
                    onChange={() => {
                      form.setFieldValue("activeKey", KEYS.all)
                      form.setFieldValue("dirty", true)
                    }}
                  />
                ),
                className: styles.panel,
                children: null
              },
              {
                key: KEYS.specific,
                showArrow: false,
                // forceRender: true,
                label: (
                  <Form.Item name="activeKey" noStyle>
                    <RadioLabel
                      label={tModal("specificScopes")}
                      description={""}
                      disabled={selectedRole === "ADMIN"}
                      checked={activeKey === KEYS.specific}
                      onChange={() => {
                        form.setFieldValue("activeKey", KEYS.specific)
                      }}
                    />
                  </Form.Item>
                ),
                className: styles.panel,
                children: (
                  <div className={styles.container}>
                    <Text type="secondary">
                      {tModal("specificScopesDescription")}
                    </Text>
                    <Form.Item name="selectedScopes" noStyle>
                      <MultiSelectWithDisabledOptions
                        loading={isScopesLoading}
                        options={scopeOptions}
                      />
                    </Form.Item>
                  </div>
                )
              }
            ]}
          />
        </>
      )}
    </Form>
  )
}
