import cn from "classnames"
import { Fragment } from "react"

import { Text, Tooltip } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Item, List } from "@/pages/InventoryPage/components/molecules/List"
import { ComputedFixesItem } from "./ComputedFixesItem"
import { IntroducedTroughItem } from "./IntroducedTroughItem"

import styles from "./SourceList.module.scss"

import type { SourceListComponent } from "."

export const SourceList: SourceListComponent = (props) => {
  const { t } = useTranslation()
  const { sources, type, noLimits } = props
  const tKey = "inventory.images.drawer.issue."
  const Component = sources.length > 1 ? "li" : "div"

  return (
    <List
      items={sources}
      limit={noLimits ? undefined : 2}
      Component="ol"
      classes={
        sources.length <= 1
          ? { list: cn(styles.listWithoutPadding, styles.list) }
          : { list: styles.list }
      }
      showMoreTitle={`${t(`${tKey}showMoreSources`)}`}
      showLessTitle={`${t(`${tKey}showLessSources`)}`}
      render={(
        {
          name,
          introducedThrough,
          computedFixes,
          computationError,
          fixability
        },
        index
      ) => {
        const hasCodeRepoFixes = computedFixes.length > 0
        // const Wrapper = !!name && name !== "." ? Tooltip : Fragment

        return (
          <Fragment key={index}>
            <Item
              title={
                <div className={styles.titleItemContainer}>
                  {`Source: `}
                  <Tooltip title={name}>
                    <Text ellipsis>{name}</Text>
                  </Tooltip>
                </div>
              }
              Component={Component}
              classes={{ title: styles.titleItem }}
            />
            {type === "introducedThrough" && (
              <IntroducedTroughItem introducedThrough={introducedThrough} />
            )}
            {type === "computedFixes" && (
              <>
                {!!computedFixes.length && (
                  <ComputedFixesItem
                    computedFixes={computedFixes}
                    noLimits={noLimits}
                  />
                )}
                {!hasCodeRepoFixes && fixability === "partial" && (
                  <Text className={cn(styles.value, styles.noComputedFixes)}>
                    {`${t(`${tKey}kodemNoFix`)}`}
                  </Text>
                )}
                {computationError && (
                  <Text className={styles.errorText}>
                    {`${t(`${tKey}${computationError}`)}`}
                  </Text>
                )}
                {!computationError && fixability === "not_fixable" && (
                  <Text className={styles.value}>{`${t(
                    `${tKey}kodemNoFix`
                  )}`}</Text>
                )}
                {!computationError && fixability === "has_fix" && (
                  <Text className={styles.value}>
                    {`${t(`${tKey}kodemNoFix`)}`}
                  </Text>
                )}
              </>
            )}
          </Fragment>
        )
      }}
    />
  )
}
