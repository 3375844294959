import type { SbomFiltersResponse } from "@/api/useSbom.types"

type OptionsFilter = { label: string; value: string }[]

export const getLabel = (label: string) =>
  label.indexOf(" ") !== -1 ? `"${label}"` : label

export const getOptionsForFilters = (
  response: SbomFiltersResponse,
  t: (str: string) => string
) => {
  const { data } = response || {}

  return Object.keys(data).reduce(
    (acc, key) => {
      if (key === "exploitMaturity") {
        const values = data[key] as string[]
        acc[key] = values.map((value) => ({
          label: t(`sbom.exploitabilityInputFilter.${value}`),
          value
        }))
        return acc
      }
      acc[key] = data[key].map((value) =>
        typeof value === "string"
          ? { label: getLabel(value), value }
          : { ...value, label: getLabel(value.label) }
      )

      return acc
    },
    {} as Record<string, OptionsFilter>
  )
}
