import { Children, useRef } from "react"

import { Skeleton, Tabs } from "@/atoms"
import { EC2, ECS, Fargate, Kubernetes } from "@/icons"
import { DeployTypeButton } from "../../molecules/DeployTypeButton"

import styles from "./DeployTabs.module.scss"

import type { DeployTabsProps } from "."

export const DeployTabs = ({ children, loading }: DeployTabsProps) => {
  const tabs = useRef(Children.toArray(children)).current

  return (
    <Tabs
      className={styles.container}
      items={
        loading
          ? [
              {
                label: (
                  <Skeleton.Node active className={styles.nodeSkeleton}>
                    <div />
                  </Skeleton.Node>
                ),
                key: "loading",
                children: (
                  <>
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 3 }} />
                  </>
                )
              }
            ]
          : [
              {
                label: <DeployTypeButton name="Kubernetes" Icon={Kubernetes} />,
                key: "kubernetes",
                children: tabs[0]
              },
              {
                label: <DeployTypeButton name="ECS" Icon={ECS} disabled />,
                key: "ecs",
                children: tabs[1],
                disabled: true
              },
              {
                label: (
                  <DeployTypeButton name="Fargate" Icon={Fargate} disabled />
                ),
                key: "fargate",
                children: tabs[2],
                disabled: true
              },
              {
                label: <DeployTypeButton name="EC2" Icon={EC2} disabled />,
                key: "ec2",
                children: tabs[3],
                disabled: true
              }
            ]
      }
    />
  )
}
