import { Fixability } from "@/api/useIssues.types"
import { Text } from "@/atoms"
import type { BaseImageFinding, BaseImageFix } from "@/domain/issue"
import type { TFunction } from "@/hooks/useTranslation"
import { Item, List } from "@/pages/InventoryPage/components/molecules/List"
import styles from "./BaseImageFixes.module.scss"

export type BaseImageFixesProps = {
  baseImageFindings: BaseImageFinding[]
  introducedThrough: React.ReactNode
  fixability: Fixability
}

export const getUniqueBaseImageFixes = (
  fixes: BaseImageFix[]
): BaseImageFix[] => {
  const uniqueFixes: BaseImageFix[] = []

  fixes?.forEach((fix) => {
    const isUnique = uniqueFixes.every(
      (uniqueFix: BaseImageFix) =>
        uniqueFix.digest +
          uniqueFix.fixType +
          uniqueFix.repository +
          uniqueFix.tags !==
        fix.digest + fix.fixType + fix.repository + fix.tags
    )

    if (isUnique) {
      uniqueFixes.push(fix)
    }
  })
  return uniqueFixes
}

export const BaseImageFixes: React.FC<
  BaseImageFixesProps & { t: TFunction; tKey: string }
> = ({ baseImageFindings, introducedThrough, fixability, t, tKey }) => {
  const allBaseImageFixes: BaseImageFix[] = baseImageFindings
    .map((f) => f.computedFixes)
    .flat()
  const uniqueFixes = getUniqueBaseImageFixes(allBaseImageFixes)
  const limit = 2
  const computedErrors = baseImageFindings.map((f) => f.computationError)
  const hasComputedError = computedErrors.some((error) => !!error)
  return (
    <div className={styles.section}>
      <Text className={styles.subtitleSection}>
        {`${t(`${tKey}baseImageUpdate`)}:`}
      </Text>
      <div className={styles.inContainer}>
        <div className={styles.column}>
          {hasComputedError ? (
            computedErrors.map((error, index) => (
              <Text key={index} className={styles.value}>
                {`${t(`${tKey}${error}`)}`}
              </Text>
            ))
          ) : (
            <>
              {fixability === "not_fixable" ? (
                <Text className={styles.value}>
                  {`${t(`${tKey}kodemNoFix`)}`}
                </Text>
              ) : (
                <>
                  {fixability === "has_fix" ? (
                    <Text className={styles.value}>
                      {t(`${tKey}undeterminedBaseImageFix`)}
                    </Text>
                  ) : null}
                </>
              )}
            </>
          )}

          {fixability === "partial" && uniqueFixes.length === 0 && (
            <Text className={styles.value}>
              {t(`${tKey}undeterminedBaseImageFix`)}
            </Text>
          )}

          <List
            limit={uniqueFixes.length > limit ? limit : undefined}
            items={uniqueFixes}
            render={({ fixType, tags, repository }: BaseImageFix, i) => {
              const key = `${fixType}-${i}`
              const isRebuild = fixType === "rebuild"
              const isReplaceOrRemove =
                fixType === "replace" || fixType === "remove"
              const title = isRebuild
                ? t("general.minorUpdate")
                : t(`general.${fixType}Update`)
              const value = isReplaceOrRemove
                ? ""
                : `${repository}:${tags?.[0] || ""}`
              return (
                <Item
                  key={key}
                  classes={{ title: styles.titleItem }}
                  title={title}
                  value={value}
                  suffix={
                    value ? <Text copyable={{ text: value }} /> : undefined
                  }
                />
              )
            }}
          />
        </div>
        {introducedThrough}
      </div>
    </div>
  )
}
