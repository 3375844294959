import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ExploitabilityCell } from "@/molecules/ExploitabilityCell"
import { LanguageIconCell } from "@/molecules/LanguageIconCell"
import { VulnerabilitiesCellWithoutTooltip } from "@/molecules/VulnerabilitiesCellWithoutTooltip"

import type { ParamsGetColumnsTable } from "@/organisms/Drawers/components/TabDrawerTemplate"
import { InsightsSbomCell } from "@/pages/SbomPage/components/molecules/InsightsSbomCell"

export const getColumnsTable = ({ orderByProps, t }: ParamsGetColumnsTable) => [
  {
    title: "Package",
    width: "100%",
    showSorterTooltip: false,
    render: LanguageIconCell,
    ...getDefaultColumnParams("name", orderByProps)
  },
  {
    title: "Version",
    width: "100%",
    className: "version-column-table",
    render: CellTable,
    ...getDefaultColumnParams("version", orderByProps)
  },
  {
    title: "Fix Version",
    width: "100%",
    className: "fix-version-column-table",
    render: CellTable,
    ...getDefaultColumnParams("ultimateFix")
  },
  {
    title: "Exploitability",
    width: 130,
    render: ExploitabilityCell(t),
    ...getDefaultColumnParams("exploitMaturity", orderByProps)
  },
  {
    title: "Insights",
    width: 80,
    render: InsightsSbomCell({ internetFacingFF: false }),
    ...getDefaultColumnParams("insights")
  },
  {
    title: "Vulnerabilities",
    width: 162,
    render: VulnerabilitiesCellWithoutTooltip,
    ...getDefaultColumnParams("vulnerabilities")
  }
]
