import { Descriptions } from "@/atoms"
import type { OverviewDescriptionsComponent } from "."
import { LabelOverviewDescriptions } from "./LabelOverviewDescriptions"
import styles from "./OverviewDescriptions.module.scss"
import { TitleOverviewDescriptions } from "./TitleOverviewDescriptions"

export const OverviewDescriptions: OverviewDescriptionsComponent = ({
  flex,
  layout = "vertical",
  bordered = true,
  ...props
}) => {
  if (flex) {
    const { items } = props

    return (items || []).map((item, index) => (
      <div key={index} className={styles.itemFlex}>
        <span className={styles.labelFlex}>{item.label}</span>
        <span className={styles.valueFlex}>{item.children}</span>
      </div>
    ))
  }

  if (bordered) {
    return (
      <Descriptions
        bordered={bordered}
        colon={false}
        size="small"
        layout={layout}
        {...props}
      />
    )
  }
  return (
    <div className={styles.noBorder}>
      <Descriptions
        bordered
        colon={false}
        size="small"
        layout={layout}
        {...props}
      />
    </div>
  )
}
OverviewDescriptions.Label = LabelOverviewDescriptions
OverviewDescriptions.Title = TitleOverviewDescriptions
