import { SEVERITIES_FILTER_OPTIONS } from "@/const/options.constants"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { InformationPopover } from "@/molecules/InformationPopover"
import { CODE_REPOSITORY_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import {
  useBranch,
  useBranchArchivedRepos
} from "@/pages/InventoryPage/application/branch-inventory/useBranchInventory"
import { getBranchTabData } from "@/pages/InventoryPage/application/branch-inventory/useBranchInventory.selector"
import { TabTemplate } from "@/templates/TabTemplate"
import { useEffect, useState } from "react"
import { getColumnsTable } from "./getColumnsTable"
import { getItemsFilter } from "./getItemsFilter"

import styles from "./BranchTab.module.scss"

// import { ExportButton } from "@/molecules/ExportButton"
import type { BranchTabProps } from "."

type TRecord = ReturnType<typeof getBranchTabData>["data"][number]

export const BranchTab: React.FC<BranchTabProps> = (props) => {
  const { useActiveKeyTabsInventory } = props
  const { activeKey, config = {} } = useActiveKeyTabsInventory()
  const { t } = useTranslation()
  const { t: tAsideFooter } = useTranslation("translation", {
    keyPrefix: "inventory.codeRepositoriesTab.asideFooter"
  })
  const { idsInDrawers, onRowClick } = useDrawer(CODE_REPOSITORY_DRAWER_ID, {
    filters: ["severity", "riskId", "runtime"]
  })
  const filters = getItemsFilter(t)
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const { response, isLoading } = useBranch(filters, {
    enabled: activeKey === TAB_KEYS[0],
    ...config
  })
  const { response: archivedRepoResponse, isLoading: isLoadingArchived } =
    useBranchArchivedRepos()
  const { data, metadata } = response || {}
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const filterDisabled = !!dataReadinessFF
  const previewMode = !!config.headers
  const [showArchivedData, setShowArchivedData] = useState(false)

  useEffect(() => {
    const showFooter: boolean = !isLoading && !isLoadingArchived

    if (!showArchivedData && showFooter) setShowArchivedData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLoadingArchived])

  const convertors = useNumberConvertor()
  const { onSorterChange, ...orderByProps } = useOrderBy()

  useTooltip(data)

  return (
    <TabTemplate<TRecord>
      resizable
      dataSource={data}
      metadata={metadata}
      columns={getColumnsTable({
        sorter: orderByProps,
        convertors,
        t,
        previewMode,
        onRowClick
      })}
      loading={isLoading}
      locale={{
        teasers: {
          dataReadiness: true,
          scmInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t)
        }
      }}
      SearchComponent={
        !previewMode && (
          <Filter.Provider keys={filters}>
            <Filter.Template
              isLoading={filterDisabled}
              search={
                filterDisabled ? (
                  <InputSmartSearchLoading />
                ) : (
                  <SmartSearch
                    id="smart-search-code-repo-tab-inventory"
                    filters={filters}
                  />
                )
              }
              // aside={
              //   <ExportButton isLoading={filterDisabled} exportType="Branch" />
              // }
            >
              <Filter.Insights isLoading={filterDisabled} filters={filters} />
              <Filter.Checkbox
                isLoading={isLoading}
                order="none"
                keyFilter="severity"
                items={SEVERITIES_FILTER_OPTIONS}
                placeHolderDefaultValue="Severity"
              />
            </Filter.Template>
          </Filter.Provider>
        )
      }
      idsInDrawers={idsInDrawers}
      onRowClick={onRowClick}
      disableDrawer={previewMode}
      asideFooter={
        showArchivedData &&
        !!archivedRepoResponse?.data?.archived && (
          <div className={styles.messageWrapper}>
            <InformationPopover.Popover
              content={
                <InformationPopover.CommonTemplate>
                  {tAsideFooter("popUpContent")}
                </InformationPopover.CommonTemplate>
              }
              placement={"topRight"}
            />
            <span>
              {archivedRepoResponse?.data.archived} {tAsideFooter("content")}
            </span>
          </div>
        )
      }
      onChange={(_pagination, _filters, sorter) => {
        onSorterChange(sorter)
      }}
    />
  )
}
