import { Paragraph, Text } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import cn from "classnames"

import styles from "./Description.module.scss"

import type { DescriptionProps } from "."

export const Description = ({
  children,
  label,
  copyable,
  copyableText,
  copyableEvent,
  ellipsis,
  width,
  classes = {}
}: DescriptionProps) => {
  const { logger } = useLogger()
  const copyableConfig = copyable
    ? {
        text: copyableText,
        onCopy: () => {
          if (copyableEvent) {
            logger.info(copyableEvent, { children })
          }
        }
      }
    : undefined

  return (
    <Paragraph className={cn(styles.label, classes.label)}>
      {label}
      {!!children && (
        <Text
          className={cn(styles.value, classes.value)}
          copyable={copyableConfig}
          ellipsis={ellipsis || !!width}
          style={{ maxWidth: width }}
        >
          {children}
        </Text>
      )}
    </Paragraph>
  )
}
