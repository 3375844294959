import { STATUSES } from "@/domain/alerts"

export const TABS_PAGE_CONTAINER_ID = "PageWithTabsTemplate"

export const TAB_KEYS_BY_PAGE = {
  INVENTORY: ["branch", "applications", "images", "baseImages"],
  SBOM: ["runtime", "all"],
  ALERT: [STATUSES.OPEN, STATUSES.RESOLVED, STATUSES.IGNORED],
  SETTINGS: [
    "users",
    "scopes",
    "auth",
    "scm",
    "apikeys",
    "deploymentkeys",
    "servicekeys",
    "ci",
    "codeScanSast",
    "labels"
  ],
  INTEGRATION: ["connect", "configuration"]
}
