import { CellTable } from "@/atoms"
import { ISSUES_LIMIT } from "@/const/table-constants"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { CellTableCustomTooltip } from "@/molecules/CellTableCustomTooltip"
import { HeaderCell } from "@/molecules/HeaderCell"
import { ImpactCell } from "@/molecules/ImpactCell"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { TriageActionCell } from "@/molecules/TriageActionCell"
import { ProjectsTooltipContent } from "../../molecules/InfoHeader"
import { InsightsCell } from "../../molecules/InsightsCell"
import { ResourceCell } from "../../molecules/ResourceCell"

import type { SortOrder } from "@/atoms"
import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { NumberConvertor } from "@/hooks/useNumberConvertor"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { TFunction } from "@/hooks/useTranslation"

export const getColumnsTable = (props: {
  t: TFunction
  convertors: NumberConvertor
  orderByProps: Omit<ORDER_BY_PROPS, "onSorterChange">
  onRowClick: RowClick
  internetFacingFF: boolean
}) => {
  const {
    t,
    convertors,
    onRowClick,
    orderByProps: sorter,
    internetFacingFF
  } = props

  return [
    {
      title: HeaderCell({ title: "Projects", Content: ProjectsTooltipContent }),
      width: "100%",
      render: ResourceCell(onRowClick),
      ...getDefaultColumnParams("name")
    },
    {
      title: "Score",
      width: 80,
      className: "center-align-tab-template-component",
      render: CellTableCustomTooltip(
        "This is the highest issue score on this project"
      ),
      ...getDefaultColumnParams("kodemScore", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Insights",
      width: 150,
      render: InsightsCell(t, internetFacingFF),
      ...getDefaultColumnParams("riskInsights")
    },
    {
      title: "Issues Summary",
      width: 244,
      render: IssuesSummaryCell({ t, convertors }, ISSUES_LIMIT),
      ...getDefaultColumnParams("severity", sorter),
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Impact",
      width: 100,
      className: "center-align-tab-template-component",
      render: ImpactCell(t, onRowClick),
      ...getDefaultColumnParams("applicationCount", sorter),
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Actions",
      width: 84,
      className: "center-align-tab-template-component",
      render: TriageActionCell
    },
    {
      title: "Discovered",
      width: 125,
      render: CellTable,
      ...getDefaultColumnParams("discoveredDaysAgo")
    }
  ]
}
