import { useParams } from "react-router-dom"

import { Link } from "@/atoms"
import { useServices } from "@/hooks/useServices"
import {
  AlertOutlined,
  DashboardOutlined,
  SortAscendingOutlined
} from "@/icons"
import { LabelPrimaryNavigation } from "@/molecules/LabelPrimaryNavigation"
import { MenuNavigationTemplate } from "@/templates/MenuNavigationTemplate"
import { TitleSideNavigation as Title } from "./TitleSideNavigation"

import type { NavigationComponent } from ".."

export const FeedSideNavigation: NavigationComponent = (props) => {
  const { collapsed, t } = props
  const { incident } = useParams()
  const { auth } = useServices()
  const getItems = () => [
    {
      label: <Link to="/">{t("navigation.dashboard")}</Link>,
      key: "/",
      icon: <DashboardOutlined />,
      "data-testid": "dashboard"
    },
    {
      label: (
        <Link to="/triage?issueStatus=open&runtime=YES">
          {t("navigation.triage")}
        </Link>
      ),
      key: "triage",
      icon: <SortAscendingOutlined />,
      "data-testid": "triage"
    }
  ]

  const getDemoItems = () => [
    ...getItems(),
    {
      label: (
        <Link to="/incidents">
          <LabelPrimaryNavigation
            label={t("navigation.incidents")}
            count={incident ? 1 : 0}
          />
        </Link>
      ),
      key: "incidents",
      icon: <AlertOutlined />,
      "data-testid": "incidents"
    }
  ]

  const menuItems = auth.isDemo() ? getDemoItems() : getItems()

  return (
    <>
      <Title collapsed={collapsed}>{t("navigation.feed")}</Title>
      <MenuNavigationTemplate items={menuItems} />
    </>
  )
}
