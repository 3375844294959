import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  Config,
  ConfigInfinityQuery,
  Params,
  useCreate,
  useHttp,
  useLoadMore,
  useUpdate
} from "@/hooks/useHttp"

import type { Issue } from "@/domain/issue"
import type {
  FindingsTriageResponse,
  FixesTriageResponse,
  IssuesTriageResponse,
  ProjectsTriageResponse
} from "./useTriage.types"

import type { GetUseMutationOptions } from "@/hooks/useHttp.types"

export const useProjectsTriage = <T extends ProjectsTriageResponse>(
  params?: Params<Record<string, unknown>>,
  config?: Config<T>
) => {
  const context = useHttp<T>(RESOURCES_NAMES.TRIAGE.PROJECTS, params, config)

  return context
}

export const useLoadMoreIssuesProjectTriage = <T = Issue>(
  params: Params<{ needle: string; pageNumber?: number | string }>,
  config: ConfigInfinityQuery<T>
) => {
  const { needle = "", pageNumber, ...restParams } = params

  const context = useLoadMore<T>(
    `${RESOURCES_NAMES.TRIAGE.PROJECTS}${needle}`,
    restParams,
    config
  )

  return context
}

export const useIssuesProjectTriage = <T extends IssuesTriageResponse>(
  params: Params<{ needle: string; pageNumber?: number | string }>,
  config?: Config<T>
) => {
  const { needle = "", ...restParams } = params

  const context = useHttp(
    `${RESOURCES_NAMES.TRIAGE.PROJECTS}${needle}`,
    restParams,
    config
  )

  return context
}

export const useIssuesByResourceTriage = <T extends IssuesTriageResponse>(
  params: Params<{
    resourceId?: string
    pageNumber?: number | string
    issueType?: string
  }>,
  config?: Config<T>
) => {
  const context = useHttp(`${RESOURCES_NAMES.TRIAGE.ISSUES}`, params, config)

  return context
}

export const useFiltersTriage = <
  T = KosmosApi.Response<Record<string, string[]>>
>(
  config: Config<T>
) => {
  const context = useHttp<T>(RESOURCES_NAMES.TRIAGE.FILTERS, undefined, config)

  return context
}

export const useIssueFindings = <T extends FindingsTriageResponse, S = T>(
  params: Params<{ needle: string }>,
  config: Config<T, S>
) => {
  const { needle, ...restParams } = params

  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.TRIAGE.ISSUES}/${needle}/findings`,
    restParams,
    config
  )
  return context
}

export const useDismissIssues = <T, S>(
  params: Record<string, unknown>,
  options?: GetUseMutationOptions<T, S>
) => {
  const context = useUpdate(
    RESOURCES_NAMES.TRIAGE.PROJECTS,
    params,
    undefined,
    options
  )
  return context
}

export const useCreateTicketIssue = () => {
  const context = useCreate<
    { data: Jira.CreateIssuePayload; needle?: string },
    Jira.CreateIssuePayload
  >(`${RESOURCES_NAMES.TRIAGE.ISSUES}`, {}, (oldData, _) => oldData)

  return context
}

export const useCalculateFixes = <T = FixesTriageResponse, S = unknown>() => {
  const context = useCreate<T, S>(`${RESOURCES_NAMES.TRIAGE.ISSUES}/fix`)

  return context
}

export const useIssue = <T = KosmosApi.Response<Issue>, S = T>(
  id: undefined | string,
  params?: Params,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.TRIAGE.ISSUES}/${id}`,
    params,
    { enabled: !!id, ...config }
  )

  return context
}
