export const getDefaultValues = (initialValues?: { environment?: string }) => {
  const { environment = "environment" } = initialValues || {}

  return {
    namespace: "kodem",
    environment: environment,
    deploymentKey: "deployment_key",
    pullSecretName: "kortex-regcred",
    key: Date.now().toString()
  }
}

export const getTextForValue = (value: string, defaultValue: string) =>
  value === defaultValue ? `[${value}]` : value
