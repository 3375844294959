import { Card, Text } from "@/atoms"
import { getSuggestedFixVersion, packageHasFix } from "@/domain/package"
import { useTranslation } from "@/hooks/useTranslation"
import { UltimateFix } from "@/icons"
import cn from "classnames"
import { RemediateButton } from "../RemediateButton"

import styles from "./FixCard.module.scss"

import type { FixCardProps } from "."

export const FixCard = ({ isDemoUser, data, classes = {} }: FixCardProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "sbom.drawer" })

  if (!data || !packageHasFix(data)) {
    return null
  }

  const FixIcon = UltimateFix
  const titleTranslationKey = "ultimateFix"
  const { currentVersion, suggestedVersion } = getSuggestedFixVersion(data)

  return (
    <Card
      className={cn(styles.card, classes.card)}
      title={
        <div className={styles.header}>
          <div>
            <FixIcon className={styles.icon} />
            <Text className={styles.title} data-testid={titleTranslationKey}>
              {t(titleTranslationKey)}
            </Text>
          </div>
          {isDemoUser && <RemediateButton />}
        </div>
      }
    >
      <div className={styles.description}>
        <Text className={styles.label}>{`${t("updateVersion")}:`}</Text>
        <Text>
          {currentVersion} → {suggestedVersion}
        </Text>
      </div>
    </Card>
  )
}
