import styles from "./InfoHeader.module.scss"

export const ProjectsTooltipContent = () => (
  <div>
    A project is a group of resources, which may include a Code Repository and
    Images. The resources are related in one of the following ways:
    <ol className={styles.infoList}>
      <li>Code Repository and images that are built from it</li>
      <li>Image Repository (if no related Code Repository was found)</li>
      <li>Code Repository (if no related images were found)</li>
    </ol>
  </div>
)
