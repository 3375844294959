import { allSeverities } from "@/domain/vulnerability/vulnerability"
import {
  Severity,
  VulnerabilitiesBySeverity,
  Vulnerability
} from "@/domain/vulnerability/vulnerability.types"
import { isEmpty } from "@/helpers/utils"

interface VulnerabilityFromServer
  extends Partial<Omit<Vulnerability, "severity">> {
  id?: string
  fixVersions?: string[]
  severity?: string
}

export const formatSeverity = (severity: string | Severity = ""): Severity => {
  const lower = severity.toLowerCase() as Severity
  if (allSeverities.includes(lower)) return lower
  return "unknown"
}

const formatVulnerability = (
  vulnerability: VulnerabilityFromServer = {}
): Vulnerability | null => {
  if (isEmpty(vulnerability)) return null
  const {
    id,
    cve,
    fix,
    fixVersions,
    severity,
    cvss,
    exploitMaturity,
    ...rest
  } = vulnerability
  return {
    cve: cve || id || "",
    severity: formatSeverity(severity || ""),
    fix: fix || fixVersions,
    cvss: cvss || 0,
    exploitMaturity: exploitMaturity,
    ...rest
  }
}

export const formatVulnerabilities = (
  vulnerabilities?: VulnerabilityFromServer[] | null
): Vulnerability[] => {
  if (!vulnerabilities) return []
  return vulnerabilities
    ?.map(formatVulnerability)
    .filter((v) => v) as Vulnerability[]
}

export const formatVulnerabilitiesBySeverity = (
  vulnerabilities: Record<string, VulnerabilityFromServer> = {}
): VulnerabilitiesBySeverity => {
  if (!vulnerabilities) return {}

  const vulnerabilitiesKeys = Object.keys(vulnerabilities)

  return vulnerabilitiesKeys.reduce((acc, key) => {
    const formattedVulnerability = formatVulnerability(vulnerabilities[key])
    if (!formattedVulnerability) return acc
    const { severity } = formattedVulnerability

    if (!acc[severity]) {
      acc[severity] = []
    }

    acc[severity]!.push(formattedVulnerability)

    return acc
  }, {} as VulnerabilitiesBySeverity)
}
