import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import {
  AppstoreOutlined,
  BugOutlined,
  CodeIssues,
  OpenSourceIssue,
  UnorderedListOutlined
} from "@/icons"
import { LabelTabItem } from "@/molecules/LabelTabItem"

import type { LabelTabProps } from "."

const IconComponents: Record<LabelTabProps["name"], React.FC> = {
  sbom: UnorderedListOutlined,
  issues: BugOutlined,
  scaIssues: OpenSourceIssue,
  overview: AppstoreOutlined,
  sastIssues: CodeIssues
}

export const LabelTab: React.FC<LabelTabProps> = ({ name, count }) => {
  const { abbreviateNumber, t } = useNumberConvertor()
  const commonProps = {
    count: abbreviateNumber(count || 0),
    title: t(`general.${name}`),
    overflowCount: 999
  }
  const Component = LabelTabItem
  const Icon = IconComponents[name]

  return <Component icon={Icon && <Icon />} {...commonProps} />
}
