import List from "rc-virtual-list"
import { useRef } from "react"

import { Checkbox } from "@/atoms"
import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { Table } from "@/icons"
import { ItemListTemplate } from "../../templates/ItemListTemplate"
import { DescriptionItem } from "../DescriptionItem"

export const ValueList = (props: any) => {
  const { logger, services, createHandler } = props
  const { FilterService, StringService } = services
  const currentToken = StringService.getTokenByCaretPos()
  const filter = FilterService.getFilterByLabel(currentToken?.id)
  const values: string[] = (currentToken?.value || "").split(",")
  const lastValue = values[values.length - 1]
  let { type, options = [], order } = filter || {}
  const descriptionItemClassName =
    "item-smart-search-ks value-item-smart-search-ks"

  const optionsCurrent = useRef(null)
  let optionsByCurrentValue = optionsCurrent.current || options

  if (!!lastValue) {
    let searchValue = lastValue.toLocaleLowerCase().replace(/"$/, "")

    if (
      searchValue.length === 2 &&
      searchValue[0] === '"' &&
      searchValue[1] === '"'
    ) {
      searchValue = ""
    }

    optionsByCurrentValue = optionsByCurrentValue.filter(({ label }: any) => {
      return label.toLocaleLowerCase().includes(searchValue)
    })
  }

  if (type === "single") {
    return (
      <ItemListTemplate title="values" Icon={Table}>
        {optionsByCurrentValue.map(({ label, value, disabled }: any) => {
          if (disabled) return null

          const handler = (e: MouseEvent | KeyboardEvent) => {
            createHandler(() => {
              logger?.info(
                ANALYTIC_EVENTS.TAB.FILTER.ADVANCE_SEARCH.CLICK.VALUE,
                {
                  value
                }
              )

              return label
            })(e)
          }

          return (
            <DescriptionItem
              className={descriptionItemClassName}
              key={value}
              handler={handler}
            >
              {label}
            </DescriptionItem>
          )
        })}
      </ItemListTemplate>
    )
  }

  if (type === "multiple") {
    const { valueClickedLastTime } = props
    const valuesMap = values.reduce(
      (acc, value) => {
        if (!value) return acc

        acc[value] = true

        return acc
      },
      {} as Record<string, boolean>
    )

    if (!optionsCurrent.current) {
      optionsCurrent.current =
        order === "none"
          ? options
          : options.sort((a: any, b: any) => {
              if (valuesMap[a.label] && valuesMap[b.label])
                return a.label.localeCompare(b.label)

              if (valuesMap[a.label]) return -1
              if (valuesMap[b.label]) return 1

              return a.label.localeCompare(b.label)
            })
    }
    const itemHeight = 38 // .item-smart-search-ks height (line-height + padding)
    const maxHeightList = 418
    const lengthList = optionsByCurrentValue?.length || 0
    const itemsHeight = lengthList * itemHeight
    const heightList = itemsHeight > maxHeightList ? maxHeightList : itemsHeight

    return (
      <ItemListTemplate title="values" Icon={Table}>
        <List
          itemHeight={itemHeight}
          height={heightList}
          data={optionsByCurrentValue}
          itemKey={(item: any) => item.value}
        >
          {({ label, value, displayLabel = label }) => {
            const handler = (e: any) => {
              createHandler(() => {
                valueClickedLastTime.current = label
                valuesMap[label] = !valuesMap[label]

                const currentTokenValue = currentToken?.value
                const commaIsLast =
                  !currentTokenValue ||
                  currentTokenValue[currentTokenValue.length] === ","

                if (!commaIsLast) {
                  valuesMap[values[values.length - 1]] = false
                }

                logger?.info(
                  ANALYTIC_EVENTS.TAB.FILTER.ADVANCE_SEARCH.CLICK.VALUE,
                  {
                    label,
                    value: valuesMap[label]
                  }
                )

                return Object.keys(valuesMap).reduce((acc, key) => {
                  if (valuesMap[key]) {
                    acc.push(key)
                  }

                  return acc
                }, [] as string[])
              })(e)
            }

            return (
              <li
                className={descriptionItemClassName}
                key={value}
                tabIndex={label === valueClickedLastTime.current ? 2 : 1}
                onClick={handler}
                onKeyDown={handler}
              >
                <Checkbox checked={valuesMap[label]}>{displayLabel}</Checkbox>
              </li>
            )
          }}
        </List>
      </ItemListTemplate>
    )
  }

  return null
}
