import { Card, Col, Row, Text } from "@/atoms"
import { getLinkByVulnerability } from "@/domain/vulnerability"
import { useLogger } from "@/hooks/useLogger"
import cn from "classnames"
import { ExploitabilityTag } from "../../../../../molecules/ExploitabilityTag"
import styles from "./VulnerabilityCard.module.scss"

import type { VulnerabilityCardProps } from "."

export const VulnerabilityCard = ({ data }: VulnerabilityCardProps) => {
  const { cvss, description, fix } = data
  const hasFix = fix?.length
  const fixSuggestion = hasFix ? fix.join(", ") : "-"
  const { logger, EVENTS } = useLogger()

  return (
    <Card className={styles.card}>
      <Row className={styles.firstRow}>
        <Col className={styles.col} span={8}>
          <ExploitabilityTag maturity={data.exploitMaturity} />
          <a
            target="_blank"
            rel="noreferrer"
            href={getLinkByVulnerability(data)}
            onClick={() =>
              logger.info(
                EVENTS.ANALYTIC_EVENTS.SBOM.DRAWER.VULNERABILITIES
                  .CLICKED_CVE_LINK,
                {
                  cardId: data.cve,
                  cvss: data.cvss,
                  description: data.description
                }
              )
            }
          >
            {data.cve}
          </a>
        </Col>
        <Col className={styles.col} span={8}>
          <Text type="secondary">CVSS</Text>
          <Text>{cvss}</Text>
        </Col>
        <Col className={styles.col} span={8}>
          <Text type="secondary">Fix</Text>
          <Text className={cn(hasFix && styles.fix)}>{fixSuggestion}</Text>
        </Col>
      </Row>
      <Row className={styles.descriptionRow}>
        <Col span={24}>{description}</Col>
      </Row>
    </Card>
  )
}
