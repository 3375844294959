import { splitCommand } from "@/domain/package"

import type {
  PackageImageInformationResponse,
  PackageVulnerabilitiesResponse
} from "@/api/useSbom.types"
import { findHighestExploitMaturities } from "@/domain/vulnerability"
import { formatVulnerabilitiesBySeverity } from "@/domain/vulnerability/vulnerability.formatters"
import { toDictionary } from "@/helpers/utils"

export const getPackageImageInformationData = (
  response: PackageImageInformationResponse
) => {
  const { data } = response || {}
  const { imageDetails } = data || {}
  return {
    imagesDetails: imageDetails.map(
      ({ historyEntries = [], specDeclarations, ...details }) => ({
        specDeclarations: specDeclarations.map(
          ({ paths, repoName, ...repo }) => ({
            repoName,
            paths: paths.map(({ filePath, ...props }) => {
              const relativeFilePath = filePath.replace(repoName, "")
              try {
                return {
                  filePath,
                  relativeFilePath: relativeFilePath.startsWith("/")
                    ? relativeFilePath.substring(1)
                    : relativeFilePath,
                  ...props
                }
              } catch {
                return {
                  filePath,
                  relativeFilePath: filePath,
                  ...props
                }
              }
            }),
            ...repo
          })
        ),
        historyEntries: historyEntries.map(
          ({ createdBy, ...attr }: { createdBy: string }) => ({
            createdBy,
            ...attr,
            ...splitCommand(createdBy)
          })
        ),
        ...details
      })
    )
  }
}

export const getPackageVulnerabilitiesData = (
  response: PackageVulnerabilitiesResponse
) => {
  const { data: vulnerabilities } = response || {}
  const highestExploitMaturities = findHighestExploitMaturities(vulnerabilities)
  vulnerabilities?.sort((a, b) => (b.cvss as number) - (a.cvss as number))
  return {
    highestExploitMaturities: highestExploitMaturities,
    vulnerabilities: formatVulnerabilitiesBySeverity(
      toDictionary(vulnerabilities, "id")
    )
  }
}
