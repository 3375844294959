import { Language } from "@/icons"
import {
  DescriptionVulnerableCodeIssue,
  IssueDetails
} from "@/molecules/IssueDetails"
import { LinkRisk } from "@/molecules/LinkRisk"

import styles from "./IssueTitleCell.module.scss"

import type { Issue } from "@/domain/issue"
import type { TFunction } from "@/hooks/useTranslation"

export const IssueTitleCell =
  (t: TFunction, setRecord?: (issue: Issue) => void) =>
  (_: string, props: Issue) => {
    const {
      id,
      severity,
      riskId,
      packageName,
      language,
      cwe,
      status,
      dismissedBy,
      dismissedAt,
      dismissReason,
      issueType,
      filePath
    } = props
    const isVulnerableCode = issueType === "Vulnerable_Code"
    const isCweExists = cwe && cwe.description

    return (
      <IssueDetails
        issueId={id}
        severity={severity}
        issueType={issueType}
        t={t}
        title={
          isCweExists ? (
            cwe.description
          ) : (
            <LinkRisk cve={riskId} classes={{ text: styles.description }} />
          )
        }
        description={
          isVulnerableCode ? (
            <DescriptionVulnerableCodeIssue
              language={language}
              filePath={filePath || ""}
            />
          ) : (
            <>
              {isCweExists && (
                <LinkRisk classes={{ text: styles.details }} cve={riskId} />
              )}
              {isCweExists && " on "}
              <Language
                secondary
                className={styles.languageIcon}
                withBg={false}
                language={language}
              />
              <span>{packageName}</span>
            </>
          )
        }
        status={status}
        dismissedBy={dismissedBy}
        dismissedAt={dismissedAt}
        dismissReason={dismissReason}
        labels={props.labels}
        onAddLabel={() => setRecord?.(props)}
      />
    )
  }
