import { Popover, Text } from "@/atoms"
import {
  useLinkDrawerTooltip,
  type RowClick
} from "@/hooks/useLinkDrawerTooltip"
import { Image, SourceControlIcon } from "@/icons"
import { IconTextCell } from "@/molecules/IconTextCell"
import { CODE_REPOSITORY_DRAWER_ID } from "@/organisms/Drawers/const"
import { Resource } from "./Resource"

import styles from "./ResourceCell.module.scss"

import type { ResourceCellProps } from "."

export const ResourceWithPopover = (
  props: ResourceCellProps & { valueCell: string; onRowClick: RowClick }
) => {
  const { valueCell, resources, sourceControl, relatedImagesCount } = props
  const isRepoResource = !!sourceControl
  const showRelatedImages = isRepoResource && !!relatedImagesCount
  const { open, openDrawer } = useLinkDrawerTooltip(props)

  return (
    <div className={styles.container}>
      <Popover
        open={open}
        className={styles.labelWrapper}
        rootClassName={styles.root}
        content={
          <div className={styles.tooltipWrapper}>
            {resources.map((resource, index) => {
              const isCodeRepo = resource.type === "CodeRepository"

              if (!isCodeRepo) {
                return (
                  <Text key={index}>
                    <Resource key={index} {...resource} />
                  </Text>
                )
              }

              return (
                <Text
                  underline
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    openDrawer?.(CODE_REPOSITORY_DRAWER_ID, resource.id)
                  }}
                >
                  <Resource key={index} {...resource} />
                </Text>
              )
            })}
          </div>
        }
      >
        <IconTextCell
          noTooltip
          icon={
            isRepoResource ? (
              <SourceControlIcon sourceControl={sourceControl} />
            ) : (
              <Image />
            )
          }
          text={valueCell}
        />
        {showRelatedImages && (
          <IconTextCell
            noTooltip
            icon={<Image />}
            text={`+${relatedImagesCount} related images`}
            classes={{ container: styles.relatedResourceContainer }}
          />
        )}
      </Popover>
    </div>
  )
}
