import {
  SLUG_LS,
  SPLASH_SCREEN_ON_BOARDING
} from "@/const/local-storage.constants"
import { createUser, User } from "@/domain/user"
import type { Services } from "@/interfaces/services"
import { FirebaseOptions, initializeApp } from "@firebase/app"
import {
  Auth,
  confirmPasswordReset,
  getAuth,
  getMultiFactorResolver,
  GithubAuthProvider,
  GoogleAuthProvider,
  multiFactor,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  SAMLAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  User as FirebaseUser
} from "@firebase/auth"

export type InitialParamsAuthAdapter = {
  slug?: string
} & FirebaseOptions

export class AuthAdapter implements Services.IAuthAdapter {
  static defaultInstance: AuthAdapter

  private app: Auth

  private recaptchaVerifier: RecaptchaVerifier

  private resolver: MultiFactorResolver | undefined

  private currentUser: User

  private company: string

  private resolveToken: ((value: string | undefined) => void) | null = null

  private token: Promise<string | undefined>

  private subscribers: Record<string, Array<(data: any) => void>> = {}

  private multiFactorAuthEnabled = false

  public AUTH_STATE_CHANGED_EVENT = "authStateChanged"

  public AUTH_MULTI_FACTOR_EVENT = "authMultiFactor"

  constructor({ slug, ...options }: InitialParamsAuthAdapter) {
    this.app = getAuth(initializeApp(options))
    this.recaptchaVerifier = new RecaptchaVerifier(
      this.app,
      "recaptcha-container",
      { size: "invisible" }
    )
    this.company = slug || ""
    this.currentUser = createUser({
      company: this.company,
      authorized: !!slug
    })

    this.token = new Promise((resolvePromise) => {
      this.resolveToken = resolvePromise
    })

    this.app.onAuthStateChanged(this.onAuthStateChanged)
  }

  private sendVerificationCode = async (phoneInfoOptions: PhoneInfoOptions) => {
    if (this.app.currentUser) {
      const phoneAuthProvider = new PhoneAuthProvider(this.app)

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        this.recaptchaVerifier
      )

      return verificationId
    }
  }

  public sendCodePhoneNumberEnrolling = async (phoneNumber: string) => {
    if (!this.app.currentUser || !phoneNumber) {
      return null
    }

    const multiFactorSession = await multiFactor(
      this.app.currentUser
    ).getSession()

    return await this.sendVerificationCode({
      phoneNumber,
      session: multiFactorSession
    })
  }

  sendVerificationCodeOnPhoneNumber = async () => {
    if (!this.resolver) {
      return null
    }

    const phoneInfoOptions = {
      multiFactorHint: this.resolver.hints[0],
      session: this.resolver.session
    }
    const phoneAuthProvider = new PhoneAuthProvider(this.app)

    return await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      this.recaptchaVerifier
    )
  }

  private onAuthStateChanged = async (user: FirebaseUser | null) => {
    this.setCurrentUser(user)
    this.publish(this.AUTH_STATE_CHANGED_EVENT, this.getCurrentUser())

    const token = await user?.getIdToken()

    if (this.resolveToken) {
      this.resolveToken(token)
      this.resolveToken = null
    } else {
      this.token = Promise.resolve(token)
    }
  }

  private publish(eventName: string, data: unknown) {
    if (!Array.isArray(this.subscribers[eventName])) {
      return
    }

    this.subscribers[eventName].forEach((callback) => {
      callback(data)
    })
  }

  private setCurrentUser(fbUser = this.app.currentUser) {
    if (!fbUser) {
      this.currentUser = createUser({ authorized: false })

      const splashScreen = localStorage.getItem(SPLASH_SCREEN_ON_BOARDING)

      localStorage.clear()

      if (!!splashScreen)
        localStorage.setItem(SPLASH_SCREEN_ON_BOARDING, splashScreen)

      return
    }

    const { email, photoURL } = fbUser

    this.currentUser = {
      ...this.currentUser,
      company: this.company,
      email: email || "",
      photoURL: photoURL || "",
      authorized: true,
      userNeedVerificationCodeMultiFactor: false
    }
  }

  public subscribe(eventName: string, callback: (data: any) => void) {
    if (!this.subscribers[eventName]) {
      this.subscribers[eventName] = []
    }

    this.subscribers[eventName].push(callback)

    return () => {
      this.subscribers[eventName] = this.subscribers[eventName].filter((cb) => {
        if (cb === callback) {
          return false
        }

        return true
      })
    }
  }

  public setCompany = (company: string) => {
    this.company = company
  }

  public setMultiFactorAuth = (multiFactorAuthEnabled: boolean) => {
    this.multiFactorAuthEnabled = multiFactorAuthEnabled
  }

  public setTenantId = (tenantId: string | null) => {
    this.app.tenantId = tenantId
  }

  public getCurrentUser = () => this.currentUser

  public getCompany = () => this.company

  public getRecaptcha = () => this.recaptchaVerifier

  public verificationCode = async (
    verificationId: string,
    verificationCode: string,
    enrolling?: boolean
  ) => {
    const errorMessage = "verificationCode-auth-adapter: INVALID-PARAMS"

    if (!verificationId || !verificationCode) {
      throw new Error(errorMessage)
    }

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    if (enrolling) {
      if (!this.app.currentUser) {
        throw new Error(errorMessage)
      }

      return await multiFactor(this.app.currentUser).enroll(
        multiFactorAssertion
      )
    } else {
      if (!this.resolver) {
        return null
      }

      await this.resolver.resolveSignIn(multiFactorAssertion)

      localStorage.setItem(SLUG_LS, this.company)

      this.clearCaptcha()
    }
  }

  onSignIn: any = async () => {
    try {
      if (this.multiFactorAuthEnabled) {
        this.publish(this.AUTH_MULTI_FACTOR_EVENT, {
          userNeedVerifyPhoneNumberMultiFactor: true
        })
      }

      this.setCurrentUser()

      const user = this.getCurrentUser()

      if (user.authorized && this.company) {
        localStorage.setItem(SLUG_LS, this.company)
      }

      return this.currentUser
    } catch (err) {
      return this.currentUser
    }
  }

  public skipMultiFactorSetUp = () => {
    this.publish(this.AUTH_MULTI_FACTOR_EVENT, {
      userNeedVerifyPhoneNumberMultiFactor: false
    })
  }

  getToken = async () => {
    try {
      if (this.resolveToken) {
        await this.token
      }

      const token = await this.app.currentUser?.getIdToken()

      return token
    } catch {
      return undefined
    }
  }

  private clearCaptcha = () => {
    try {
      this.getRecaptcha().clear()
    } catch (error) {
      return null
    }
  }

  private checkSignInError = (error: any) => {
    if (error?.code === "auth/multi-factor-auth-required") {
      this.publish(this.AUTH_MULTI_FACTOR_EVENT, {
        userNeedVerificationCodeMultiFactor: true
      })
      this.resolver = getMultiFactorResolver(this.app, error)

      return
    }

    if (error?.code === "auth/invalid-login-credentials") {
      return { error: true, translationKey: "invalid-login-credentials" }
    }

    throw error
  }

  signInWithSaml = async (firebaseProviderId = "saml.google-workspace") => {
    try {
      const provider = new SAMLAuthProvider(firebaseProviderId)

      await signInWithPopup(this.app, provider)

      return this.onSignIn()
    } catch (error) {
      return this.checkSignInError(error)
    }
  }

  signInWithToken = async (token: string) => {
    try {
      await signInWithCustomToken(this.app, token)

      return this.onSignIn()
    } catch (error) {
      return this.checkSignInError(error)
    }
  }

  signInWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider()

    googleProvider.addScope("profile")
    googleProvider.addScope("email")

    await signInWithPopup(this.app, googleProvider)

    return this.onSignIn()
  }

  signInWithGithub = async () => {
    const githubProvider = new GithubAuthProvider()

    githubProvider.addScope("profile")
    githubProvider.addScope("email")

    await signInWithPopup(this.app, githubProvider)

    return this.onSignIn()
  }

  signInWithEmailPass = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(this.app, email, password)

      return this.onSignIn()
    } catch (error) {
      return this.checkSignInError(error)
    }
  }

  signOut = async () => {
    await this.app.signOut()
    this.company = ""
    this.currentUser = createUser({ authorized: false })
  }

  handleResetPassword = async (code: string, newPassword: string) => {
    try {
      await confirmPasswordReset(this.app, code, newPassword)

      return [true, undefined]
    } catch (err) {
      return [false, err as any]
    }
  }

  isDemo = () => {
    return this.company === "kodemsecurity"
  }
}
