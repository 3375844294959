import { NAMESPACE_DEFAULT_VALUE } from "@/const/default-values"
import { capitalizeFirstLetter } from "@/helpers/string.helpers"
import dayjs from "dayjs"

import type { ApplicationResponse } from "@/api/useInventory.types"
import { getIssuesCountMapOrderedSeverity } from "@/domain/vulnerability"

export const getApplicationTabData = (response: ApplicationResponse) => {
  const { data, metadata } = response || {}

  return {
    metadata,
    data: data.map(({ data: dataRecord, id, displayName }, index) => {
      const { issuesSummary } = dataRecord
      const operatingSystems = (dataRecord.operatingSystems || []).map(
        capitalizeFirstLetter
      )
      const issuesCounts = getIssuesCountMapOrderedSeverity(issuesSummary)
      return {
        key: id,
        displayName,
        sbomQuery: `isVulnerable=Yes&service=${dataRecord.resourceIds.join(
          ","
        )}`,
        ...dataRecord,
        namespace: dataRecord.namespace || NAMESPACE_DEFAULT_VALUE,
        operatingSystems: {
          id: `${id}_${index}`,
          items: operatingSystems,
          label: operatingSystems[0] || "",
          count: operatingSystems.length - 1
        },
        lastSeen: dayjs(dataRecord.startedAt).format("YYYY-MM-DD HH:mm"),
        severity: issuesCounts
      }
    })
  }
}
