import cn from "classnames"

import { BetaLabel, Col, Text } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { InformationPopover } from "@/molecules/InformationPopover"
import { CollapseDrawer } from "@/organisms/Drawers/components/CollapseDrawer"
import { List } from "@/pages/InventoryPage/components/molecules/List"

import type { RuntimeEvidenceProps } from "./RuntimeEvidence.types"

import styles from "./RuntimeEvidence.module.scss"

export const RuntimeEvidence = (props: RuntimeEvidenceProps) => {
  const { t } = useTranslation()
  const { t: tRuntimeEvidence } = useTranslation("translation", {
    keyPrefix: "runtimeEvidence"
  })
  const { logger, EVENTS } = useLogger()
  const { record, isCard } = props

  if (!record || !record.riskInsights) {
    return null
  }

  const { riskInsights, issueType, language } = record
  const { runtime } = riskInsights

  if (issueType !== "Vulnerable_Package") return null

  const title = runtime ? t("general.inRuntime") : t("general.notInRuntime")
  const titleClassName = runtime ? "ks-color-text" : ""
  const { type: typeRuntimeEvidence, functions } =
    riskInsights.runtimeEvidence || {}
  const countFunctions = functions?.length || 0
  const noFunctions = countFunctions === 0
  const displayFunction = !noFunctions

  const isPackageLoaded =
    typeRuntimeEvidence === "package_loaded" && noFunctions
  const displayFunctionKey = runtime
    ? "runtimeFunctionEvidence"
    : "notInRuntimeFunctionEvidence"

  const isJVM = language === "java" || language === "scala"

  const runtimeAndNoLinkedVulnerableFunctions = runtime && noFunctions
  const runtimeAndNoLinkedVulnerableFunctionsAndJVM =
    runtimeAndNoLinkedVulnerableFunctions && isJVM
  const runtimeAndNoLinkedVulnerableFunctionsAndNotJVM =
    runtimeAndNoLinkedVulnerableFunctions && !isJVM

  const notRuntimeAndNoLinkedVulnerableFunctions = !runtime && noFunctions
  const notRuntimeAndNoLinkedVulnerableFunctionsAndJVM =
    notRuntimeAndNoLinkedVulnerableFunctions && isJVM
  const notRuntimeAndNoLinkedVulnerableFunctionsAndNotJVM =
    notRuntimeAndNoLinkedVulnerableFunctions && !isJVM

  const displayTextLabel = !runtime || runtimeAndNoLinkedVulnerableFunctions
  const displayOneFunction = displayFunction && countFunctions === 1
  const displayFunctionsList = displayFunction && countFunctions > 1

  const getFunctionName = (name: string) => name.replace("::", ": ")

  return (
    <Col span={24}>
      <CollapseDrawer
        classes={{
          container: isCard
            ? cn(styles.collapseContainer, styles.isCardContainer)
            : cn(
                "margin-horizontal-container-items-issue-expanded-cell-component-issue-tab",
                styles.collapseContainer
              )
        }}
        key="runtime-evidence"
        header={
          <div className={styles.collapseHeaderContainer}>
            <Heading
              level={6}
              classes={{ title: titleClassName }}
              popover={{
                className: styles.popover,
                content: (
                  <InformationPopover.CommonTemplate>
                    <Text strong className={styles.textPopover}>
                      {tRuntimeEvidence("titlePopover")}
                    </Text>
                    <br />
                    {tRuntimeEvidence("contentPopover")}
                  </InformationPopover.CommonTemplate>
                )
              }}
              title={title}
            />
            <BetaLabel />
          </div>
        }
        onChange={(keys) => {
          if (!!keys?.length)
            logger.info(
              EVENTS.ANALYTIC_EVENTS.TRIAGE.DRAWER.IN_RUNTIME_COLLAPSE_OPEN
            )
        }}
      >
        {displayTextLabel && (
          <Text className={styles.content}>
            {notRuntimeAndNoLinkedVulnerableFunctionsAndJVM &&
              tRuntimeEvidence("package_not_in_runtime")}

            {notRuntimeAndNoLinkedVulnerableFunctionsAndNotJVM &&
              tRuntimeEvidence("package_not_loaded")}

            {runtimeAndNoLinkedVulnerableFunctionsAndJVM &&
              tRuntimeEvidence("package_in_runtime")}

            {runtimeAndNoLinkedVulnerableFunctionsAndNotJVM &&
              tRuntimeEvidence("package_loaded")}
          </Text>
        )}

        {isPackageLoaded && (
          <Text className={styles.content}>
            {tRuntimeEvidence("package_loaded_not_used")}
          </Text>
        )}

        {displayFunction && (
          <Text className={styles.content}>
            <Text>
              {tRuntimeEvidence(displayFunctionKey, { count: countFunctions })}
            </Text>
            <br />
            {displayOneFunction && (
              <Text code className={styles.secondaryTextContent}>
                {getFunctionName(functions[0])}
              </Text>
            )}
            {displayFunctionsList && (
              <List
                limit={3}
                items={functions}
                classes={{
                  list: styles.functionsListEntity,
                  button: styles.functionsListButton
                }}
                render={(name, i) => (
                  <Text code className={styles.secondaryTextContent}>
                    {`${i + 1}. ${getFunctionName(name)}`}
                  </Text>
                )}
              />
            )}
          </Text>
        )}
      </CollapseDrawer>
    </Col>
  )
}
