import {
  KodemCIPublicImageName,
  type Integration,
  type IntegrationDetails,
  type IntegrationDetailsResponse,
  type IntegrationsListResponse,
  type IntegrationStaticInfo,
  type JiraMetadata,
  type PreInstallIntegrationResponse
} from "@/api/useIntegrations.types"

export const kodemCIIntegrationsStaticInfo: IntegrationStaticInfo[] = [
  {
    name: "kodem_ci",
    displayName: "Kodem CI",
    beta: true,
    description:
      "Kodem CI is an image-based tool that allows scanning images on demand",
    tags: ["Continuous Integration"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Kodem CI is an image-based tool that allows scanning images on demand. It can be integrated into any CI tool, or run directly on any machine. Kodem CI helps secure your CI/CD pipelines by enforcing CI policies through the SDLC."
        },
        {
          title: "Setup",
          prerequisites: [
            "The Kodem CI image is compatible with Linux and Mac OS, supporting both ARM64 and amd64 architectures",
            "Kodem CI image can run in both docker and contained runtime",
            "You need to be an admin in Kodem in order to generate a service key for the integration"
          ]
        },
        {
          title: "How To Use The Kodem CI Image",
          howToUse: {
            runScanCommandSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image <IMAGE_TO_SCAN> \\
     -v --severity=critical,high`,
            runScanCompareSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image ubuntu:24.04 \\
     -v --severity=critical,high --ignore-unfixed --compare-with ubuntu:14.04`,
            integrationSample: "TBD"
          }
        }
      ]
    }
  },
  {
    name: "jenkins",
    displayName: "Jenkins",
    beta: true,
    description:
      "Proactively secure your CI pipelines with early detection of security flaws",
    tags: ["Continuous Integration"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrate Kodem's Continuous Integration tool within your Jenkins pipelines to secure your images from vulnerabilities before entering your organization's image registries."
        },
        {
          title: "Setup",
          prerequisites: [
            "The Kodem CI image is compatible with Linux and Mac OS, supporting both ARM64 and amd64 architectures",
            "Kodem CI image can run in both docker and contained runtime",
            "You need to be an admin in Kodem in order to generate a service key for the integration"
          ]
        },
        {
          title: "How To Use The Kodem CI Image",
          howToUse: {
            runScanCommandSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image <IMAGE_TO_SCAN> \\
     -v --severity=critical,high`,
            runScanCompareSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image ubuntu:24.04 \\
     -v --severity=critical,high --ignore-unfixed --compare-with ubuntu:14.04`,
            integrationSample: `pipeline {
     agent any

     stages {
         stage('Vulnerability Scan') {
             steps {
                 withCredentials([string(credentialsId: 'kodem_service_key', variable: 'KODEM_SERVICE_KEY')]) {
                     sh """
                         docker run --rm \\
                             -v /var/run/docker.sock:/var/run/docker.sock \\
                             -e SERVICE_KEY=\${KODEM_SERVICE_KEY} \\
                             ${KodemCIPublicImageName}:latest  image <IMAGE_TO_SCAN> \\
                             -v --severity=critical,high
                     """
                 }
             }
         }
     }
 }`
          }
        }
      ]
    }
  },
  {
    name: "github_actions",
    displayName: "GitHub Actions",
    beta: true,
    description:
      "Proactively secure your CI pipelines with early detection of security flaws",
    tags: ["Continuous Integration"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrate Kodem's Continuous Integration tool within your GitHub Actions pipelines to secure your images from vulnerabilities before entering your organization's image registries."
        },
        {
          title: "Setup",
          prerequisites: [
            "The Kodem CI image is compatible with Linux and Mac OS, supporting both ARM64 and amd64 architectures",
            "Kodem CI image can run in both docker and contained runtime",
            "You need to be an admin in Kodem in order to generate a service key for the integration"
          ]
        },
        {
          title: "How To Use The Kodem CI Image",
          howToUse: {
            runScanCommandSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image <IMAGE_TO_SCAN> \\
     -v --severity=critical,high`,
            runScanCompareSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image ubuntu:24.04 \\
     -v --severity=critical,high --ignore-unfixed --compare-with ubuntu:14.04`,
            integrationSample: `name: CI

on:
  push:
    branches: [ main ]
  pull_request:
    branches: [ main ]

jobs:
  build:
    runs-on: ubuntu-latest

    - name: Pull the image
      run: |
        docker pull <IMAGE_TO_SCAN>
        docker pull ${KodemCIPublicImageName}:latest

    - name: Run the scan
      run: |
        docker run --rm \\
          -v /var/run/docker.sock:/var/run/docker.sock \\
          -e SERVICE_KEY=\${KODEM_SERVICE_KEY} \\
          ${KodemCIPublicImageName}:latest  image <IMAGE_TO_SCAN> \\
          -v --severity=critical,high`
          }
        }
      ]
    }
  },
  {
    name: "circle_ci",
    displayName: "Circle CI",
    beta: true,
    description:
      "Proactively secure your CI pipelines with early detection of security flaws",
    tags: ["Continuous Integration"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrate Kodem's Continuous Integration tool within your CircleCI pipelines to secure your images from vulnerabilities before entering your organization's image registries."
        },
        {
          title: "Setup",
          prerequisites: [
            "The Kodem CI image is compatible with Linux and Mac OS, supporting both ARM64 and amd64 architectures",
            "Kodem CI image can run in both docker and contained runtime",
            "You need to be an admin in Kodem in order to generate a service key for the integration"
          ]
        },
        {
          title: "How To Use The Kodem CI Image",
          howToUse: {
            runScanCommandSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image <IMAGE_TO_SCAN> \\
     -v --severity=critical,high`,
            runScanCompareSample: `docker run --rm \\
     -v /var/run/docker.sock:/var/run/docker.sock \\
     -e SERVICE_KEY=$KODEM_SERVICE_KEY \\
     ${KodemCIPublicImageName}:latest image ubuntu:24.04 \\
     -v --severity=critical,high --ignore-unfixed --compare-with ubuntu:14.04`,
            integrationSample: "TBD"
          }
        }
      ]
    }
  }
]

export const kodemCILink =
  "https://public.kodemsecurity.com/docs/integrations/Kodem_CI_tool.pdf"
const integrationsStaticInfo: IntegrationStaticInfo[] = [
  {
    name: "bitbucket",
    displayName: "Bitbucket Cloud",
    description:
      "Automatically scan and monitor Bitbucket Cloud for early vulnerability detection in SDLC",
    tags: ["Source control"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrate your Bitbucket workspace with Kodem to detect package vulnerabilities, malicious packages, code weaknesses and exposed secrets across code repositories. Discovered issues are correlated to runtime images helping better triage."
        },
        {
          title: "Setup",
          prerequisites: [
            "The user activating the Kodem application requires explicitly accepting all Bitbucket scopes"
          ]
        }
      ],
      submitButton: {
        description: "Integrate Kodem with Bitbucket Cloud:",
        label: "Open Atlassian Marketplace",
        link: "https://marketplace.atlassian.com/apps/1229654?tab=overview&hosting=cloud"
      }
    }
  },
  {
    name: "github",
    displayName: "GitHub",
    description:
      "Automatically scan and monitor GitHub for early vulnerability detection in SDLC",
    tags: ["Source control"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrate your GitHub organizations with Kodem to detect package vulnerabilities, malicious packages, code weaknesses and exposed secrets across code repositories. Discovered issues are correlated to runtime images helping better triage and allowing for secured SCM policies enforcement."
        },
        {
          title: "Setup",
          prerequisites: [
            {
              "The user activating the Kodem application requires the following permissions on the desired GitHub organizations:":
                [
                  "Read access to code",
                  "Read access to metadata",
                  "Read permissions for packages",
                  "Read and write access to pull requests metadata"
                ]
            }
          ]
        }
      ],
      submitButton: {
        description: "Install the Kodem App from the GitHub Marketplace:",
        note: "Kodem security PR comments are disabled by default. You can enable them after completing the installation.",
        label: "Open GitHub Marketplace",
        link: "https://github.com/apps/kodem-security"
      }
    }
  },
  {
    name: "ecr",
    displayName: "Amazon ECR",
    description:
      "Add context to your remediation by connecting Amazon ECR with Kodem",
    tags: ["Container registry"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrating your AWS Elastic Container Registry (ECR) with Kodem improves the security, visibility, and management of your SDLC. By scanning your registry Kodem provides valuable context throughout your SDLC and enables informed and streamlined remediation steps and better image scanning times."
        },
        {
          title: "Setup",
          prerequisites: [
            "You need to be an admin in your AWS account in order to integrate your AWS ECR with Kodem."
          ]
        }
      ],
      submitButton: {
        description: " ",
        label: "Connect AWS Account",
        connect: "connect",
        link: "https://public.kodemsecurity.com/docs/integrations/Amazon_Elastic_Container_Registry_(ECR)_integration_for_Kodem.pdf"
      }
    }
  },
  {
    name: "docker_hub",
    displayName: "Docker Hub",
    description:
      "Add context to your remediation by connecting Docker Hub with Kodem",
    tags: ["Container registry"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrating your Docker Hub container image registry with Kodem improves the security, visibility, and management of your SDLC. By scanning your registry Kodem provides valuable context throughout your SDLC and enables informed and streamlined remediation steps."
        },
        {
          title: "Setup",
          prerequisites: [
            "Read permissions for all image repositories that you wish to scan with Kodem"
          ]
        }
      ],
      submitButton: {
        description: "Allow Kodem to scan your Docker Hub container images:",
        label: "Setup instructions",
        link: "https://public.kodemsecurity.com/docs/integrations/Docker_Hub_integration_for_Kodem.pdf"
      }
    }
  },
  {
    name: "gcr",
    displayName: "Google Artifact Registry",
    description:
      "Add context to your remediation by connecting GAR and GCR with Kodem",
    tags: ["Container registry"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Integrating your organization's Google Artifact Registry (GAR) and Google Container Registry (GCR) with Kodem improves the security, visibility, and management of your SDLC. By scanning your registry Kodem provides valuable context throughout your SDLC and enables informed and streamlined remediation steps."
        },
        {
          title: "Setup",
          prerequisites: [
            "The user activating the integration requires Owner, Editor or Project IAM Admin role in the related GCP projects"
          ]
        }
      ],
      submitButton: {
        description: "Allow Kodem to scan your GAR and GCR images:",
        label: "Setup instructions",
        link: "https://public.kodemsecurity.com/docs/integrations/Google_Registry_integration_for_Kodem.pdf"
      }
    }
  },
  {
    name: "jira",
    displayName: "Jira Cloud",
    description:
      "Streamline your remediation by creating a Jira issue for Kodem findings",
    tags: ["Ticketing"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Streamline your remediation process by creating a Jira issue for Kodem findings. You can triage effortlessly by creating Jira issues with full applicative context and sending them directly to the issue owner."
        },
        {
          title: "Setup",
          prerequisites: [
            "The user activating the integration must be a Jira System administrator"
          ]
        }
      ],
      submitButton: {
        description: "Integrate Kodem with Jira Cloud:",
        label: "Open Atlassian Marketplace",
        link: "https://marketplace.atlassian.com/apps/1229653?tab=overview&hosting=cloud"
      }
    }
  },
  {
    name: "wiz",
    displayName: "Wiz",
    description:
      "Complement your cloud security findings with runtime intelligence",
    tags: ["CSPM"],
    connectPage: {
      sections: [
        {
          title: "About",
          description:
            "Use Kodem's integration with Wiz to complement your Cloud security findings with runtime intelligence. Kodem provides comprehensive applicative-level insights, helping you understand the real impact of your CSPM findings."
        },
        {
          title: "Setup",
          prerequisites: [
            "The activating user must have one of the following roles in the Wiz platform: Project Admin, Global Contributor or Global Admin"
          ]
        }
      ],
      submitButton: {
        description: "Allow Kodem to access and comment on your Wiz issues:",
        label: "Setup instructions",
        link: "https://public.kodemsecurity.com/docs/integrations/Wiz_integration_for_Kodem.pdf"
      }
    }
  },
  {
    name: "qualys",
    displayName: "Qualys",
    description: "Complement your security findings with runtime intelligence",
    tags: ["VMDR"]
  },
  ...kodemCIIntegrationsStaticInfo,
  {
    name: "slack",
    displayName: "Slack",
    description:
      "Be the first to know about security risks with real-time Kodem findings notifications",
    tags: ["Collaboration"]
  },
  {
    name: "asana",
    displayName: "Asana",
    description:
      "Streamline your remediation by creating an Asana task for Kodem findings",
    tags: ["Ticketing"]
  },
  {
    name: "GitLabSourceCode",
    displayName: "GitLab",
    description:
      "Automatically scan and monitor GitLab for early vulnerability detection in SDLC",
    tags: ["Source control"]
  },
  {
    name: "ServiceNow",
    displayName: "ServiceNow",
    description:
      "Streamline your remediation by creating ServiceNow tickets for Kodem findings",
    tags: ["Ticketing"]
  },
  {
    name: "Splunk",
    displayName: "Splunk",
    description: "Monitor Kodem findings, and Top Alerts within your Splunk",
    tags: ["SIEM"]
  },
  {
    name: "orca",
    displayName: "Orca",
    description:
      "Complement your cloud security findings with runtime intelligence",
    tags: ["CSPM"]
  },
  {
    name: "prisma_cloud",
    displayName: "Prisma Cloud",
    description: "Complement your security findings with runtime intelligence",
    tags: ["CSPM"]
  },
  {
    name: "snyk",
    displayName: "Snyk",
    description: "Complement your security findings with runtime intelligence",
    tags: ["SCA"]
  },
  {
    name: "mend",
    displayName: "Mend",
    description: "Complement your security findings with runtime intelligence",
    tags: ["SCA"]
  },
  {
    name: "checkmarx",
    displayName: "Checkmarx",
    description: "Complement your security findings with runtime intelligence",
    tags: ["SCA"]
  },
  {
    name: "azure_repos",
    displayName: "Azure Repos",
    description:
      "Automatically scan and monitor Azure Repos for early vulnerability detection in SDLC",
    tags: ["SCM"]
  },
  {
    name: "jfrog_artifcatory",
    displayName: "JFrog Artifactory",
    description: "Complement your security findings with runtime intelligence",
    tags: ["Artifactory"]
  }
]

export const getIntegrationsListData = (response: IntegrationsListResponse) => {
  const { data } = response || {}
  const integrationsStatus: Record<string, boolean> = {}

  const servicesStateByName = data.reduce(
    (acc, integration) => {
      const { name, isEnabled, isInstalled } = integration

      acc[integration.name] = integration

      integrationsStatus[name] = isEnabled && isInstalled

      return acc
    },
    {} as Record<string, Integration>
  )

  const merged = integrationsStaticInfo
    .filter((integration) => servicesStateByName[integration.name])
    .map((integration) => ({
      ...integration,
      ...servicesStateByName[integration.name]
    }))
  const metadata: JiraMetadata = { integrationsStatus }
  return { data: merged, metadata }
}

export const getIntegrationDetailsData = (
  response: IntegrationDetailsResponse
) => {
  const integration = (response.data as IntegrationDetails).integration
  // TODO: api sometimes does not return a name, fix it :)
  const n = integration.name
  const configuration =
    (response.data as IntegrationDetails).configuration || {}
  const staticInfo = integrationsStaticInfo.find((i) => i.name === n)

  if (!staticInfo) {
    return null
  }

  return {
    data: {
      integration: { ...staticInfo, ...integration },
      configuration
    }
  }
}

export const getPreInstallOptionsECR = (
  response: PreInstallIntegrationResponse
) => {
  const { data } = response || {}
  const { regions = [] } = data || []

  return {
    ...data,
    regionOptions: regions.map((value: string) => ({
      value,
      label: value
    }))
  }
}
