import { useEffect } from "react"

import { Button, Empty, Text } from "@/atoms"
import { INTERNAL_EVENTS } from "@/const/event.constants"
import { useAttributesObservable } from "@/hooks/useAttributesObservable"
import { useTranslation } from "@/hooks/useTranslation"
import { NoFound } from "@/icons"
import {
  CONTAINER_SELECTOR,
  FILTER_ATTRIBUTE_NAME
} from "@/templates/TabTemplate"

import styles from "./EmptyTable.module.scss"

import type { EmptyTableComponent } from "."

export const EmptyTable: EmptyTableComponent = ({ containerRef }) => {
  const [values, { update }] = useAttributesObservable(containerRef, [
    FILTER_ATTRIBUTE_NAME
  ])
  const counts = values[FILTER_ATTRIBUTE_NAME]

  useEffect(() => {
    update(FILTER_ATTRIBUTE_NAME)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation("translation", {
    keyPrefix: "filter.emptyState"
  })

  if (!counts || counts === "0") {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  return (
    <div className={styles.container}>
      <NoFound />
      <Text className={styles.text}>{t("message")}</Text>
      <Button
        type="link"
        onClick={(e) => {
          ;(e.target as HTMLElement)
            .closest(CONTAINER_SELECTOR)
            ?.dispatchEvent(
              new Event(INTERNAL_EVENTS.CLEAR_FILTERS_TABLE_TAB_TEMPLATE)
            )
        }}
      >
        {t("clear")}
      </Button>
    </div>
  )
}
