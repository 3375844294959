import { useIssueFindings } from "@/api/useTriage"
import { Col } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { DescriptionIssueCard } from "@/molecules/DescriptionIssueCard"
import { FixesLocationCard } from "@/molecules/FixesLocationCard"

import type { VulnerableCodeFinding } from "@/domain/issue"

export const VulnerableCodeCards = ({
  record
}: {
  record?: { id: string }
}) => {
  const id = record?.id
  const context = useIssueFindings({ needle: id || "" }, { enabled: !!id })
  const recordFinding = context.response?.data[0] as
    | VulnerableCodeFinding
    | undefined
  const { t } = useTranslation()

  return (
    <>
      <Col span={24} data-element-issue-expandable-cell-view="fix-container">
        <DescriptionIssueCard t={t} record={recordFinding} />
      </Col>
      <Col span={24} data-element-issue-expandable-cell-view="fix-container">
        <FixesLocationCard t={t} record={recordFinding} />
      </Col>
    </>
  )
}
