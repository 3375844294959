import cn from "classnames"
import { Children } from "react"

import styles from "./BulletedList.module.scss"

import type { BulletedListProps } from "."

export const BulletedList = ({
  children,
  ordered,
  classes = {}
}: BulletedListProps) => {
  const Wrap = ordered ? "ol" : "ul"
  const items = Children.toArray(children)

  return (
    <Wrap className={cn(styles.bulletedList, classes.wrap)}>
      {items.map((child, index) => (
        <li key={index} className={classes.item}>
          {child}
        </li>
      ))}
    </Wrap>
  )
}
