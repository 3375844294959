import { HeaderPage } from "@/organisms/HeaderPage"
import { PageTemplate } from "@/templates/PageTemplate"
import { FunnelTriagePage } from "./components/organisms/FunnelTriagePage"
import { SelectionProvider } from "./components/organisms/SelectionProvider"
import { TriageTab } from "./components/organisms/TriageTab"

import styles from "./TriagePage.module.scss"

export const TriagePage = () => (
  <PageTemplate
    full
    header={<HeaderPage titleTranslationKey="Triage" />}
    classes={{ main: styles.main }}
  >
    <FunnelTriagePage size="sm" />
    <SelectionProvider>
      <TriageTab />
    </SelectionProvider>
  </PageTemplate>
)
