import type {
  EcrConfiguration,
  EcrInstallation
} from "@/api/useIntegrations.types"
import {
  Button,
  Collapse,
  Divider,
  Form,
  FormInstance,
  Modal,
  Text,
  Title
} from "@/atoms"

import styles from "./EcrConfigurationForm.module.scss"

import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { ExclamationCircleOutlined, Image, TrashOutlined } from "@/icons"
import { TagInput } from "@/pages/SettingsPage/components/organisms/ScmForm/components/TagInput"
import type { Dispatch } from "react"
import { BaseReposLabel } from "../../../molecules/BaseReposLabel"
import { SelectRegions } from "../../../molecules/SelectRegions"

type EcrRegistriesListProps = {
  form: FormInstance
  accountRegistries: EcrInstallation[]
  regionOptions: {
    value: any
    label: any
  }[]
  onApply: (configuration: EcrConfiguration) => void
  onRemove: (accountId: string) => void
  setFormDirty: Dispatch<boolean>
}
export const EcrRegistriesList: React.FC<EcrRegistriesListProps> = ({
  form,
  accountRegistries,
  regionOptions = [],
  onApply,
  onRemove,
  setFormDirty
}) => {
  const { logger, EVENTS } = useLogger()
  const { t: tGeneral } = useTranslation("translation", {
    keyPrefix: "general"
  })
  const { t: tConfiguration } = useTranslation("translation", {
    keyPrefix: "integrations.configuration.ecr"
  })

  const { t: tConnectEcr } = useTranslation("translation", {
    keyPrefix: "integrations.connect.ecr"
  })
  const [modal, modalContextHolder] = Modal.useModal()

  return (
    <>
      <Title className={styles.title} level={5}>
        {tConfiguration("configurationTitle")}
      </Title>
      {accountRegistries.length ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            accounts: accountRegistries.map((account: any) => ({
              regions: Object.keys(account.regionToRegistryUrl || {}).map(
                (key) => key
              ),
              ...account
            }))
          }}
          onFieldsChange={() => setFormDirty(true)}
          onFinish={onApply}
        >
          <Form.List name="accounts">
            {(accounts) =>
              accounts
                .filter((a) => !!accountRegistries[a.key])
                .map((account) => (
                  <Collapse
                    className={styles.collapse}
                    key={account.key}
                    items={[
                      {
                        label: (
                          <div className={styles.labelContainer}>
                            <div className={styles.titleWrap}>
                              {accountRegistries[account.key].accountId}
                              <div>
                                <span className={styles.description}>
                                  <span className={styles.icon}>
                                    <Image />
                                  </span>{" "}
                                  {tConfiguration("registriesConencted", {
                                    count:
                                      accountRegistries[account.key]
                                        .registriesCount
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        ),
                        children: (
                          <>
                            <div className={styles.wrapper}>
                              <Form.Item
                                name={[account.key, "regions"]}
                                label="Regions"
                              >
                                <SelectRegions
                                  options={regionOptions}
                                  placeholder={tConnectEcr(
                                    "regions.placeholder"
                                  )}
                                />
                              </Form.Item>
                              <Form.Item
                                name={[account.key, "repositories"]}
                                label={<BaseReposLabel t={tConnectEcr} />}
                              >
                                <TagInput
                                  placeholder={tConnectEcr(
                                    "baseReposInput.placeholder"
                                  )}
                                />
                              </Form.Item>
                            </div>
                            <Divider />
                            <div className={styles.wrapper}>
                              <Button
                                type="default"
                                onClick={() =>
                                  modal.confirm({
                                    className: styles.removeWarning,
                                    icon: (
                                      <ExclamationCircleOutlined
                                        className={styles.warning}
                                      />
                                    ),
                                    content: (
                                      <Text className={styles.text}>
                                        {tConnectEcr("removeWarning")}
                                      </Text>
                                    ),
                                    okText: tGeneral("remove"),
                                    okButtonProps: {
                                      danger: true
                                    },
                                    cancelText: tGeneral("cancel"),
                                    onOk: () => {
                                      logger.info(
                                        EVENTS.ANALYTIC_EVENTS.INTEGRATIONS
                                          .COMMAND_REMOVE,
                                        {
                                          accountId:
                                            accountRegistries[account.key]
                                              .accountId
                                        }
                                      )
                                      onRemove(
                                        accountRegistries[account.key].accountId
                                      )
                                    }
                                  })
                                }
                                icon={<TrashOutlined />}
                                danger
                              >
                                Remove Account
                              </Button>
                            </div>
                          </>
                        )
                      }
                    ]}
                  />
                ))
            }
          </Form.List>
        </Form>
      ) : (
        <Text>{tConfiguration("noConfiguration")}</Text>
      )}
      {modalContextHolder}
    </>
  )
}
