import { VulnerabilityCounts } from "@/molecules/VulnerabilityCounts"
import type { VulnerabilitiesCellComponent } from "."

export const VulnerabilitiesCell: VulnerabilitiesCellComponent = (
  vulnerabilities,
  { vulnerabilitiesLength, disableIndividualBlocks },
  rowIndex
) => {
  return (
    <VulnerabilityCounts
      disableIndividualBlocks={disableIndividualBlocks}
      disabled={!vulnerabilitiesLength}
      rowIndex={rowIndex}
      vulnerabilities={vulnerabilities}
    />
  )
}
