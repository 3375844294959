import { Route, Routes } from "react-router-dom"

import { ANALYTIC_EVENTS } from "@/const/event.constants"
import { NAMES_ROUTES, PARAMS } from "@/const/routes.constants"
import { ScmPoliciesPage } from "./components/pages/ScmPoliciesPage"
import { ScopePage } from "./components/pages/ScopePage"
import { SettingsPage } from "./components/pages/SettingsPage"

export const SettingsRouter = () => {
  const { SETTINGS } = NAMES_ROUTES
  const { SCOPES, SCM, CI } = SETTINGS

  return (
    <Routes>
      <Route
        path={`/${SCOPES}/:${PARAMS.ID}/:${PARAMS.DUPLICATE_ID}?`}
        element={<ScopePage />}
      />
      <Route
        path={`/${SCM}/:${PARAMS.SUB_TYPE}/:${PARAMS.ID}`}
        element={
          <ScmPoliciesPage
            policyType="SCM"
            settingsRoute={NAMES_ROUTES.SETTINGS.SCM}
            analyticsEvents={ANALYTIC_EVENTS.SETTINGS.POLICIES.SCM}
          />
        }
      />
      <Route
        path={`/${CI}/:${PARAMS.SUB_TYPE}/:${PARAMS.ID}`}
        element={
          <ScmPoliciesPage
            policyType="CI"
            settingsRoute={NAMES_ROUTES.SETTINGS.CI}
            analyticsEvents={ANALYTIC_EVENTS.SETTINGS.POLICIES.CI}
          />
        }
      />
      <Route path="/:tab?/:needle?" element={<SettingsPage />} />
    </Routes>
  )
}
