import dayjs from "dayjs"

import type {
  CodeRepoPackagesResponse,
  ImageResponse,
  SastIssuesResponse
} from "@/api/useInventory.types"
import type {
  IssueCodeRepoResponse,
  IssueImageResponse
} from "@/api/useIssues.types"
import type { Severity } from "@/domain/vulnerability"
import { formatVulnerabilitiesBySeverity } from "@/domain/vulnerability/vulnerability.formatters"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale,
  getRelativeTimeFromNow
} from "@/helpers/date.helpers"
import { capitalizeFirstLetter } from "@/helpers/string.helpers"
import { toDictionary } from "@/helpers/utils"

export const getPackagesData = (response: CodeRepoPackagesResponse) => {
  return {
    metadata: response.metadata,
    data: response.data.map((item, index) => ({
      ...item,
      key: `${item.name}-${index}-${item.version}`,
      expandable: item.vulnerabilities.length > 0,
      vulnerabilitiesLength: item.vulnerabilities.length,
      rowData: item.vulnerabilities,
      vulnerabilities: formatVulnerabilitiesBySeverity(
        toDictionary(item.vulnerabilities, "cve")
      )
    }))
  }
}

export const getIssuesDataCodeRepository = (
  response: IssueCodeRepoResponse
) => {
  const { data, ...attr } = response || {}

  return {
    data: data.map((item, i: number) => {
      const {
        cve,
        cvss,
        name,
        paths,
        fixVersions,
        fixability,
        computationError,
        computedFixes = []
      } = item

      return {
        key: `${cve}_${name}_${i}`,
        packageName: name,
        expandable: true,
        rowData: [
          {
            key: `${cve}_${name}_${i}`,
            name,
            cve,
            cvss,
            fixVersions,
            computedFixes: computedFixes.sort((a, b) =>
              a.name.localeCompare(b.name)
            ),
            fixability,
            paths: (paths || [])
              .map((pathSet: { name: string; version: string }[]) =>
                (pathSet || [])
                  .map(({ name, version }) => `${name}:${version}`)
                  .join(" > ")
              )
              .sort((a, b) => a.localeCompare(b)),
            computationError
          }
        ],
        ...item
      }
    }),
    ...attr
  }
}

export const getImageTabData = (response: ImageResponse) => {
  const { data, metadata } = response || {}

  return {
    metadata,
    data: data.map(({ data: dataRecord, id, displayName }) => {
      const { applications = [] } = dataRecord
      const codeRepositories = dataRecord.codeRepositoriesData || []

      return {
        key: id,
        displayName,
        applicationsCount: {
          copyable: true,
          title: "Applications",
          items: (applications || []).map(
            ({ name, environment, namespace }) => ({
              name,
              description: `${environment || "-"}/${namespace || "-"}`
            })
          ),
          label: applications?.length || ""
        },
        sbomQuery: `isVulnerable=Yes&imageId=${dataRecord.imageId}`,
        ...dataRecord,
        codeRepositoriesDisplayValue: {
          copyable: true,
          items: codeRepositories,
          count: codeRepositories.length - 1,
          label: codeRepositories?.[0]?.name.split("/")[1] || ""
        },

        fullImageName: `${dataRecord.registry}/${dataRecord.repository}:${dataRecord.tag}`,
        digestShort: dataRecord.digest?.substring(7, 19),
        operatingSystems: capitalizeFirstLetter(dataRecord.os),
        firstSeen: dayjs(
          new Date(parseInt(dataRecord.firstSeen) * 1000)
        ).format("YYYY-MM-DD HH:mm")
      }
    })
  }
}

export const getIssuesDataImage = (response: IssueImageResponse) => {
  const { data, metadata } = response || {}

  return {
    metadata,
    data: data.map((issue, i) => {
      const { cve, name } = issue

      return {
        key: `${cve}_${name}_${i}`,
        rowIndex: i,
        packageName: name,
        expandable: true,
        rowData: [issue],
        ...issue
      }
    })
  }
}

export const selectSastIssues = (response: SastIssuesResponse) => {
  const data = response.data.map((record, index) => {
    const cwe = {
      id: record.cwe,
      description: record.riskId
    }
    const date = getDateFromSeconds(record.discoveredAt)
    const key = `${index}_${Date.now()}`
    const idInsight = `${key}_insight`

    const applicationItems =
      record.relatedApplications?.map((app: any) => ({
        name: app.name,
        description: `${app.namespace}/${app.environment}`
      })) || []

    return {
      key,
      ...record,
      cwe,
      expandable: true,
      fixability: "full",
      riskInsights: { id: idInsight, ...record.insights } || {
        id: idInsight
      },
      applicationsCount: {
        label: applicationItems.length,
        copyable: true,
        items: applicationItems
      },
      severity: record.impact.toLocaleLowerCase() as Severity,
      discoveredDaysAgo: {
        timestamp: record.discoveredAt,
        text: getRelativeTimeFromNow(date),
        tooltip: getDateInUserLocale(date, DEFAULT_LONG_FORMAT)
      },
      kodemScore: record.score || 0,
      rowData: [
        {
          key: index,
          details: {
            cwe,
            riskId: record.riskId,
            owasp: record.owasp,
            languages: record.languages,
            description: record.description,
            fixComment: record.fixComment,
            introducedThroughLinks: record.introducedThroughLinks,
            codeExamples: record.codeExamples
          }
        }
      ]
    }
  })

  return {
    ...response,
    data
  }
}
