import { useState } from "react"

import {
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Text,
  TextArea
} from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags, useUser } from "@/hooks/useUser"

import styles from "./SamlAuthenticationMethodForm.module.scss"
import type {
  SamlAuthenticationMethodFormProps,
  SamlAuthenticationPermissionMethod
} from "./SamlAuthenticationMethodForm.types"

export const SamlAuthenticationMethodForm = ({
  activeKey,
  dataKey,
  form
}: SamlAuthenticationMethodFormProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.auth.methods.saml.form"
  })
  const { t: tGen } = useTranslation()
  const rules =
    activeKey === dataKey
      ? [{ required: true, message: tGen("error.requiredField") }]
      : []
  const [enabledAutoSize, setEnabledAutoSize] = useState(false)
  const { user } = useUser()
  const isEnabled = form.getFieldValue([dataKey, "idpAutoProvisioningEnabled"])
  const [isChecked, setIsChecked] = useState(isEnabled ? "checked" : "")
  const releaseDataAuthorizationFF = useFeatureFlags("releaseDataAuthorization")
  const permissionsOptions: {
    label: string
    value: SamlAuthenticationPermissionMethod
  }[] = releaseDataAuthorizationFF
    ? [
        { label: t("noScopesPermissions"), value: "NONE" },
        { label: t("allScopesPermissions"), value: "ALL" }
      ]
    : []
  return (
    <>
      <Divider className={styles.upperDivider} />
      <Collapse
        activeKey={isChecked}
        ghost
        bordered={false}
        className={styles.authorizationCollapse}
        items={[
          {
            key: "checked",
            showArrow: false,
            label: (
              <Form.Item
                name={[dataKey, "idpAutoProvisioningEnabled"]}
                valuePropName="checked"
                noStyle
              >
                <Checkbox
                  onChange={(changed) => {
                    if (!releaseDataAuthorizationFF) return
                    if (changed.target.checked) {
                      setIsChecked("checked")
                      form.setFieldValue(
                        [dataKey, "idpAutoProvisioningAuthorizationSettings"],
                        form.getFieldValue([
                          dataKey,
                          "idpAutoProvisioningAuthorizationSettings"
                        ]) || "NONE"
                      )
                    } else {
                      setIsChecked("")
                    }
                  }}
                >
                  {t("idp")}{" "}
                  <span className={styles.optionalLabel}>{t("optional")}</span>
                </Checkbox>
              </Form.Item>
            ),

            className: styles.panel,
            children: (
              <Form.Item
                name={[dataKey, "idpAutoProvisioningAuthorizationSettings"]}
                noStyle
              >
                <Radio.Group
                  className={styles.verticalRadioGroup}
                  options={permissionsOptions}
                />
              </Form.Item>
            )
          }
        ]}
      />

      <Divider className={styles.lowerDivider} />
      <div className={styles.note}>
        <Text>{`${t("notes.mes")}:`}</Text>
        <Text>
          {`${t("notes.appName")}:`}
          <b>Kodem Security</b>
        </Text>
        <Text>
          {`${t("notes.entityId")}:`}
          <b>https://app.kodemsecurity.com</b>
        </Text>
        <Text>
          {`${t("notes.acs")}:`}
          <b>{`https://app.kodemsecurity.com/api/v1/auth/acs/${user.company}`}</b>
        </Text>
      </div>
      <Text className={styles.text}>{t("title")}</Text>
      <Form.Item
        required={false}
        rules={rules}
        name={[dataKey, "entityId"]}
        label={t("entityId")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        required={false}
        rules={rules}
        name={[dataKey, "ssoUrl"]}
        label={t("signUrl")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        required={false}
        rules={rules}
        name={[dataKey, "x509PublicCert"]}
        label={t("cert509")}
      >
        <TextArea
          autoSize={enabledAutoSize}
          rows={4}
          onBlur={() => {
            setEnabledAutoSize(false)
          }}
          onFocus={() => {
            setEnabledAutoSize(true)
          }}
        />
      </Form.Item>
    </>
  )
}
