import { MoreTableCell } from "@/molecules/MoreTableCell"

import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { TFunction } from "@/hooks/useTranslation"
import type { MoreTableCellProps } from "@/molecules/MoreTableCell"

export const ImpactCell =
  (t: TFunction, onRowClick?: RowClick) => (props: MoreTableCellProps) => {
    if (!props) {
      return null
    }

    const { label, ...restProps } = props

    if (!label) {
      return null
    }

    return (
      <MoreTableCell
        label={
          typeof label === "number"
            ? `${label} ${t("general.app", { count: label })}`
            : label
        }
        onRowClick={onRowClick}
        {...restProps}
      />
    )
  }
