import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { VulnerabilitiesPackageCell } from "@/molecules/VulnerabilitiesPackageCell"

import type { ParamsGetColumnsTable } from "@/organisms/Drawers/components/TabDrawerTemplate"

export const getColumnsExpandedRow = ({ t }: ParamsGetColumnsTable) => [
  {
    title: null,
    render: VulnerabilitiesPackageCell(t),
    ...getDefaultColumnParams("vulnerabilities")
  }
]
