import {
  useGetInventoryApplication,
  useGetInventoryBranch,
  useGetInventoryCodeRepoPackages,
  useGetInventoryImage
} from "@/api/useInventory"
import { useGetSbom } from "@/api/useSbom"
import { useIssuesByResourceTriage } from "@/api/useTriage"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useHttp, useQueryClient } from "@/hooks/useHttp"
import { getCountParams } from "@/organisms/Drawers/hooks/useCounts"
import { getBranchTabData } from "@/pages/InventoryPage/application/branch-inventory/useBranchInventory.selector"
import { getSbomTabsData } from "@/pages/SbomPage/application/sbom/useSbom.selector"
import { getIssuesTriageSelector } from "@/pages/TriagePage/application/useTriage.selector"
import { getInitialDataFromQueryClient } from "../common/common"
import {
  getImageTabData,
  getPackagesData,
  selectSastIssues
} from "./useInventory.selectors"

import type { InventoryCodeRepoPackagesParams } from "@/api/useInventory.types"
import type { PropsUseEntity } from "./useInventory.types"

export const getData = (res: { data: object | unknown[] }) =>
  Array.isArray(res.data) ? res.data : [res.data]

export const useEntity = <S, E extends { id: string } = { id: string }>(
  id: string | undefined,
  { resourceRequestURL, hook, select: selectFn }: PropsUseEntity<S>
) => {
  const params = { needle: `/${id}`, pageNumber: "0" }
  const queryClient = useQueryClient()
  const context = hook(params, {
    select: (res: any) => {
      const nextRes = { ...res, data: getData(res) }

      if (typeof selectFn === "function") return selectFn?.(nextRes)

      return nextRes
    },
    enabled: !!id,
    initialData: () =>
      getInitialDataFromQueryClient(
        queryClient.getQueriesData<KosmosApi.Response<E[]> | undefined>({
          queryKey: [resourceRequestURL]
        }),
        (item) => item.id === id
      )
  })

  return context
}

export const useBranchEntity = (id?: string) => {
  const context = useEntity<typeof getBranchTabData>(id, {
    resourceRequestURL: RESOURCES_NAMES.INVENTORIES.BRANCH,
    hook: useGetInventoryBranch,
    select: getBranchTabData
  })
  const repositoryName = context.response?.data[0]?.repositoryName || ""
  const packageManagers: PackageManager[] =
    context.response?.data[0]?.packageManagers || []
  const estimatedPackageManagers: PackageManager[] =
    context.response?.data[0]?.estimatedPackageManagers || []

  const isPackageManagerEstimated =
    packageManagers?.filter((manager) =>
      estimatedPackageManagers.includes(manager)
    ).length > 0

  return [context, { repositoryName, isPackageManagerEstimated }] as const
}

export const useImageEntity = (id: string | undefined) => {
  const context = useEntity<typeof getImageTabData>(id, {
    resourceRequestURL: RESOURCES_NAMES.INVENTORIES.IMAGES,
    hook: useGetInventoryImage,
    select: getImageTabData
  })
  const imageId = context.response?.data[0]?.imageId

  return [context, imageId] as const
}

export const useApplicationEntity = (id: string | undefined) => {
  const context = useEntity(id, {
    resourceRequestURL: RESOURCES_NAMES.INVENTORIES.APPLICATION,
    hook: useGetInventoryApplication
  })
  const resourceId = context.response?.data[0]?.data?.resourceIds?.[0]

  return [context, resourceId] as const
}

export const usePackagesCodeRepository = (
  id: string | undefined,
  params: InventoryCodeRepoPackagesParams
) => {
  const [entityContext, { repositoryName }] = useBranchEntity(id)
  const config = { enabled: !!repositoryName }
  const context = useGetInventoryCodeRepoPackages(repositoryName, params, {
    select: getPackagesData,
    ...config
  })
  const countsContext = useGetInventoryCodeRepoPackages(
    repositoryName,
    getCountParams(),
    config
  )

  return [entityContext.isLoading ? entityContext : context, countsContext]
}

export const usePackagesImage = (
  id: string | undefined,
  getParams: (imageId: string) => InventoryCodeRepoPackagesParams,
  getCountRequestParams: (imageId: string) => InventoryCodeRepoPackagesParams
) => {
  const [entityContext, imageId = ""] = useImageEntity(id)

  const config = { enabled: !!imageId }
  const defaultParams = { groupedByImages: true }
  const nextParams = { ...defaultParams, ...getParams(imageId) }
  const context = useGetSbom(nextParams, {
    select: (res) => getSbomTabsData(res, { isDemo: false }),
    ...config
  })
  const countsContext = useGetSbom(
    {
      ...defaultParams,
      ...getCountRequestParams(imageId),
      ...getCountParams()
    },
    {
      select: (res) => getSbomTabsData(res, { isDemo: false }),
      ...config
    }
  )

  return [entityContext.isLoading ? entityContext : context, countsContext]
}

export const useGetIssuesForResource = (
  resourceIdName: string | undefined,
  filterParams: Record<string, unknown>
) => {
  const componentParams = { resourceId: resourceIdName, ...filterParams }
  const context = useIssuesByResourceTriage(componentParams, {
    enabled: !!resourceIdName,
    select: getIssuesTriageSelector
  })
  const countsContext = useIssuesByResourceTriage(
    {
      resourceId: resourceIdName,
      issueType: filterParams.issueType as string,
      ...getCountParams()
    },
    {
      enabled: !!resourceIdName,
      select: getIssuesTriageSelector
    }
  )
  return [context, countsContext]
}

export const usePackagesApplication = (
  id: string | undefined,
  getParams: (applicationId: string) => InventoryCodeRepoPackagesParams,
  getCountRequestParams: (
    applicationId: string
  ) => InventoryCodeRepoPackagesParams
) => {
  const [entityContext, resourceId = ""] = useApplicationEntity(id)
  const config = { enabled: !!resourceId }
  const nextParams = getParams(resourceId)
  const context = useGetSbom(nextParams, {
    select: (res) => getSbomTabsData(res, { isDemo: false }),
    ...config
  })
  const countsContext = useGetSbom(
    {
      ...getCountRequestParams(resourceId),
      ...getCountParams()
    },
    {
      select: (res) => getSbomTabsData(res, { isDemo: false }),
      ...config
    }
  )

  return [entityContext.isLoading ? entityContext : context, countsContext]
}

export const useSastIssues = (resourceId: string | undefined) => {
  const context = useHttp(
    `${RESOURCES_NAMES.INVENTORIES.SAST_ISSUES}`,
    { resourceId },
    {
      enabled: !!resourceId,
      select: selectSastIssues
    }
  )

  return context
}

export const useSastIssueCount = (resourceId: string | undefined) => {
  const context = useHttp(
    `${RESOURCES_NAMES.INVENTORIES.SAST_ISSUES}/${resourceId}`,
    { enabled: !!resourceId }
  )

  return context
}
