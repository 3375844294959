import { ScoreExplanationProps } from "@/api/useTriage.types"
import { Card } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { ScoreExplanation, TitleScoreCard } from "@/molecules/ScoreExplanation"

export const ScoreCardExplanation = ({
  record
}: {
  record: ScoreExplanationProps
}) => {
  const { t } = useTranslation()

  if (!record) return null

  const { kodemScore } = record
  return (
    <Card
      title={
        <Heading
          level={6}
          title={<TitleScoreCard t={t} score={kodemScore} />}
        />
      }
    >
      <ScoreExplanation flex record={record} />
    </Card>
  )
}
