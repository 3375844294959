export const getFiltersValues = (
  params: Record<string, string>
): Record<string, unknown> => ({
  ...params,
  name: params.name ? (params.name || "").split(",").filter(Boolean) : [],
  riskId: params.riskId ? (params.riskId || "").split(",").filter(Boolean) : [],
  exploitMaturity: params.exploitMaturity
    ? (params.exploitMaturity || "").split(",").filter(Boolean)
    : [],
  fromBaseImage: params.fromBaseImage ? [params.fromBaseImage] : [],
  cveSeverity: (params.severity || "").split(",").filter(Boolean),
  languages: (params.languages || "").split(",").filter(Boolean),
  scope: !!params.runtime ? "runtime" : "all"
})
