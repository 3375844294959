import dayjs from "dayjs"

import { getIssuesCountMapOrderedSeverity } from "@/domain/vulnerability"
import { IMAGE_DRAWER_ID } from "@/organisms/Drawers/const"

import type { BranchResponse } from "@/api/useInventory.types"

export const getDisplayName = (displayName: string): string => {
  if (!displayName) return displayName

  const displayNameArr = displayName.split("/")
  if (displayNameArr.length <= 2) return displayName

  return displayNameArr.slice(0, 2).join("/")
}

export const getBranchTabData = (response: BranchResponse) => {
  const { data = [], metadata } = response || {}

  return {
    metadata,
    data: data.map(({ id, displayName, data: dataRecord }, index) => {
      const { issuesSummary } = dataRecord
      const relatedImages = (dataRecord.relatedImages || []).map((image) => ({
        drawerEntityId: image.id,
        drawerId: IMAGE_DRAWER_ID,
        ...image
      }))
      const relatedImagesExist = !!dataRecord.relatedImages?.length
      const firstRelatedImageName = relatedImagesExist
        ? dataRecord.relatedImages[0].name
        : ""

      const issuesCounts = getIssuesCountMapOrderedSeverity(issuesSummary)

      return {
        key: id,
        displayName: getDisplayName(displayName),
        ...dataRecord,
        relatedImages: {
          id: `relatedImages-${index}`,
          count: relatedImages.length - 1,
          label: firstRelatedImageName,
          items: relatedImages,
          copyable: true,
          width: "100%"
        },
        commitHashShort: dataRecord.commitHash?.slice(0, 7),
        firstRelatedImage: firstRelatedImageName,
        sbomQuery: relatedImagesExist
          ? `isVulnerable=Yes&imageId=${dataRecord.relatedImages
              ?.map((image) => image.imageId)
              .join(",")}`
          : null,
        lastSeen: dayjs(new Date(dataRecord.lastSeen * 1000)).format(
          "YYYY-MM-DD HH:mm"
        ),
        lastCveScan: dataRecord.lastCveScan
          ? dayjs(new Date(dataRecord.lastCveScan * 1000)).format(
              "YYYY-MM-DD HH:mm"
            )
          : "",
        severity: issuesCounts
      }
    })
  }
}
