import { capitalizeFirstLetter } from "@/helpers/string.helpers"
import dayjs from "dayjs"

import { getIssuesCountMapOrderedSeverity } from "@/domain/vulnerability"
import {
  APPLICATION_DRAWER_ID,
  CODE_REPOSITORY_DRAWER_ID
} from "@/organisms/Drawers/const"

import type { ImageResponse } from "@/api/useInventory.types"

export const getImageTabData = (response: ImageResponse) => {
  const { data, metadata } = response || {}

  return {
    metadata,
    data: data.map(({ data: dataRecord, id, displayName }) => {
      const { applications = [], issuesSummary } = dataRecord
      const codeRepositories = (dataRecord.codeRepositoriesData || []).map(
        (codeRepository) => ({
          name: codeRepository.name,
          drawerId: CODE_REPOSITORY_DRAWER_ID,
          drawerEntityId: codeRepository.repositoryId
        })
      )
      const issuesCounts = getIssuesCountMapOrderedSeverity(issuesSummary)
      return {
        key: id,
        displayName,
        applicationsCount: {
          copyable: true,
          title: "Applications",
          items: (applications || []).map(
            ({ name, environment, namespace, applicationId }) => ({
              name,
              description: `${environment || "-"}/${namespace || "-"}`,
              drawerId: APPLICATION_DRAWER_ID,
              drawerEntityId: applicationId
            })
          ),
          label: applications?.length || ""
        },
        sbomQuery: `isVulnerable=Yes&imageId=${dataRecord.imageId}`,
        ...dataRecord,
        codeRepositoriesDisplayValue: {
          copyable: true,
          items: codeRepositories,
          count: codeRepositories.length - 1,
          label: codeRepositories?.[0]?.name.split("/")[1] || ""
        },

        fullImageName: `${dataRecord.registry}/${dataRecord.repository}:${dataRecord.tag}`,
        digestShort: dataRecord.digest?.substring(7, 19),
        operatingSystems: capitalizeFirstLetter(dataRecord.os),
        firstSeen: dayjs(
          new Date(parseInt(dataRecord.firstSeen) * 1000)
        ).format("YYYY-MM-DD HH:mm"),
        severity: issuesCounts
      }
    })
  }
}

export type ImageTabData = ReturnType<typeof getImageTabData>["data"][number]
